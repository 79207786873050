
import { Options, Vue } from 'vue-class-component'
import { PropType } from 'vue'

@Options({
  name: 'v-label',
  props: {
    label: {
      type: String as PropType<string>,
      required: true,
    },
    vFor: {
      type: String as PropType<string>,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {},
  watch: {},
})
export default class App extends Vue {}
