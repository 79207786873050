<template>
  <div class="flex w-full h-full items-center">
    <div
      class="content p-4 w-full justify-content-center text-center text-red-400"
    >
      <h1 class="font-bold text-4xl text-red-400 m-0 pt-5 pb-1">403</h1>
      <h2 class="text-danger text-2xl m-0 pb-5">Forbidden</h2>
    </div>
  </div>
</template>

<script lang="ts"></script>
