import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a180920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mt-2 mb-4 mx-2" }
const _hoisted_2 = { class: "w-10/12" }
const _hoisted_3 = ["placeholder", "value"]
const _hoisted_4 = { class: "w-2/12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["search-bar z-20", { desktop: _ctx.desktop, mobile: !_ctx.desktop }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "flex",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showLeftPanelContent && _ctx.showLeftPanelContent(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", {
            class: _normalizeClass(["search-bar-input h-12 rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none text-lg rounded-r-none", { desktop: _ctx.desktop, mobile: !_ctx.desktop }]),
            placeholder: 
              _ctx.$t('pages.vehicleMap.leftPanel.searchBar.placeholder')
            ,
            type: "text",
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 42, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cleanInput && _ctx.cleanInput(...args))),
            class: "block w-full h-12 border border-gray-300 rounded-r-md border-l-0 text-gray-400 hover:text-blue-500 bg-white"
          }, [
            _createVNode(_component_v_icon, {
              icon: "delete_forever",
              class: "text-2xl"
            })
          ])
        ])
      ])
    ])
  ], 2))
}