
import { defineComponent, PropType, nextTick } from 'vue'
import spot from '@/types/spot'

export default defineComponent({
  name: 'SpotCircle',
  props: {
    spots: {
      type: Array as PropType<spot[]>,
      default: () => [],
    },
    viewable: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      spotCircleOptions: {
        fillcolor: '#0000FF',
        strokeOpacity: 0,
        strokeWeight: 2,
        fillColor: '#0000FF',
        fillOpacity: 0.35,
      } as object,
      openedMarkerID: null as null | number,
    }
  },
  methods: {
    getSpotIcon(): object {
      return {
        url: '/images/place.png',
      }
    },
    openMarker(id: number): void {
      this.closeMarkerWindow()
      ;(async () => {
        await nextTick()
        this.openedMarkerID = id
      })()
    },
    closeMarkerWindow(): void {
      this.openedMarkerID = null
    },
    isMarkerSelected(index: number): boolean {
      return this.openedMarkerID === index
    },
  },
})
