export const workStatus = {
  WORK_STATUS_LEAVING_WAREHOUSE: 'leaving_warehouse',
  WORK_STATUS_LOADING: 'loading',
  WORK_STATUS_LOADING_END: 'loading_end',
  WORK_STATUS_UNLOADING: 'unloading',
  WORK_STATUS_UNLOADING_END: 'unloading_end',
  WORK_STATUS_SHIPPING: 'shipping',
  WORK_STATUS_SHIPPING_END: 'shipping_end',
  WORK_STATUS_INCIDENTAL_WORK: 'incidental_work',
  WORK_STATUS_INCIDENTAL_WORK_END: 'incidental_work_end',
  WORK_STATUS_REFUELING: 'refueling',
  WORK_STATUS_REFUELING_END: 'refueling_end',
  WORK_STATUS_WAIT: 'wait',
  WORK_STATUS_WAIT_END: 'wait_end',
  WORK_STATUS_BREAK: 'break',
  WORK_STATUS_BREAK_END: 'break_end',
  WORK_STATUS_REST: 'rest',
  WORK_STATUS_REST_END: 'rest_end',
  WORK_STATUS_OTHER: 'other',
  WORK_STATUS_OTHER_END: 'other_end',
  WORK_STATUS_HIGH_WAY: 'high_way',
  WORK_STATUS_GENERAL_WAY: 'general_way',
  WORK_STATUS_ACTUAL_CAR: 'actial_car',
  WORK_STATUS_EMPTY_CAR: 'empty_car',
  WORK_STATUS_MAINTENANCE: 'maintenance',
  WORK_STATUS_MAINTENANCE_END: 'maintenance_end',
  WORK_STATUS_RETURNING_WAREHOUSE: 'returning_warehouse',
  WORK_STATUS_IN_SERVICE: 'in_service',
  WORK_STATUS_STOPPING: 'stopping',
  WORK_STATUS_NOTHING: '',
}
export type workStatus = typeof workStatus[keyof typeof workStatus]

export const alertStatus = {
  ALERT_STATUS_OVER_SPEED: 'over_speed',
  ALERT_STATUS_RAPID_DECELERATION: 'rapid_deceleration',
  ALERT_STATUS_RAPID_ACCELERATION: 'rapid_acceleration',
  ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN:
    'sharp_steering_wheel_and_steep_turn',
} as const
export type alertStatus = typeof alertStatus[keyof typeof alertStatus]

export enum sensorAlertStatus {
  ALERT_OVER_THRESHOLD = 'over_threshold',
  ALERT_UNDER_THRESHOLD = 'under_threshold',
  ALERT_SENSOR_ERROR = 'sensor_error',
}

export enum markerType {
  ALERT,
  WORK_EVENT,
  REST,
  SENSOR_ALERT,
}

export const MAP_ICON = {
  path: 'M 8 16 s 6 -5.686 6 -10 A 6 6 0 0 0 2 6 c 0 4.314 6 10 6 10 z',
  fillColor: '#f44336',
  fillOpacity: 1,
  strokeWeight: 0,
  rotation: 0,
  scale: 3,
  anchor: {
    x: 8,
    y: 16,
  },
  labelOrigin: {
    x: 8.1,
    y: 6.5,
  },
}

export const MAP_ICON_TEXT = {
  text: '\ue558',
  fontFamily: 'Material Icons',
  color: 'black',
  fontWeight: 'bold',
  fontSize: '22px',
}

export const STATUS_MAP: { [index: string]: string } = {
  1_1: workStatus.WORK_STATUS_LEAVING_WAREHOUSE,
  2_1: workStatus.WORK_STATUS_LOADING,
  2_0: workStatus.WORK_STATUS_LOADING_END,
  3_1: workStatus.WORK_STATUS_UNLOADING,
  3_0: workStatus.WORK_STATUS_UNLOADING_END,
  4_1: workStatus.WORK_STATUS_BREAK,
  4_0: workStatus.WORK_STATUS_BREAK_END,
  5_1: workStatus.WORK_STATUS_OTHER,
  5_0: workStatus.WORK_STATUS_OTHER_END,
  6_1: workStatus.WORK_STATUS_HIGH_WAY,
  6_0: workStatus.WORK_STATUS_GENERAL_WAY,
  7_1: workStatus.WORK_STATUS_ACTUAL_CAR,
  7_0: workStatus.WORK_STATUS_EMPTY_CAR,
  8_1: workStatus.WORK_STATUS_REFUELING,
  8_0: workStatus.WORK_STATUS_REFUELING_END,
  9_1: workStatus.WORK_STATUS_WAIT,
  9_0: workStatus.WORK_STATUS_WAIT_END,
  10_1: workStatus.WORK_STATUS_MAINTENANCE,
  10_0: workStatus.WORK_STATUS_MAINTENANCE_END,
  11_1: workStatus.WORK_STATUS_REST,
  11_0: workStatus.WORK_STATUS_REST_END,
  14_1: workStatus.WORK_STATUS_SHIPPING,
  14_0: workStatus.WORK_STATUS_SHIPPING_END,
  15_1: workStatus.WORK_STATUS_INCIDENTAL_WORK,
  15_0: workStatus.WORK_STATUS_INCIDENTAL_WORK_END,
  1_0: workStatus.WORK_STATUS_RETURNING_WAREHOUSE,
}

export const IGNORE_STATUS = [
  workStatus.WORK_STATUS_HIGH_WAY,
  workStatus.WORK_STATUS_GENERAL_WAY,
  workStatus.WORK_STATUS_ACTUAL_CAR,
  workStatus.WORK_STATUS_EMPTY_CAR,
  workStatus.WORK_STATUS_OTHER,
  workStatus.WORK_STATUS_OTHER_END,
  workStatus.WORK_STATUS_MAINTENANCE,
  workStatus.WORK_STATUS_MAINTENANCE_END,
]

export const IGNORE_STATUS_FOR_TIMELINE = [
  workStatus.WORK_STATUS_HIGH_WAY,
  workStatus.WORK_STATUS_GENERAL_WAY,
  workStatus.WORK_STATUS_OTHER,
  workStatus.WORK_STATUS_OTHER_END,
  workStatus.WORK_STATUS_MAINTENANCE,
  workStatus.WORK_STATUS_MAINTENANCE_END,
]

export const WORK_STATUS_FOR_VEHICLE_MAP = [
  workStatus.WORK_STATUS_LEAVING_WAREHOUSE,
  workStatus.WORK_STATUS_LOADING,
  workStatus.WORK_STATUS_UNLOADING,
  workStatus.WORK_STATUS_SHIPPING,
  workStatus.WORK_STATUS_INCIDENTAL_WORK,
  workStatus.WORK_STATUS_REFUELING,
  workStatus.WORK_STATUS_WAIT,
  workStatus.WORK_STATUS_BREAK,
  workStatus.WORK_STATUS_REST,
  workStatus.WORK_STATUS_RETURNING_WAREHOUSE,
  workStatus.WORK_STATUS_IN_SERVICE,
  workStatus.WORK_STATUS_STOPPING,
] as const
export type WORK_STATUS_FOR_VEHICLE_MAP =
  typeof WORK_STATUS_FOR_VEHICLE_MAP[number]

export const ENGINE_OFF_THRESHOLD_MINUTES = 3

export const ALERT_STATUS_MAP: { [index: string]: string } = {
  1: alertStatus.ALERT_STATUS_RAPID_DECELERATION,
  2: alertStatus.ALERT_STATUS_RAPID_ACCELERATION,
  3: alertStatus.ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN,
  4: alertStatus.ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN,
  5: alertStatus.ALERT_STATUS_OVER_SPEED,
}

export const SHOW_MARKER_SETTING = 'SHOW_MARKER_SETTING'
export const VEHICLE_MARKER_DIRECTION_RIGHT = 'right'
export const VEHICLE_MARKER_DIRECTION_LEFT = 'left'

export const MULTISELECT_CLASSES_PARAM = {
  container:
    'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-gray-300 rounded-md bg-white text-base leading-snug outline-none',
  containerDisabled: 'cursor-default bg-gray-100',
  containerOpen: 'rounded-b-none',
  containerOpenTop: 'rounded-t-none',
  containerActive: 'ring ring-blue-100 ring-opacity-30',
  singleLabel:
    'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border',
  singleLabelText:
    'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
  multipleLabel:
    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
  search:
    'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
  tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2',
  tag: 'bg-blue-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap',
  tagDisabled: 'pr-2 opacity-50',
  tagRemove:
    'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
  tagRemoveIcon:
    'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
  tagsSearchWrapper:
    'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
  tagsSearch:
    'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
  tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
  placeholder:
    'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
  caret:
    'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
  caretOpen: 'rotate-180 pointer-events-auto',
  clear:
    'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80',
  clearIcon:
    'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
  spinner:
    'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0',
  dropdown:
    'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
  dropdownTop:
    '-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t',
  dropdownHidden: 'hidden',
  options: 'flex flex-col p-0 m-0 list-none',
  optionsTop: 'flex-col-reverse',
  group: 'p-0 m-0',
  groupLabel:
    'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal',
  groupLabelPointable: 'cursor-pointer',
  groupLabelPointed: 'bg-gray-300 text-gray-700',
  groupLabelSelected: 'bg-blue-600 text-white',
  groupLabelDisabled: 'bg-gray-100 text-gray-300 cursor-not-allowed',
  groupLabelSelectedPointed: 'bg-blue-600 text-white opacity-90',
  groupLabelSelectedDisabled:
    'text-blue-100 bg-blue-600 bg-opacity-50 cursor-not-allowed',
  groupOptions: 'p-0 m-0',
  option:
    'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
  optionPointed: 'text-gray-800 bg-gray-100',
  optionSelected: 'text-white bg-blue-500',
  optionDisabled: 'text-gray-300 cursor-not-allowed',
  optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
  optionSelectedDisabled:
    'text-blue-100 bg-blue-500 bg-opacity-50 cursor-not-allowed',
  noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
  noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
  fakeInput:
    'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
  spacer: 'h-9 py-px box-content',
}

export const REST_THRESHOLD_MILLI_SECCONDS = 3 * 60 * 60 * 1000
export const REST_THRESHOLD_SECCONDS = REST_THRESHOLD_MILLI_SECCONDS / 1000

export const STATUS_ICON_MAPPING = {
  [alertStatus.ALERT_STATUS_OVER_SPEED]: { icon: '\ue9e4' },
  [alertStatus.ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN]: {
    icon: '\ueba2',
  },
  [alertStatus.ALERT_STATUS_RAPID_ACCELERATION]: { icon: '\ue0b2' },
  [alertStatus.ALERT_STATUS_RAPID_DECELERATION]: { icon: '\ue0b5' },
  [workStatus.WORK_STATUS_BREAK]: { icon: '\ue541' },
  [workStatus.WORK_STATUS_REST]: { icon: '\ue549' },
  [workStatus.WORK_STATUS_REFUELING]: { icon: '\ue1a3' },
  [workStatus.WORK_STATUS_LEAVING_WAREHOUSE]: { icon: '\ue9ba' },
  [workStatus.WORK_STATUS_RETURNING_WAREHOUSE]: { icon: '\uea77' },
  [workStatus.WORK_STATUS_WAIT]: { icon: '\ue425' },
  [workStatus.WORK_STATUS_LOADING]: { icon: '\ue2c4' },
  [workStatus.WORK_STATUS_UNLOADING]: { icon: '\ue2c6' },
  [workStatus.WORK_STATUS_INCIDENTAL_WORK]: { icon: '\uf10c' },
  [workStatus.WORK_STATUS_SHIPPING]: { icon: '\ue163' },
  [sensorAlertStatus.ALERT_OVER_THRESHOLD]: {
    color: 'red',
    icon: 'device_thermostat',
  },
  [sensorAlertStatus.ALERT_UNDER_THRESHOLD]: {
    color: '#13F',
    icon: 'device_thermostat',
  },
  [sensorAlertStatus.ALERT_SENSOR_ERROR]: { icon: 'device_thermostat' },
}

export const REFUELING_STATIONS = [{ val: '自社給油' }, { val: '他社給油' }]
