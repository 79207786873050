import AsyncLock from 'async-lock'

export default class RestApiBase {
  static lock = new AsyncLock()

  public static async sync(
    key: string,
    method: Function,
    param: any = null
  ): Promise<any> {
    const res = await this.lock.acquire(key, async () => {
      try {
        if (param) {
          return await method(param)
        } else {
          return await method()
        }
      } catch (error) {
        return
      }
    })

    return new Promise((resolve) => {
      resolve(res)
    })
  }
}
