
import { defineComponent, PropType } from 'vue'
import AlertChart from '@/components/AlertChart.vue'
import AlertVideo from '@/components/AlertVideo.vue'
import { formatDateTimeWithSeconds } from '@/services/functions'
import routeMapMarker from '@/types/routeMapMarker'
import { markerType } from '@/services/const'
import { timeLinePosition, timeLineProducer } from '@/types/timeLinePosition'
import alertVideo from '@/types/alertVideo'
import vLoaderAnimation from '@/components/template/v-loader-animation.vue'
import i18n from '@/lang/i18n'

export default defineComponent({
  name: 'AlertDetail',
  setup() {
    return {
      formatDateTimeWithSeconds,
      i18n,
    }
  },
  props: {
    index: {
      type: Number as PropType<number>,
    },
    marker: {
      type: Object as PropType<routeMapMarker>,
      required: true,
    },
  },
  data() {
    return {
      timeLinePosition: {
        positionInPercentage: 0,
        producer: timeLineProducer.CONTAINER,
        producerID: 0,
      } as timeLinePosition,
      seekedTimeLinePosition: {
        positionInPercentage: 0,
        producer: timeLineProducer.CONTAINER,
        producerID: 0,
      } as timeLinePosition,
      allVideosArePlaying: false,
      arReadyToPlayVideoIDs: [] as Array<Number>,
      arWaitingVideoIDs: [] as Array<Number>,
      arErrorVideoIDs: [] as Array<Number>,
    }
  },
  mounted() {},
  computed: {
    mainVideo(): alertVideo | undefined {
      if (this.marker?.videos?.length) {
        return this.marker.videos[0]
      }
      return undefined
    },
    arSubVideos(): alertVideo[] {
      if (this.marker?.videos?.length) {
        return this.marker.videos.slice(1)
      }
      return []
    },
    isAllVideosReadyToAutoPlay(): boolean {
      return (
        this.arReadyToPlayVideoIDs.length === this.arVideoWithOutError.length
      )
    },
    oneOfVideosIsWaiting(): boolean {
      return this.arWaitingVideoIDs.length > 0
    },
    arVideoWithOutError(): alertVideo[] {
      if (!this.marker?.videos?.length) {
        return []
      }
      return this.marker.videos.filter((video, videoID) => {
        const index = this.arErrorVideoIDs.indexOf(videoID)
        return index === -1
      })
    },
    oneOfVideosHasDownloadProblems(): boolean {
      return this.arErrorVideoIDs.length > 0
    },
    isContentPreparingToBeShown(): boolean {
      return (
        !this.oneOfVideosHasDownloadProblems && !this.isAllVideosReadyToAutoPlay
      )
    },
  },
  watch: {},
  methods: {
    changeTimelinePositionEvent(timelinePosition: timeLinePosition): void {
      if (
        this.timeLinePosition.positionInPercentage !=
        timelinePosition.positionInPercentage
      ) {
        this.timeLinePosition = timelinePosition
      }
    },
    videoSeekedEvent(timelinePosition: timeLinePosition): void {
      if (
        this.seekedTimeLinePosition.positionInPercentage !=
        timelinePosition.positionInPercentage
      ) {
        this.seekedTimeLinePosition = timelinePosition
      }
    },
    getMarkerName(marker: routeMapMarker): string {
      if (marker.marker === markerType.ALERT) {
        return this.$t('common.alerts.' + marker.type)
      } else if (marker.marker === markerType.SENSOR_ALERT) {
        return this.$t('common.sensorAlerts.' + marker.type)
      } else {
        return this.$t('common.workStatus.' + marker.type + '.name')
      }
    },
    videoReadyToPlayEvent(videoID: number): void {
      const index = this.arReadyToPlayVideoIDs.indexOf(videoID)
      if (index == -1) {
        this.arReadyToPlayVideoIDs.push(videoID)
      }
    },
    addVideoToErrorList(videoID: number): void {
      const index = this.arErrorVideoIDs.indexOf(videoID)
      if (index !== -1) {
        return
      }

      this.arErrorVideoIDs.push(videoID)
    },
    addVideoInWaitingList(videoID: number): void {
      const index = this.arWaitingVideoIDs.indexOf(videoID)
      if (index == -1) {
        this.arWaitingVideoIDs.push(videoID)
      }
    },
    removeVideoFromWaitingList(videoID: number): void {
      const index = this.arWaitingVideoIDs.indexOf(videoID)
      if (index == -1) return
      this.arWaitingVideoIDs.splice(index, 1)
    },
    infoWindowClick(marker: routeMapMarker): void {
      if (!marker.link) return
      window.open(marker.link, '_blank')
    },
  },
  components: {
    vLoaderAnimation,
    AlertChart,
    AlertVideo,
  },
})
