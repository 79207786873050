import vehicle from '@/types/vehicle'
import { workStatus } from '@/services/const'
import subscribeToVehicleResponse from '@/types/responses/subscribeToVehicleResponse'
import subscribeToSensorResponse from '@/types/responses/subscribeToSensorResponse'
import SubscribeProvider from '@/services/subscribe/providers/SubscribeProvider'
import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'
import { Traffic, Status, Sensor } from '@/API'

class DummyProvider implements SubscribeProvider {
  private static getRandomNumber(): number {
    return Math.floor(Math.random() * 10) * 0.00001
  }

  public switchAlertNotification(flag: boolean) {
    console.log(flag)
  }
  public trafficAction = async (
    traffic: Traffic,
    vehicle: vehicle,
    cb: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ) => {}
  public statusAction = async (
    st: Status,
    vehicle: vehicle,
    cb: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ) => {}
  public sensorAction = async (sensor: Sensor, vehicle: vehicle) => {}
  public subscribeToVehicleSubscriptions(
    vehicle: vehicle,
    trafficAction: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void,
    statusAction: (
      vehicle: vehicle,
      position: subscribeToVehicleResponse,
      payload: Traffic | Status
    ) => void
  ): void {}
}

export default DummyProvider
