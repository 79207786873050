
import { defineComponent, PropType } from 'vue'
import vehicle from '@/types/vehicle'
import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'

export default defineComponent({
  name: 'VehicleStatistics',
  setup() {},
  props: {
    vehicles: {
      type: Array as PropType<vehicle[]>,
      default: function () {
        return []
      },
    },
  },
  inject: [],
  computed: {
    finishedVehicleCount(): number {
      let count = 0
      this.vehicles?.map((vehicle) => {
        const status = new VehicleWorkStatus(vehicle.status)
        if (status.isFinished()) {
          count++
        }
      })
      return count
    },
    operationVehicleCount(): number {
      let count = 0
      this.vehicles?.map((vehicle) => {
        const status = new VehicleWorkStatus(vehicle.status)
        if (status.isInOperation()) {
          count++
        }
      })
      return count
    },
    restingAndStopVehicleCount(): number {
      let count = 0
      this.vehicles?.map((vehicle) => {
        const status = new VehicleWorkStatus(vehicle.status)
        if (status.isInRest() || status.isStop()) {
          count++
        }
      })
      return count
    },
    inServiceVehicleCount(): number {
      let count = 0
      this.vehicles?.map((vehicle) => {
        const status = new VehicleWorkStatus(vehicle.status)
        if (status.isInService()) {
          count++
        }
      })
      return count
    },
  },
  methods: {},
  components: {},
})
