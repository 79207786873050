import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  ListDriverSetFilteredByDriverPinQuery,
  DriverSet as DriverSetType,
} from '@/API'
import { listDriverSetFilteredByDriverPin } from '@/graphql/queries'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'

const DRIVER_SET_MAX_LIMIT = 5000
export default class DriverSet extends GraphQLApiBase {
  public static async selectByDriverPin(arg: {
    driver_pin: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<DriverSetType[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''

    if (!arg.orderby) arg.orderby = 'ASC'
    let countItems = 0

    let nextToken: string | null | undefined = undefined
    let merged: DriverSetType[] | null = null

    while ((!merged || nextToken) && countItems != arg.max) {
      let limit: number = DRIVER_SET_MAX_LIMIT
      if (arg.max && arg.max - countItems < DRIVER_SET_MAX_LIMIT)
        limit = arg.max - countItems
      const res = (await appSyncConnection.query({
        query: gql(listDriverSetFilteredByDriverPin),
        variables: {
          user_id: user_id,
          driver_pin: arg.driver_pin,
          sortDirection: arg.orderby,
          nextToken: nextToken,
          limit: limit,
        },
      })) as GraphQLResult<ListDriverSetFilteredByDriverPinQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listDriverSetFilteredByDriverPin
          ?.items as DriverSetType[]) || []
      )

      countItems = merged.length
      nextToken = res?.data?.listDriverSetFilteredByDriverPin?.nextToken
    }

    return new Promise((resolve) => {
      resolve(<DriverSetType[]>merged || [])
    })
  }
}
