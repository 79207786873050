/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const subscribeToTraffic = /* GraphQL */ `subscription SubscribeToTraffic($sn: String!) {
  subscribeToTraffic(sn: $sn) {
    id
    sn
    dgn
    tm
    sp
    sf
    eg
    ef
    ig
    th
    air
    lat
    lon
    acx
    acy
    acz
    agx
    agy
    agz
    gex
    gey
    gez
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToTrafficSubscriptionVariables,
  APITypes.SubscribeToTrafficSubscription
>;
export const subscribeToStatus = /* GraphQL */ `subscription SubscribeToStatus($serial_no: String!) {
  subscribeToStatus(serial_no: $serial_no) {
    id
    serial_no
    delivery_group_no
    time
    status_flag
    status
    latitude
    longitude
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToStatusSubscriptionVariables,
  APITypes.SubscribeToStatusSubscription
>;
export const subscribeToDriverSet = /* GraphQL */ `subscription SubscribeToDriverSet($serial_no: String!) {
  subscribeToDriverSet(serial_no: $serial_no) {
    id
    delivery_date
    serial_no
    driver_pin
    dgn
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToDriverSetSubscriptionVariables,
  APITypes.SubscribeToDriverSetSubscription
>;
export const subscribeToIccid = /* GraphQL */ `subscription SubscribeToIccid($serial_no: String!) {
  subscribeToIccid(serial_no: $serial_no) {
    id
    sim
    serial_no
    time
    dgn
    version
    fw_name
    ini_register_date
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToIccidSubscriptionVariables,
  APITypes.SubscribeToIccidSubscription
>;
export const subscribeToDeliveryPlan = /* GraphQL */ `subscription SubscribeToDeliveryPlan($serial_no: String!) {
  subscribeToDeliveryPlan(serial_no: $serial_no) {
    id
    delivery_date
    serial_no
    driver_code
    dgn
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToDeliveryPlanSubscriptionVariables,
  APITypes.SubscribeToDeliveryPlanSubscription
>;
export const subscribeToDeviceAlert = /* GraphQL */ `subscription SubscribeToDeviceAlert($sn: String!) {
  subscribeToDeviceAlert(sn: $sn) {
    id
    sn
    dgn
    cat
    alert
    th
    dv
    tm
    lat
    lon
    trp
    feature
    mpg
    bitrate
    fps
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToDeviceAlertSubscriptionVariables,
  APITypes.SubscribeToDeviceAlertSubscription
>;
export const subscribeToEtc = /* GraphQL */ `subscription SubscribeToEtc($serial_no: String!) {
  subscribeToEtc(serial_no: $serial_no) {
    id
    sim
    serial_no
    dgn
    time
    etc_info
    latitude
    longitude
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToEtcSubscriptionVariables,
  APITypes.SubscribeToEtcSubscription
>;
export const subscribeToSensor = /* GraphQL */ `subscription SubscribeToSensor($sn: String!) {
  subscribeToSensor(sn: $sn) {
    id
    sn
    dgn
    tm
    u1s
    u2s
    u3s
    u4s
    u5s
    u6s
    u7s
    u8s
    d11
    d12
    d21
    d22
    d31
    d32
    d41
    d42
    d51
    d52
    d61
    d62
    d71
    d72
    d81
    d82
    lat
    lon
    gst
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToSensorSubscriptionVariables,
  APITypes.SubscribeToSensorSubscription
>;
export const subscribeToVehicleSubscription = /* GraphQL */ `subscription SubscribeToVehicleSubscription($serial_no: String!) {
  subscribeToVehicleSubscription(serial_no: $serial_no) {
    serial_no
    tm
    topic
    payload
    id
    delivery_plan_id
    created_at
    ttl_timestamp
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.SubscribeToVehicleSubscriptionSubscriptionVariables,
  APITypes.SubscribeToVehicleSubscriptionSubscription
>;
