import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "main-map",
  class: "h-full relative"
}
const _hoisted_2 = { class: "relative h-full" }
const _hoisted_3 = { class: "bg-white" }
const _hoisted_4 = {
  class: "w-full absolute",
  style: {"height":"calc(100% - 208px - 48px)"},
  "data-test": "vehicle-list"
}
const _hoisted_5 = { class: "relative h-full overflow-auto" }
const _hoisted_6 = ["id"]
const _hoisted_7 = {
  key: 1,
  class: "h-full w-full absolute flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
}
const _hoisted_8 = { class: "max-w-md w-full space-y-8" }
const _hoisted_9 = {
  key: 0,
  class: "text-2xl font-semibold text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehicleStatistics = _resolveComponent("VehicleStatistics")!
  const _component_SearchOperationOffices = _resolveComponent("SearchOperationOffices")!
  const _component_SearchVehicle = _resolveComponent("SearchVehicle")!
  const _component_VehicleListElement = _resolveComponent("VehicleListElement")!
  const _component_LeftPanel = _resolveComponent("LeftPanel")!
  const _component_ToggleTraffic = _resolveComponent("ToggleTraffic")!
  const _component_ToggleMarker = _resolveComponent("ToggleMarker")!
  const _component_v_loader_animation = _resolveComponent("v-loader-animation")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_SpotCircle = _resolveComponent("SpotCircle")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.showMap)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_LeftPanel, {
              "open-text": _ctx.getLeftPaneMessage,
              onChangeVisibility: _cache[2] || (_cache[2] = ($event: any) => (this.paddingLeft = !this.paddingLeft))
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_VehicleStatistics, {
                      vehicles: 
                    _ctx.selectedOffices.length || _ctx.searchedVehicles.length
                      ? _ctx.searchedVehicles
                      : _ctx.vehicles
                  
                    }, null, 8, ["vehicles"]),
                    _createVNode(_component_SearchOperationOffices, {
                      items: _ctx.operationOffices,
                      multiple: true,
                      selectedItems: _ctx.selectedOffices,
                      placeholderColor: "color: rgba(107, 114, 128); font-size: 17px;",
                      height: "min-height: 48px;",
                      position: "mx-auto",
                      margin: "ml-2",
                      placeholder: 
                    _ctx.$t(
                      'pages.vehicleMap.leftPanel.searchOperationOffices.placeholder'
                    )
                  ,
                      onChange: _ctx.selectedOperationOffices
                    }, null, 8, ["items", "selectedItems", "placeholder", "onChange"]),
                    _createVNode(_component_SearchVehicle, {
                      vehicles: 
                    _ctx.selectedOffices.length || _ctx.searchedVehicles.length
                      ? _ctx.searchedVehicles
                      : _ctx.vehicles
                  ,
                      modelValue: _ctx.search,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                      "data-test": "search-vehicle-field"
                    }, null, 8, ["vehicles", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredVehicles, (vehicle, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: vehicle.device_id,
                          id: vehicle.device_id
                        }, [
                          _createVNode(_component_VehicleListElement, {
                            vehicle: vehicle,
                            "selected-vehicle": _ctx.selectedVehicle,
                            operationOfficeName: _ctx.officeName(vehicle),
                            "add-border": index + 1 !== _ctx.filteredVehicles.length,
                            onUpdateAddress: _ctx.updateAddress
                          }, null, 8, ["vehicle", "selected-vehicle", "operationOfficeName", "add-border", "onUpdateAddress"])
                        ], 8, _hoisted_6))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_SearchVehicle, {
                  vehicles: _ctx.vehicles,
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                  desktop: false,
                  "data-test": "search-vehicle-field-mobile"
                }, null, 8, ["vehicles", "modelValue"])
              ]),
              _: 1
            }, 8, ["open-text"]),
            _createVNode(_component_ToggleTraffic, {
              map: this.$refs.vehiclesMap,
              "data-test": "toggle-traffic",
              "tooltip-text": this.$t('pages.vehicleMap.tooltip.traffic')
            }, null, 8, ["map", "tooltip-text"]),
            _createVNode(_component_ToggleMarker, {
              value: _ctx.showMarkerSetting.vehicleName,
              icon: "badge",
              onToggleMarker: _ctx.toggleVehicleName,
              class: "second",
              "data-test": "toggle-vehicle-name",
              "tooltip-text": this.$t('pages.vehicleMap.tooltip.vehicleName')
            }, null, 8, ["value", "onToggleMarker", "tooltip-text"]),
            _createVNode(_component_ToggleMarker, {
              value: _ctx.showSpotCircle,
              icon: "location_on",
              onToggleMarker: _ctx.toggleShowSpotCircle,
              class: "third",
              "data-test": "toggle-spot",
              "tooltip-text": this.$t('pages.vehicleMap.tooltip.spot')
            }, null, 8, ["value", "onToggleMarker", "tooltip-text"]),
            _createVNode(_component_ToggleMarker, {
              value: _ctx.showSatellite,
              icon: "public",
              onToggleMarker: _ctx.toggleSatellite,
              class: "four",
              "data-test": "toggle-satellite",
              "tooltip-text": this.$t('pages.vehicleMap.tooltip.satellite')
            }, null, 8, ["value", "onToggleMarker", "tooltip-text"])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_ctx.mapReady)
                ? (_openBlock(), _createElementBlock("h1", _hoisted_9, _toDisplayString(this.$t('pages.vehicleMap.noVehicle.header')), 1))
                : (_openBlock(), _createBlock(_component_v_loader_animation, {
                    key: 1,
                    text: 'text-black',
                    "loading-text-style": 'text-black text-lg',
                    "loading-text": _ctx.$t('common.loadingText')
                  }, null, 8, ["loading-text"]))
            ])
          ]))
    ], 512), [
      [_vShow, _ctx.showMapUI]
    ]),
    (_ctx.showStreetViewUI)
      ? (_openBlock(), _createBlock(_component_ToggleMarker, {
          key: 0,
          value: _ctx.showStreetViewUI,
          icon: "close",
          onToggleMarker: _ctx.closeStreetView
        }, null, 8, ["value", "onToggleMarker"]))
      : _createCommentVNode("", true),
    _createVNode(_component_GMapMap, {
      center: _ctx.center,
      zoom: _ctx.defaultZoom,
      options: _ctx.mapOptions,
      class: _normalizeClass({
        'h-full': true,
        invisible: !_ctx.showMap,
        'padding-left-class': _ctx.paddingLeft,
        'allow-map-padding': _ctx.allowMapPadding,
      }),
      "map-type-id": _ctx.mapTypeId,
      ref: "vehiclesMap"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedOffices.length ||
        _ctx.searchedVehicles.length
          ? _ctx.searchedVehicles
          : _ctx.vehicles, (vehicle, index) => {
          return (_openBlock(), _createBlock(_component_GMapMarker, {
            key: index,
            position: vehicle.position,
            clickable: true,
            draggable: false,
            onClick: ($event: any) => (_ctx.choiceVehicle(vehicle)),
            label: _ctx.getVehicleText(vehicle),
            icon: _ctx.getVehicleIcon(vehicle),
            zIndex: _ctx.getZIndex(vehicle)
          }, null, 8, ["position", "onClick", "label", "icon", "zIndex"]))
        }), 128)),
        _createVNode(_component_SpotCircle, {
          spots: _ctx.filteredSpots,
          viewable: _ctx.showSpotCircle
        }, null, 8, ["spots", "viewable"])
      ]),
      _: 1
    }, 8, ["center", "zoom", "options", "class", "map-type-id"])
  ]))
}