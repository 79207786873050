class VehicleIconBuilder {
  protected selected: boolean = false
  protected carEmpty: boolean = true
  protected engineOn: boolean = true
  protected orientationRight: boolean = true
  protected arFileNameElements: Array<string> = []

  readonly basePath = '/images/'

  public setSelected(): this {
    this.selected = true
    return this
  }

  public setFull(): this {
    this.carEmpty = false
    return this
  }

  public setEngineOff(): this {
    this.engineOn = false
    return this
  }

  public setOrientationLeft(): this {
    this.orientationRight = false
    return this
  }

  public build(): string {
    this.initArPath()
    if (this.isEngineOff()) {
      this.prepareEngineOffIconPath()
    } else {
      this.prepareCarIconPath()
    }
    return this.getFinalPath()
  }

  private initArPath(): void {
    this.arFileNameElements = []
    this.arFileNameElements.push('car')
  }

  private prepareEngineOffIconPath(): void {
    this.arFileNameElements.push('stop')
    if (this.selected) {
      this.arFileNameElements.push('active')
    }
  }

  private isEngineOff(): boolean {
    return !this.engineOn
  }

  private prepareCarIconPath(): void {
    this.arFileNameElements.push(this.carEmpty ? 'empty' : 'full')

    if (this.selected) {
      this.arFileNameElements.push('active')
    }

    this.arFileNameElements.push(this.orientationRight ? 'right' : 'left')
  }

  private getFinalPath(): string {
    const directory = this.basePath
    const fileName = this.arFileNameElements.join('_')
    const extension = '.svg'

    return directory + fileName + extension
  }
}

export default VehicleIconBuilder
