import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import { ApolloClientOptions } from 'apollo-client'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'

const options: Partial<ApolloClientOptions<NormalizedCacheObject>> = {
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
}

class Client {
  public token: string | null
  private _beforeToken: string | null
  private _connection: any

  constructor() {
    this.token = null
    this._beforeToken = null
  }

  public connection() {
    if (!this.token) return null
    // check to update token
    if (this.token == this._beforeToken) return this._connection
    this._connection = new AWSAppSyncClient(
      {
        url: process.env.VUE_APP_GRAPHQL_API_END_POINT || '',
        region: process.env.VUE_APP_GRAPHQL_REGION || '',
        auth: {
          type: AUTH_TYPE.OPENID_CONNECT,
          jwtToken: () => this.getToken(),
        },
        disableOffline: true,
      },
      options
    )
    this._beforeToken = this.token
    return this._connection
  }

  private getToken() {
    return this.token || ''
  }
}

const client: any = new Client()

export default client
