import restApiConnection from '@/services/api/providers/Connection'
import userSessionResponse from '@/types/responses/userSessionResponse'
import RestApiBase from '@/services/models/RestApiBase'
import router from '@/routes'

export default class UserSession extends RestApiBase {
  public static async get(): Promise<userSessionResponse | undefined> {
    return this.sync('GetUserSession', this.getOwn)
  }

  public static getOwn = async (): Promise<userSessionResponse | undefined> => {
    let ret: userSessionResponse | undefined = undefined
    if (!restApiConnection.defaults?.headers?.common?.Authorization)
      return Promise.reject()

    await restApiConnection
      .get(process.env.VUE_APP_USER_SESSION_API_URL || '', { cache: true })
      .then((data) => {
        ret = data
      })
      .catch((error) => {
        if (Math.floor((error.response?.status ?? 0) / 100) == 4) {
          router.replace('/forbidden')
        }
      })
    return new Promise((resolve) => {
      resolve(ret)
    })
  }
}
