import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44596bf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      controls: "",
      src: _ctx.src,
      muted: "",
      ref: "video",
      playsinline: "",
      loop: "",
      preload: "metadata"
    }, null, 8, _hoisted_2)
  ]))
}