import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "page-content",
  ref: 'page-content',
  class: "m-4"
}
const _hoisted_2 = { class: "max-w-screen-xl m-auto" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "text-4xl mb-4" }
const _hoisted_5 = { class: "search-route-form flex flex-wrap md:flex-nowrap mb-8" }
const _hoisted_6 = { class: "w-full md:w-1/5 mb-3 md:mb-0" }
const _hoisted_7 = { class: "w-full md:w-1/5 mb-3 md:mb-0" }
const _hoisted_8 = { class: "w-full md:w-1/5 ml-0 mb-3 md:mb-0 md:ml-4" }
const _hoisted_9 = { class: "w-full md:w-2/5 ml-0 mb-3 md:mb-0 md:ml-4" }
const _hoisted_10 = {
  key: 1,
  class: "text-lg text-gray-800 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_v_label = _resolveComponent("v-label")!
  const _component_SearchOperationOffices = _resolveComponent("SearchOperationOffices")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_v_loader_animation = _resolveComponent("v-loader-animation")!
  const _component_route_list_table_view = _resolveComponent("route-list-table-view")!
  const _component_route_list_mobile_view = _resolveComponent("route-list-mobile-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('pages.searchRoute.header')), 1),
        _createVNode(_component_v_button, {
          class: "refresh-button w-14",
          onClick: _ctx.refreshRouteList,
          "data-test": "refresh-btn"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, {
              icon: "refresh",
              class: "flow-root w-auto"
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_v_label, {
            "v-for": 'operationOffices',
            label: _ctx.$t('pages.searchRoute.filter.operation_offices.label'),
            class: "text-lg md:mb-1"
          }, null, 8, ["label"]),
          _createVNode(_component_SearchOperationOffices, {
            items: _ctx.operationOffices,
            multiple: true,
            selectFirstIfOnlyOne: true,
            selectedItems: _ctx.selectedOffices,
            placeholder: 
              _ctx.$t(
                'pages.searchRoute.loadDriversAndVehicles.selectOperationOffices'
              )
            ,
            height: "min-height: 40px;",
            responsiveWidthClass: "responsive-width",
            onChange: _ctx.selectedOperationOffices
          }, null, 8, ["items", "selectedItems", "placeholder", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_v_label, {
            "v-for": 'vehicle',
            label: _ctx.$t('pages.searchRoute.filter.vehicle.label'),
            class: "text-lg md:mb-1"
          }, null, 8, ["label"]),
          _createVNode(_component_Multiselect, {
            modelValue: _ctx.selectedVehicle,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedVehicle) = $event)),
            options: 
              _ctx.filteredVehiclesByOperationOffices.length ||
              _ctx.selectedOffices.length
                ? _ctx.filteredVehiclesByOperationOffices
                : _ctx.vehicles
            ,
            placeholder: 
              _ctx.$t('pages.searchRoute.loadDriversAndVehicles.selectVehicle')
            ,
            searchable: true,
            label: 'key',
            valueProp: 'val',
            noOptionsText: _ctx.$t('pages.searchRoute.filter.noOptions.text'),
            noResultsText: _ctx.$t('pages.searchRoute.filter.notFound.text'),
            classes: _ctx.classes_param,
            "data-test": "car-filter"
          }, null, 8, ["modelValue", "options", "placeholder", "noOptionsText", "noResultsText", "classes"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_v_label, {
            "v-for": 'driver',
            label: _ctx.$t('pages.searchRoute.filter.driver.label'),
            class: "text-lg md:mb-1"
          }, null, 8, ["label"]),
          _createVNode(_component_Multiselect, {
            modelValue: _ctx.selectedDriver,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDriver) = $event)),
            options: 
              _ctx.filteredDriversByOperationOffices.length || _ctx.selectedOffices.length
                ? _ctx.filteredDriversByOperationOffices
                : _ctx.drivers
            ,
            placeholder: _ctx.$t('pages.searchRoute.filter.driver.label'),
            searchable: true,
            label: 'key',
            valueProp: 'val',
            noOptionsText: _ctx.$t('pages.searchRoute.filter.noOptions.text'),
            noResultsText: _ctx.$t('pages.searchRoute.filter.notFound.text'),
            classes: _ctx.classes_param,
            "data-test": "driver-filter"
          }, null, 8, ["modelValue", "options", "placeholder", "noOptionsText", "noResultsText", "classes"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_v_label, {
            "v-for": 'vehicle',
            label: _ctx.$t('pages.searchRoute.filter.date.label'),
            class: "text-lg md:mb-1"
          }, null, 8, ["label"]),
          _createVNode(_component_DatePicker, {
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
            "min-date": _ctx.minDate,
            range: "",
            locale: "ja",
            placeholder: _ctx.$t('pages.searchRoute.filter.date.placeholder'),
            cancelText: _ctx.$t('pages.searchRoute.filter.date.cancelText'),
            selectText: _ctx.$t('pages.searchRoute.filter.date.selectText'),
            autoApply: false,
            enableTimePicker: false,
            format: _ctx.formatArrayDateToArrayYYYYMMDD,
            previewFormat: _ctx.formatArrayDateToArrayYYYYMMDD,
            inputClassName: "border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none h-10",
            "data-test": "date-filter"
          }, null, 8, ["modelValue", "min-date", "placeholder", "cancelText", "selectText", "format", "previewFormat"])
        ])
      ]),
      (!_ctx.isPaginationRequest && _ctx.routeLoadStatus.loading)
        ? (_openBlock(), _createBlock(_component_v_loader_animation, {
            key: 0,
            text: 'text-black',
            "loading-text-style": 'text-black text-lg',
            "loading-text": _ctx.$t('common.loadingText'),
            "add-character-img": false
          }, null, 8, ["loading-text"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.routeLoadStatus.loaded)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.routes.length)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createVNode(_component_route_list_table_view, {
                          routes: _ctx.routes,
                          class: "hidden md:table"
                        }, null, 8, ["routes"]),
                        _createVNode(_component_route_list_mobile_view, {
                          routes: _ctx.routes,
                          class: "md:hidden"
                        }, null, 8, ["routes"]),
                        (_ctx.routeLoadStatus.loading)
                          ? (_openBlock(), _createBlock(_component_v_loader_animation, {
                              key: 0,
                              text: 'text-black',
                              "loading-text-style": 'text-black text-lg',
                              "loading-text": _ctx.$t('common.loadingText'),
                              "add-character-img": false
                            }, null, 8, ["loading-text"]))
                          : _createCommentVNode("", true)
                      ], 64))
                    : (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_ctx.$t('pages.searchRoute.notFound')), 1))
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
    ])
  ]))
}