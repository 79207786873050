import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  listStatusesFilteredBySerialNoWithUser,
  listStatusesFilteredByDeliveryGroupNoWithUser,
  listStatusFilteredBySerialNoAndDateTimeRangeWithUser,
} from '@/graphql/queries'
import {
  ListStatusesFilteredBySerialNoWithUserQuery,
  ListStatusesFilteredByDeliveryGroupNoWithUserQuery,
  Status as StatusType,
  ListStatusFilteredBySerialNoAndDateTimeRangeWithUserQuery,
} from '@/API'
import {
  workStatus,
  WORK_STATUS_FOR_VEHICLE_MAP,
  STATUS_MAP,
  ENGINE_OFF_THRESHOLD_MINUTES,
  IGNORE_STATUS,
  REST_THRESHOLD_SECCONDS,
} from '@/services/const'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'

import trafficElement from '@/types/trafficElement'
import routeEvent from '@/types/routeEvent'
import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'

const STATUS_MAX_LIMIT = 5000

export default class Status extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Status')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<StatusType[]> {
    let key = 'StatusDeliveryPlanID'
    if (max !== undefined) key += ` max:${max}`
    if (orderby) key += ` orderby:${orderby}`
    return this.cache(key, delivery_plan_id, this._selectByDeliveryPlanId, {
      delivery_plan_id,
      max,
      orderby,
    })
  }

  public static async selectBySerialNumber(
    serial_number: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<StatusType[]> {
    let key = 'StatusSerialNumber'
    if (max !== undefined) key += ` max:${max}`
    if (orderby) key += ` orderby:${orderby}`
    return this.cache(key, serial_number, this._selectBySerialNumber, {
      serial_number,
      max,
      orderby,
    })
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<StatusType[]> {
    return this.cache(
      'StatusSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      {
        serial_number,
        startDateTime,
        endDateTime,
      }
    )
  }

  public static async _selectByDeliveryPlanId(arg: {
    delivery_plan_id: string
    max?: number
  }): Promise<Status[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let nextToken: string | null | undefined = undefined
    let merged: Status[] | null = null
    let countItems = 0
    while ((!merged || nextToken) && countItems != arg.max) {
      let limit: number = STATUS_MAX_LIMIT
      if (arg.max && arg.max - countItems < STATUS_MAX_LIMIT)
        limit = arg.max - countItems
      const res = (await appSyncConnection.query({
        query: gql(listStatusesFilteredByDeliveryGroupNoWithUser),
        variables: {
          user_id: user_id,
          delivery_group_no: arg.delivery_plan_id,
          sortDirection: 'ASC',
          nextToken: nextToken,
          limit: limit,
        },
      })) as GraphQLResult<ListStatusesFilteredByDeliveryGroupNoWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listStatusesFilteredByDeliveryGroupNoWithUser
          ?.items as Status[]) || []
      )
      countItems = merged.length
      nextToken =
        res?.data?.listStatusesFilteredByDeliveryGroupNoWithUser?.nextToken
    }
    return new Promise((resolve) => {
      resolve(<Status[]>merged)
    })
  }

  public static async _selectBySerialNumber(arg: {
    serial_number: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<StatusType[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let merged: Status[] = []

    const res = (await appSyncConnection.query({
      query: gql(listStatusesFilteredBySerialNoWithUser),
      variables: {
        user_id: user_id,
        serial_no: arg.serial_number,
        sortDirection: arg.orderby ?? 'ASC',
        limit: arg.max ?? STATUS_MAX_LIMIT,
      },
    })) as GraphQLResult<ListStatusesFilteredBySerialNoWithUserQuery>
    merged = merged.concat(
      (res?.data?.listStatusesFilteredBySerialNoWithUser?.items as Status[]) ||
        []
    )
    return new Promise((resolve) => {
      resolve(<StatusType[]>merged)
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
  }): Promise<StatusType[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: StatusType[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listStatusFilteredBySerialNoAndDateTimeRangeWithUser),
        variables: {
          user_id: user_id,
          serial_no: arg.serial_number,
          startDateTime: arg.startDateTime,
          endDateTime: arg.endDateTime,
          sortDirection: 'ASC',
          limit: STATUS_MAX_LIMIT,
          nextToken: nextToken,
        },
      })) as GraphQLResult<ListStatusFilteredBySerialNoAndDateTimeRangeWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listStatusFilteredBySerialNoAndDateTimeRangeWithUser
          ?.items as StatusType[]) ?? []
      )
      nextToken =
        res?.data?.listStatusFilteredBySerialNoAndDateTimeRangeWithUser
          ?.nextToken
    }

    return new Promise((resolve) => {
      resolve(merged ?? [])
    })
  }

  public static convertStatus(
    vehicleStatus: StatusType,
    in_service: boolean = true
  ): workStatus {
    const { status, status_flag } = <StatusType>vehicleStatus
    const key = status_flag + '' + status
    if (!vehicleStatus) return workStatus.WORK_STATUS_NOTHING
    if (!in_service) {
      return workStatus.WORK_STATUS_RETURNING_WAREHOUSE
    }
    return <workStatus>(
      (STATUS_MAP[key] ? STATUS_MAP[key] : workStatus.WORK_STATUS_IN_SERVICE)
    )
  }

  public static convertWorkStatusToVehicleMapStatus(
    status: workStatus
  ): workStatus {
    if (status == workStatus.WORK_STATUS_NOTHING) {
      return workStatus.WORK_STATUS_NOTHING
    }
    if (WORK_STATUS_FOR_VEHICLE_MAP.indexOf(status) < 0) {
      return workStatus.WORK_STATUS_IN_SERVICE
    }
    return status
  }

  public static investigateVehicleStatus(
    status: workStatus,
    latestStatusTime: number,
    latestDrivingTime: number,
    currentTime: Date
  ): workStatus {
    if (
      status != workStatus.WORK_STATUS_IN_SERVICE &&
      status != workStatus.WORK_STATUS_LEAVING_WAREHOUSE &&
      status != workStatus.WORK_STATUS_STOPPING
    ) {
      return status
    }
    const time =
      latestStatusTime > latestDrivingTime
        ? latestStatusTime
        : latestDrivingTime
    const before10m = currentTime.setMinutes(currentTime.getMinutes() - 10)
    if (time <= before10m) {
      return workStatus.WORK_STATUS_STOPPING
    } else {
      return status
    }
  }

  public static isEngineOff(
    latestTrafficTime: Date,
    currentTime: Date
  ): boolean {
    const before10m = currentTime.setMinutes(
      currentTime.getMinutes() - ENGINE_OFF_THRESHOLD_MINUTES
    )
    return latestTrafficTime.getTime() <= before10m
  }

  public static makeEvents(
    traffics: trafficElement[],
    statuses: routeEvent[]
  ): routeEvent[] {
    let trafficIndex = 0
    let duration = { running: 0, stopping: 0, stoppingOverTenMinutes: 0 }
    let beforeStatus: routeEvent | null = null

    const TEN_MINUTES = 10 * 60

    statuses.forEach((status: routeEvent) => {
      if (IGNORE_STATUS.indexOf(status.type) >= 0) {
        return true
      }
      if (!beforeStatus) {
        beforeStatus = status
        return true
      }

      if (trafficIndex >= traffics.length) {
        trafficIndex = traffics.length - 1
      }
      const statusTime = status.date_time.getTime() / 1000
      const beforeStatusTime = beforeStatus.date_time.getTime() / 1000
      let continuesStopTime = 0

      for (
        ;
        trafficIndex < traffics.length && traffics.length > 0;
        trafficIndex++
      ) {
        const trafficTime = traffics[trafficIndex].date_time.getTime() / 1000
        const beforeTrafficIndex = trafficIndex && trafficIndex - 1
        const beforeTrafficTime =
          traffics[beforeTrafficIndex].date_time.getTime() / 1000
        const currentTrafficTime =
          traffics[trafficIndex].date_time.getTime() / 1000
        const trafficStatus =
          traffics[trafficIndex].speed == 0 ||
          trafficTime - beforeTrafficTime > TEN_MINUTES
            ? 'stopping'
            : 'running'

        // status直後のtrafficまで進める
        if (trafficTime < beforeStatusTime) continue

        // statusをtrafficが超えたら値をセット
        if (trafficTime > statusTime) {
          duration[trafficStatus] += statusTime - beforeTrafficTime
          // 10分超停車
          if (continuesStopTime >= TEN_MINUTES) {
            duration['stoppingOverTenMinutes'] += continuesStopTime
          }

          // 0以外の場合デクリメント
          trafficIndex && trafficIndex--
          break
        }

        let diffTime = 0
        if (duration['running'] == 0 && duration['stopping'] == 0) {
          diffTime = trafficTime - beforeStatusTime
        } else {
          diffTime = trafficTime - beforeTrafficTime
        }
        duration[trafficStatus] += diffTime

        // 10分超停車時間
        if (trafficStatus == 'stopping') {
          continuesStopTime += diffTime
        } else {
          if (continuesStopTime >= TEN_MINUTES) {
            duration['stoppingOverTenMinutes'] += continuesStopTime
          }
          continuesStopTime = 0
        }
      }

      if (beforeStatus) {
        const sum = statusTime - beforeStatusTime
        const stoppingOverTenMinutes =
          duration['running'] || sum < TEN_MINUTES
            ? duration['stoppingOverTenMinutes']
            : sum
        beforeStatus.duration = {
          summarySeconds: statusTime - beforeStatusTime,
          sum: this.convertSecondsToTimeString(statusTime - beforeStatusTime),
          running: this.convertSecondsToTimeString(duration['running']),
          stopping: this.convertSecondsToTimeString(sum - duration['running']),
          stoppingOverTenMinutes: this.convertSecondsToTimeString(
            stoppingOverTenMinutes
          ),
        }
      }
      duration = { running: 0, stopping: 0, stoppingOverTenMinutes: 0 }
      beforeStatus = status
    })

    statuses.forEach((status: routeEvent, i: number) => {
      const st = new VehicleWorkStatus(status.type)
      if (!st.isBreak()) return

      const beforeStatus = statuses[i - 1]

      if (
        status.type == workStatus.WORK_STATUS_BREAK &&
        (status.duration?.summarySeconds ?? 0) > REST_THRESHOLD_SECCONDS
      ) {
        status.type = st.getRestStatusByBreakStatus()
      }

      if (
        beforeStatus &&
        status.type == workStatus.WORK_STATUS_BREAK_END &&
        (beforeStatus.duration?.summarySeconds ?? 0) > REST_THRESHOLD_SECCONDS
      ) {
        status.type = st.getRestStatusByBreakStatus()
      }
    })
    return statuses
  }

  private static convertSecondsToTimeString(seconds: number): string {
    const date = new Date(0)
    date.setSeconds(seconds)
    return date.toISOString().substr(11, 8)
  }
}
