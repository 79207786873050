import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import WorkloadType from '@/types/workload'
import UserSession from '@/services/models/UserSession'
import RequestWorkload from '@/types/requests/workload'
import workloadResponse from '@/types/responses/workload'
import { AxiosResponse } from 'axios'

export default class SelfWorkload extends RestApiBase {
  public static async get(
    delivery_plan_id: string,
    page: number
  ): Promise<AxiosResponse<workloadResponse>> {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    const limit = 100
    return restApiConnection.get(
      `/workloads?user_session_id=${user_id}&delivery_plan_ids=${delivery_plan_id}&limit=${limit}&page=${page}`,
      {
        headers: {
          'Cache-Control': 'max-age=0',
        },
      }
    )
  }
  public static async show(id: number): Promise<AxiosResponse<WorkloadType>> {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.post(
      `/workloads/${id}'?user_session_id=` + user_id,
      {
        headers: {
          'Cache-Control': 'max-age=0',
        },
      }
    )
  }
  public static async post(
    request: RequestWorkload
  ): Promise<AxiosResponse<WorkloadType[]>> {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.post(
      '/workloads?user_session_id=' + user_id,
      request
    )
  }
  public static async put(
    id: number,
    request: RequestWorkload
  ): Promise<AxiosResponse<WorkloadType[]>> {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.put(
      `/workloads/${id}?user_session_id=` + user_id,
      request
    )
  }
  public static async delete(
    id: number
  ): Promise<AxiosResponse<WorkloadType[]>> {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.delete(
      `/workloads/${id}?user_session_id=` + user_id
    )
  }
}
