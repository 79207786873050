import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import driverModeLayout from '..//layouts/driverModeLayout.vue'
import vehiclesMap from '@/views/vehiclesMap.vue'
import routeMap from '@/views/routeMap.vue'
import driverMap from '@/views/driverMap.vue'
import searchRoute from '@/views/searchRoute.vue'
import notFound from '@/views/notFound.vue'
import ForbiddenPage from '@/views/ForbiddenPage.vue'
import IrregularLayout from '@/layouts/IrregularLayout.vue'
import UserSession from '@/services/models/UserSession'
import { RouteLocationNormalized } from 'vue-router'
import { watchEffect } from 'vue'
import { useAuth } from '@/services/user/Auth0UserProvider'
import { useRoute } from 'vue-router'

const DRIVER_MODE_PATH = '/driver-mode'

// Uses boolean return and thus requires Vue 3 router.
export const useRouteGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const { isAuthenticated, loading, loginWithRedirect } = useAuth()

  const verify = () => {
    // If the user is authenticated, continue with the route
    if (isAuthenticated.value) {
      return true
    }

    // Otherwise, log in
    loginWithRedirect({ appState: { targetUrl: to.fullPath } })
    return false
  }

  // If loading has already finished, check our auth state using `verify()`
  if (!loading.value) {
    return verify()
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify()
    }
  })
}

//Determine if the URL of the specified page matches the URL of the current page.
export const isItThePage = (pageName: string): boolean => {
  const route = useRoute()
  const url = routeList.get(pageName)
  if (typeof url === 'string' && route.fullPath === url) {
    return true
  }
  return false
}

const routes = <Array<RouteRecordRaw>>[
  {
    path: '/',
    name: 'vehiclesMap',
    component: vehiclesMap,
    beforeEnter: useRouteGuard,
  },
  {
    path: '/route/',
    name: 'searchRoute',
    component: searchRoute,
    beforeEnter: useRouteGuard,
  },
  {
    path: '/route/:delivery_plan_id',
    name: 'routeMap',
    component: routeMap,
    beforeEnter: useRouteGuard,
  },
  {
    path: DRIVER_MODE_PATH,
    name: 'DriverMode',
    component: driverMap,
    beforeEnter: useRouteGuard,
    meta: {
      layout: driverModeLayout,
    },
  },
  {
    path: '/forbidden',
    component: ForbiddenPage,
    meta: {
      layout: IrregularLayout,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: notFound,
  },
]

export const redirectDriverMode = async (): Promise<boolean> => {
  const user = await UserSession.getOwn()
  const path = router.currentRoute.value.path

  return new Promise((resolve) => {
    if (user?.data?.enabled_driver_mode && path != DRIVER_MODE_PATH) {
      resolve(true)
      router.replace({ name: 'DriverMode' })
    } else if (!user?.data?.enabled_driver_mode && path == DRIVER_MODE_PATH) {
      resolve(true)
      router.replace({ name: 'vehiclesMap' })
    }
    resolve(false)
  })
}

//An map whose key is name and whose value is path.
export const routeList: Map<string, string> = new Map()
routes.forEach((route) =>
  routeList.set(route.name?.toString() ?? '', route.path)
)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
