import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cf979b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-2" }
const _hoisted_2 = { class: "text-right note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChartLine = _resolveComponent("ChartLine")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ChartLine, {
        "chart-options": _ctx.chartOptions,
        "chart-data": _ctx.chartData,
        "chart-id": _ctx.chartId,
        "dataset-id-key": _ctx.datasetIdKey,
        plugins: _ctx.plugins,
        "css-classes": _ctx.cssClasses,
        styles: _ctx.styles,
        height: _ctx.height,
        width: _ctx.width,
        ref: "chartInstance"
      }, null, 8, ["chart-options", "chart-data", "chart-id", "dataset-id-key", "plugins", "css-classes", "styles", "height", "width"])
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('pages.routeMap.routeDetail.chartNote.text')), 1)
  ], 64))
}