import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import OperationOfficeType from '@/types/operationOffice'
import UserSession from '@/services/models/UserSession'
import { AxiosResponse } from 'axios'

export default class SelfOffice extends RestApiBase {
  public static async get(): Promise<AxiosResponse<OperationOfficeType[]>> {
    return this.sync('GetOperationOffices', this.getOwn)
  }

  public static getOwn = async (): Promise<
    AxiosResponse<OperationOfficeType[]>
  > => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.get(
      '/operation_offices?user_session_id=' + user_id,
      {
        cache: true,
      }
    )
  }
}
