import { alertStatus, workStatus } from '@/services/const'

const arSpeedHistory = [
  {
    speed: 59,
    time: new Date('2022-05-31T09:12:15'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:16'),
  },
  {
    speed: 62.16606,
    time: new Date('2022-05-31T09:12:17'),
  },
  {
    speed: 62.16606,
    time: new Date('2022-05-31T09:12:18'),
  },
  {
    speed: 62.16606,
    time: new Date('2022-05-31T09:12:19'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:20'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:21'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:22'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:23'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:24'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:25'),
  },
  {
    speed: 53.68887,
    time: new Date('2022-05-31T09:12:26'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:27'),
  },
  {
    speed: 53.68887,
    time: new Date('2022-05-31T09:12:28'),
  },
  {
    speed: 53.68887,
    time: new Date('2022-05-31T09:12:29'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:30'),
  },
  {
    speed: 56.5146,
    time: new Date('2022-05-31T09:12:31'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:32'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:33'),
  },
  {
    speed: 62.16606,
    time: new Date('2022-05-31T09:12:34'),
  },
  {
    speed: 59.34033000000001,
    time: new Date('2022-05-31T09:12:35'),
  },
]

const arGyroscopeHistory = [
  {
    X: -0.02,
    Y: 0,
    Z: 0.08,
    time: new Date('2022-05-31T09:12:15'),
  },
  {
    X: 0,
    Y: 0.06,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:16'),
  },
  {
    X: 0.02,
    Y: -0.02,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:16'),
  },
  {
    X: -0.02,
    Y: -0.06,
    Z: -0.02,
    time: new Date('2022-05-31T09:12:17'),
  },
  {
    X: 0.04,
    Y: -0.16,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:18'),
  },
  {
    X: 0.08,
    Y: -0.02,
    Z: 0.1,
    time: new Date('2022-05-31T09:12:19'),
  },
  {
    X: 0,
    Y: -0.28,
    Z: 0.14,
    time: new Date('2022-05-31T09:12:20'),
  },
  {
    X: 0,
    Y: -0.04,
    Z: -0.14,
    time: new Date('2022-05-31T09:12:21'),
  },
  {
    X: 0,
    Y: 0.16,
    Z: 0.38,
    time: new Date('2022-05-31T09:12:22'),
  },
  {
    X: 0,
    Y: -0.02,
    Z: 0.24,
    time: new Date('2022-05-31T09:12:23'),
  },
  {
    X: 0.06,
    Y: -0.04,
    Z: 0,
    time: new Date('2022-05-31T09:12:24'),
  },
  {
    X: 0.08,
    Y: -0.06,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:25'),
  },
  {
    X: 0.02,
    Y: 0.02,
    Z: 0,
    time: new Date('2022-05-31T09:12:26'),
  },
  {
    X: 0,
    Y: -0.08,
    Z: -0.12,
    time: new Date('2022-05-31T09:12:27'),
  },
  {
    X: 0.04,
    Y: -0.2,
    Z: 0.12,
    time: new Date('2022-05-31T09:12:28'),
  },
  {
    X: -0.08,
    Y: -0.02,
    Z: -0.14,
    time: new Date('2022-05-31T09:12:29'),
  },
  {
    X: 0,
    Y: 0.06,
    Z: -0.04,
    time: new Date('2022-05-31T09:12:30'),
  },
  {
    X: 0,
    Y: 0.04,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:31'),
  },
  {
    X: 0.06,
    Y: 0.02,
    Z: 0,
    time: new Date('2022-05-31T09:12:32'),
  },
  {
    X: 0.02,
    Y: 0,
    Z: 0.02,
    time: new Date('2022-05-31T09:12:33'),
  },
  {
    X: 0,
    Y: 0,
    Z: -0.02,
    time: new Date('2022-05-31T09:12:34'),
  },
  {
    X: 0.02,
    Y: -0.02,
    Z: 0,
    time: new Date('2022-05-31T09:12:35'),
  },
]

const routeResponse = {
  path: [
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.164652,
      lng: 11.594617,
      date_time: new Date('2020-12-17T14:43:52'),
      speed: 65.5,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.168037,
      lng: 11.596626,
      date_time: new Date('2020-12-17T14:43:52'),
      speed: 65.5,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.168058,
      lng: 11.596669,
      date_time: new Date('2020-12-17T14:48:52'),
      speed: 30,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.169067,
      lng: 11.597141,
      date_time: new Date('2020-12-17T15:50:52'),
      speed: 30,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.169846,
      lng: 11.593784,
      date_time: new Date('2020-12-17T14:52:52'),
      speed: 20,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.18704,
      lng: 11.612143,
      date_time: new Date('2020-12-17T14:58:52'),
      speed: 90,
    },
    {
      dgn: '898103000002717662422053017022000267',
      sn: 'D00099000045',
      lat: 48.186683,
      lng: 11.613231,
      date_time: new Date('2020-12-17T14:58:52'),
      speed: 5,
    },
  ],
  alerts: [
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T09:53:52'),
      type: alertStatus.ALERT_STATUS_OVER_SPEED,
      position: {
        lat: 48.168058,
        lng: 11.596669,
      },
      videos: [
        // {
        //   src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220409101934/mp4/video_main_D00099000031_202204091019341.mp4',
        // },
        {
          src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220505132258/mp4/video_main_D00099000031_20220505132258.mp4',
          // src: 'http://techslides.com/demos/sample-videos/small.mp4',
        },
        {
          // src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220507122333/mp4/video_main_D00099000031_20220507122333.mp4',
          // src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          src: 'http://techslides.com/demos/sample-videos/small.mp4',
        },
        // {
        //   src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220409101934/mp4/video_main_D00099000031_20220409101934.mp4',
        // },
      ],
      arSpeedHistory: arSpeedHistory,
      arGyroscopeHistory: arGyroscopeHistory,
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:56:52'),
      type: alertStatus.ALERT_STATUS_RAPID_ACCELERATION,
      position: {
        lat: 48.164652,
        lng: 11.594617,
      },
      videos: [
        {
          src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220505132258/mp4/video_main_D00099000031_20220505132258.mp4',
          // src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220505132258/mp4/video_main_D00099000031_2022050513225811111.mp4',
          // src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        },
      ],
      arSpeedHistory: arSpeedHistory,
      arGyroscopeHistory: arGyroscopeHistory,
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:56:52'),
      type: alertStatus.ALERT_STATUS_RAPID_DECELERATION,
      position: {
        lat: 48.169067,
        lng: 11.597141,
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:56:52'),
      type: alertStatus.ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN,
      position: {
        lat: 48.186683,
        lng: 11.613231,
      },
      videos: [
        // {
        //   src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220409101934/mp4/video_main_D00099000031_202204091019341.mp4',
        // },
        {
          src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220505132258/mp4/video_main_D00099000031_20220505132258.mp4',
          // src: 'http://techslides.com/demos/sample-videos/small.mp4',
        },
        {
          src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220507122333/mp4/video_main_D00099000031_20220507122333.mp4',
          // src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
          // src: 'http://techslides.com/demos/sample-videos/small.mp4',
        },
        {
          src: 'https://digitaco-cms-storage-staging.smart-trucking.services/upload-videos/D00099000031_20220409101934/mp4/video_main_D00099000031_20220409101934.mp4',
        },
      ],
      arSpeedHistory: arSpeedHistory,
      arGyroscopeHistory: arGyroscopeHistory,
    },
  ],
  events: [
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:53:52'),
      type: workStatus.WORK_STATUS_LEAVING_WAREHOUSE,
      position: {
        lat: 48.173505,
        lng: 11.597328,
      },
      duration: {
        sum: '00:05:01',
        running: '00:03:01',
        stopping: '00:02:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:58:52'),
      type: workStatus.WORK_STATUS_REST,
      position: {
        lat: 48.184422,
        lng: 11.609294,
      },
      duration: {
        sum: '00:01:01',
        running: '00:01:00',
        stopping: '00:00:01',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T14:59:52'),
      type: workStatus.WORK_STATUS_BREAK,
      position: {
        lat: 48.185152,
        lng: 11.610045,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:05:52'),
      type: workStatus.WORK_STATUS_LOADING,
      position: {
        lat: 48.183135,
        lng: 11.607684,
      },
      duration: {
        sum: '02:00:01',
        running: '01:00:01',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:10:52'),
      type: workStatus.WORK_STATUS_UNLOADING,
      position: {
        lat: 48.182806,
        lng: 11.607427,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:15:52'),
      type: workStatus.WORK_STATUS_SHIPPING,
      position: {
        lat: 48.182713,
        lng: 11.607384,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:20:52'),
      type: workStatus.WORK_STATUS_INCIDENTAL_WORK,
      position: {
        lat: 48.182613,
        lng: 11.607298,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:25:52'),
      type: workStatus.WORK_STATUS_REFUELING,
      position: {
        lat: 48.182498,
        lng: 11.607202,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:30:52'),
      type: workStatus.WORK_STATUS_WAIT,
      position: {
        lat: 48.182333,
        lng: 11.607014,
      },
      duration: {
        sum: '02:00:00',
        running: '01:00:00',
        stopping: '01:00:00',
        stoppingOverTenMinutes: '00:02:00',
      },
    },
    {
      dgn: '898103000002717662422053017022000267',
      date_time: new Date('2020-12-17T15:35:52'),
      type: workStatus.WORK_STATUS_RETURNING_WAREHOUSE,
      position: {
        lat: 48.179175,
        lng: 11.603557,
      },
    },
  ],
  sensorAlerts: [],
  leaving_warehouse_date_time: new Date('2022-12-17T09:43:52'),
  returning_warehouse_date_time: new Date('2022-12-17T17:20:55'),
  device_id: 'D01005000018',
  registration_number: 'FH64F-600953',
  driver_name: '大輝 田口',
  delivery_plan_id: '343421d32f34g43g54',
  total_running_time: '(01:25)',
  total_mileage: 123.45,
}

export default routeResponse
