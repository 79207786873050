import sensor from '@/types/sensor'
import vehicle from '@/types/vehicle'
import sensorAlert from '@/types/sensorAlert'
import sensorAlertCount from '@/types/sensorAlertCount'
import sensorUnitTimes from '@/types/sensorUnitTimes'
import SensorModel from '@/services/models/Sensor'
import queryService from '@/services/query/QueryServiceFabrick'

import NotificationModel from '@/services/models/Sensor/Notification'

import Push from 'push.js'
import i18n from '@/lang/i18n'
import { formatDateTime } from '@/services/functions'

export default class RecoveredNotification extends NotificationModel {
  private originalType: keyof sensorAlertCount

  private notificationBits: string
  private alertTrueBit: string
  private alertFalseBit: string

  constructor(type: string) {
    const getCount = 5
    const threshold = 3
    super(type + '_recovered', getCount, threshold)
    this.originalType = type as keyof sensorAlertCount

    this.notificationBits = '11100'
    this.alertTrueBit = '1'
    this.alertFalseBit = '0'
  }

  protected notifyUnits(
    splitedSensors: sensor[][],
    vehicle: vehicle
  ): number[] {
    const notifyUnits: number[] = []
    const alertCounts: string[] = []

    splitedSensors
      .slice()
      .reverse()
      .forEach((splitedSensor) => {
        const sensorAlert = SensorModel.hasSensorError(splitedSensor)
        this.units.forEach((unit) => {
          if (!alertCounts[unit]) alertCounts[unit] = ''
          const latestNotificationTime = this.getLatestNotificationTime(
            vehicle.device_id,
            unit
          )

          if (sensorAlert[this.originalType].indexOf(unit) != -1) {
            alertCounts[unit] += this.alertTrueBit
          } else {
            alertCounts[unit] += this.alertFalseBit
          }

          if (
            sensorAlert[this.originalType].indexOf(unit) == -1 &&
            !latestNotificationTime &&
            alertCounts[unit] == this.notificationBits
          ) {
            notifyUnits.push(unit)
          }
        })
      })
    this.units.forEach((unit) => {
      if (!alertCounts[unit].match(/00$/))
        this.resetLatestNotificationTime(vehicle.device_id, unit)
    })
    return notifyUnits
  }
}
