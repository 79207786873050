import GeocoderInterface from '@/services/geocoder/providers/GeocoderInterface'
import Position from '@/types/position'

class GoogleAPiGeocoder implements GeocoderInterface {
  geocodeReverse(position: Position): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const geocoder = new google.maps.Geocoder()
      geocoder
        .geocode({ location: position })
        .then((response) => {
          if (response.results[0]) {
            resolve(response.results[0].formatted_address)
          } else {
            reject('No address found')
          }
        })
        .catch((e) => {
          reject('Geocoder failed due to: ' + e)
        })
    })
  }
}

export default GoogleAPiGeocoder
