import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  listAlertsFilteredByDgnWithUser,
  listAlertsFilteredBySnAndDateTimeRangeWithUser,
} from '@/graphql/queries'
import {
  ListAlertsFilteredByDgnWithUserQuery,
  DeviceAlert,
  ListAlertsFilteredBySnAndDateTimeRangeWithUserQuery,
} from '@/API'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'
import { ALERT_STATUS_MAP } from '../const'

const ALERT_MAX_LIMIT = 5000
export default class Alert extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Alert')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<DeviceAlert[]> {
    return this.cache(
      'AlertDeliveryPlanID',
      delivery_plan_id,
      this._selectByDeliveryPlanId,
      delivery_plan_id
    )
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<DeviceAlert[]> {
    return this.cache(
      'AlertSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      {
        serial_number,
        startDateTime,
        endDateTime,
      }
    )
  }

  public static async _selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<DeviceAlert[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let nextToken: string | null | undefined = undefined
    let merged: DeviceAlert[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listAlertsFilteredByDgnWithUser),
        variables: {
          user_id: user_id,
          dgn: delivery_plan_id,
          sortDirection: 'DESC',
          nextToken: nextToken,
          limit: ALERT_MAX_LIMIT,
        },
      })) as GraphQLResult<ListAlertsFilteredByDgnWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listAlertsFilteredByDgnWithUser?.items as DeviceAlert[]) ||
          []
      )
      nextToken = res?.data?.listAlertsFilteredByDgnWithUser?.nextToken
    }
    return new Promise((resolve) => {
      resolve(<DeviceAlert[]>merged || [])
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
  }): Promise<DeviceAlert[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: DeviceAlert[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listAlertsFilteredBySnAndDateTimeRangeWithUser),
        variables: {
          user_id: user_id,
          sn: arg.serial_number,
          startDateTime: arg.startDateTime,
          endDateTime: arg.endDateTime,
          sortDirection: 'ASC',
          limit: ALERT_MAX_LIMIT,
          nextToken: nextToken,
        },
      })) as GraphQLResult<ListAlertsFilteredBySnAndDateTimeRangeWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res.data?.listAlertsFilteredBySnAndDateTimeRangeWithUser
          ?.items as DeviceAlert[]) ?? []
      )
      nextToken =
        res.data?.listAlertsFilteredBySnAndDateTimeRangeWithUser?.nextToken
    }

    //有効なアラートのみを抽出
    const alertStatusKeys = Object.keys(ALERT_STATUS_MAP).map((key) =>
      Number(key)
    )
    merged = merged.filter((alert) => alertStatusKeys.includes(alert.alert))

    return new Promise((resolve) => {
      resolve(merged ?? [])
    })
  }
}
