import PolylinePattern from '@/services/map/polyline/PolylinePattern'

class SpeedDependsPolylinePattern implements PolylinePattern {
  public color: string
  protected minSpeed: number
  protected maxSpeed: number

  constructor(color: string, minSpeed: number, maxSpeed: number) {
    this.color = color
    this.minSpeed = minSpeed
    this.maxSpeed = maxSpeed
  }
  public isCover(speed: number): boolean {
    const speedRound = Math.floor(speed * 10) / 10
    return speedRound >= this.minSpeed && speedRound <= this.maxSpeed
  }
}

export default SpeedDependsPolylinePattern
