import { alertStatus, workStatus } from '@/services/const'
import trackingData from '@/types/trackingData'
import getVehiclesResponse from '@/types/responses/getVehicleResponse'
import getRouteResponse from '@/types/responses/getRouteResponse'
import getRouteDetailRequest from '@/types/requests/getRouteDetailRequest'
import ApiProvider from '@/services/api/providers/ApiProvider'
import loginRequest from '@/types/requests/loginRequest'
import loginResponse from '@/types/responses/loginResponse'
import findRouteRequest from '@/types/requests/findRouteRequest'
import findRouteResponse from '@/types/responses/findRouteResponse'
import getDriversAndVehiclesResponse from '@/types/responses/getDriversAndVehiclesResponse'
import OperationOfficeType from '@/types/operationOffice'
import spot from '@/types/spot'
import myOperationOffice from '@/types/myOperationOffice'
import vehicleResponse from '@/services/api/providers/dummyData/vehicleResponse'
import routeResponse from '@/services/api/providers/dummyData/routeResponse'
import workload from '@/types/workload'
import workloadItemAndUnitType from '@/types/workloadItemAndUnit'
import workloadResponse from '@/types/responses/workload'
import workloadItemType from '@/types/responses/workloadItem'
import workloadUnitType from '@/types/responses/workloadUnit'
import driverInfo from '@/types/driverInfo'
import RequestWorkload from '@/types/requests/workload'

class DummyApiProvider implements ApiProvider {
  public getVehicles(callback: Function): Promise<getVehiclesResponse> {
    return new Promise<getVehiclesResponse>((resolve) => {
      callback(vehicleResponse)
      setTimeout(() => resolve(vehicleResponse), 1000)
    })
  }

  public getCurrentRouteData(
    request: getRouteDetailRequest
  ): Promise<trackingData> {
    return new Promise((resolve) => resolve({}))
  }
  public getSpots = (): Promise<spot[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getRoute(request: getRouteDetailRequest): Promise<getRouteResponse> {
    return new Promise<getRouteResponse>((resolve, reject) => {
      setTimeout(() => resolve(routeResponse), 1000)
    })
  }

  public login(request: loginRequest): Promise<loginResponse> {
    return new Promise<loginResponse>((resolve, reject) => {
      if (request.login === 'error') {
        reject({
          message: 'test error',
        })
      }

      setTimeout(
        () =>
          resolve({
            user: {
              name: '大輝 田口',
              email: 'test@mail.com',
              id: '12354wsdf32',
              operation_office: [],
            },
          }),
        1000
      )
    })
  }

  public findRoute(
    request: findRouteRequest,
    forceUpdate: boolean = false
  ): Promise<findRouteResponse> {
    return new Promise<findRouteResponse>((resolve, reject) => {
      setTimeout(() => {
        const maxPage = 2
        const itemsPerPage = 10
        const page = request?.page ?? 1
        if (page > maxPage) {
          reject({
            message: 'wrong data',
          })
        }

        const routes = []
        for (let i = 0; i < itemsPerPage; i++) {
          routes.push({
            delivery_plan_id: '343421d32f34g43g54' + '-' + i + '-' + page,
            registration_number: 'FH64F-600953' + '-' + i + '-' + page,
            device_id: 'D01005000018' + '-' + i + '-' + page,
            driver_name: '大輝 田口' + '-' + i + '-' + page,
            returning_warehouse_date_time: new Date('2020-12-18T09:43:52'),
            leaving_warehouse_date_time: new Date('2020-12-17T09:43:52'),
            total_mileage: 123.45,
          })
        }

        resolve({
          routes: routes,
          page_num: page,
          total_pages: maxPage,
        })
      }, 1000)
    })
  }

  public getDriversAndVehicles(
    request: object
  ): Promise<getDriversAndVehiclesResponse> {
    return new Promise<getDriversAndVehiclesResponse>((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            vehicles: [
              {
                key: '大阪00の00-01',
                val: '222',
              },
              {
                key: '鳥取580は2844',
                val: '444',
              },
            ],
            drivers: [
              {
                key: '鳥取 太郎',
                val: '332212',
              },
              {
                key: '中川 ゆきな',
                val: '332211',
              },
            ],
          }),
        1000
      )
    })
  }
  public getDrivers = async (): Promise<driverInfo[]> => {
    return new Promise((resolve) => {
      resolve([
        {
          id: 1,
          driver_pin: 123456,
          name: 'ドライバー',
          operation_office_id: 1,
          telephone_number: '0120-xxxx-xxxx',
          email: 'xxxxx@example.com',
        },
      ])
    })
  }
  public getRoutesBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<getRouteResponse[]> => {
    // Unix timeをミリ秒に変換
    startDateTime *= 1000
    endDateTime *= 1000

    let routes: getRouteResponse[] = []
    for (let i = 0; i < 3; i++) {
      const route = {
        ...(await this.getRoute({
          delivery_plan_id: '898103000002717662422053017022000267',
        })),
      }
      // leaving warehouseをそれぞれ1日ずつずらす
      const newLeavingWarehouseDate = new Date(
        route.leaving_warehouse_date_time?.getTime() ?? 0
      )
      newLeavingWarehouseDate.setDate(newLeavingWarehouseDate.getDate() + i)
      route.leaving_warehouse_date_time = newLeavingWarehouseDate
      // returning warehouseをそれぞれ1日ずつずらす
      const newReturningWarehouseDate = new Date(
        route.returning_warehouse_date_time?.getTime() ?? 0
      )
      newReturningWarehouseDate.setDate(newReturningWarehouseDate.getDate() + i)
      route.returning_warehouse_date_time = newReturningWarehouseDate

      routes = routes.concat(route)
    }

    //filter by date
    routes = routes.filter((route) => {
      const leavingWarehouseDate = route.leaving_warehouse_date_time?.getTime()
      const returningWarehouseDate =
        route.returning_warehouse_date_time?.getTime()
      if (
        leavingWarehouseDate &&
        returningWarehouseDate &&
        leavingWarehouseDate >= startDateTime &&
        returningWarehouseDate <= endDateTime
      ) {
        return true
      }
    })
    return new Promise((resolve) => {
      resolve(routes)
    })
  }
  public getRouteRange = async (
    delivery_plan_id: string
  ): Promise<{
    serial_number: string
    startTime: number
    endTime: number
  }> => {
    return new Promise((resolve) => {
      resolve({
        serial_number: 'D00099000045',
        startTime: new Date('2022-12-17T09:43:52').getTime(),
        endTime: new Date('2022-12-17T17:20:55').getTime(),
      })
    })
  }
  public isNoLeavingAndReturningWarehouseRoute = async (
    delivery_plan_id: string
  ): Promise<boolean> => {
    return true
  }
  public getConcatNoLeavingAndReturningWarehouseRouteBySerialNoAndDateTimeRangeWithUser =
    async (
      serial_number: string,
      startDateTime: number,
      endDateTime: number
    ): Promise<getRouteResponse[]> => {
      const route = await this.getRoute({
        delivery_plan_id: '898103000002717662422053017022000267',
      })
      return new Promise((resolve) => {
        resolve([route])
      })
    }

  public getRouteFilterBySerialNumberAndDatetimeRange = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<{ routes: getRouteResponse[]; serial_number: string }> => {
    const route = await this.getRoute({
      delivery_plan_id: '898103000002717662422053017022000267',
    })
    return new Promise((resolve) => {
      resolve({ routes: [route], serial_number: 'D00099000045' })
    })
  }

  public getMyOperationOffice = async (): Promise<
    myOperationOffice | undefined
  > => {
    return new Promise((resolve) => {
      resolve({
        id: 1,
        name: '東京営業所',
        email: 'test@example.com',
        telephone_number: '090-1234-5678',
      })
    })
  }

  public getOperationOffices = async (): Promise<OperationOfficeType[]> => {
    return new Promise((resolve) => {
      resolve([
        {
          id: 1,
          name: '大阪営業所',
          email: 'test@example.com',
          telephone_number: '090-1234-5678',
        },
      ])
    })
  }

  public getWorkloads = async (
    delivery_plan_id: string,
    page: number
  ): Promise<workloadResponse> => {
    return new Promise((resolve) => {
      resolve({
        total_pages: 1,
        delivery_plans: [],
      })
    })
  }

  public getWorkload = async (id: number): Promise<workload> => {
    return new Promise((resolve) => {
      resolve({
        id: id,
        name: 'キャベツ',
        quantity: 1,
        unit: 'kg',
        work_id: '202310131743loading1697186515',
        delivery_plan_id: '202310131743',
        operation_office_id: 1,
        created_by: 1,
        updated_by: 1,
      })
    })
  }

  public createWorkloads = async (
    request: RequestWorkload
  ): Promise<workload[]> => {
    localStorage.setItem('workloads', JSON.stringify(request))
    return new Promise((resolve) => {
      resolve(
        localStorage.getItem('workloads')
          ? JSON.parse(localStorage.getItem('workloads') || '')
          : []
      )
    })
  }

  public updateWorkloads = async (
    id: number,
    request: RequestWorkload
  ): Promise<workload[]> => {
    localStorage.setItem('workloads', JSON.stringify(request))
    return new Promise((resolve) => {
      resolve(
        localStorage.getItem('workloads')
          ? JSON.parse(localStorage.getItem('workloads') || '')
          : []
      )
    })
  }

  public deleteWorkloads = async (id: number): Promise<workload[]> => {
    localStorage.removeItem('workloads')
    return new Promise((resolve) => {
      resolve(
        localStorage.getItem('workloads')
          ? JSON.parse(localStorage.getItem('workloads') || '')
          : []
      )
    })
  }
  public getWorkloadItems = async (): Promise<workloadItemType> => {
    return new Promise((resolve) => {
      resolve({
        total_pages: 1,
        workload_items: [],
      })
    })
  }
  public getWorkloadUnits = async (): Promise<workloadUnitType> => {
    return new Promise((resolve) => {
      resolve({
        total_pages: 1,
        workload_units: [],
      })
    })
  }
}

export default DummyApiProvider
