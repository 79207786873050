import restApiConnection from '@/services/api/providers/Connection'
import UserSession from '@/services/models/UserSession'
import spotsResponse from '@/types/responses/spotsResponse'
import RestApiBase from '@/services/models/RestApiBase'

export default class Spot extends RestApiBase {
  public static async all(): Promise<spotsResponse> {
    return this.sync('GetSpots', this.selectAll)
  }

  public static selectAll = async (): Promise<spotsResponse> => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.get('/spots?user_session_id=' + user_id, {
      cache: true,
    })
  }
}
