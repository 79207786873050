import axios, { AxiosRequestConfig } from 'axios'
import { AxiosAdapter } from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions'

import { useAuth } from '@/services/user/Auth0UserProvider'

const conn = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE_URL,
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    })
  ),
})

conn.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const userInfo = useAuth()
  if (userInfo.isAuthenticated.value) {
    const token = await userInfo.getTokenSilently()
    conn.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }
  return config
})

export default conn
