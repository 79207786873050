
import Api from '@/services/api/ApiServiceFabrick'
import subscribeService from '@/services/subscribe/SubscribeServiceFabrick'
import { Traffic, Status } from '@/API'
import SearchVehicle from '@/components/SearchVehicle.vue'
import ToggleTraffic from '@/components/ToggleTraffic.vue'
import ToggleMarker from '@/components/ToggleMarker.vue'
import {
  ENGINE_OFF_THRESHOLD_MINUTES,
  MAP_ICON_TEXT,
  VEHICLE_MARKER_DIRECTION_LEFT,
  workStatus,
} from '@/services/const'
import vehicle from '@/types/vehicle'
import spot from '@/types/spot'
import getVehicleResponse from '@/types/responses/getVehicleResponse'
import subscribeToVehicleResponse from '@/types/responses/subscribeToVehicleResponse'
import { defineComponent, nextTick } from 'vue'
import errorResponse from '@/types/responses/errorResponse'
import vLoaderAnimation from '@/components/template/v-loader-animation.vue'
import LeftPanel from '@/components/LeftPanel.vue'
import VehicleListElement from '@/components/VehicleListElement.vue'
import useTitle from '@/composables/useTitle'
import useMap from '@/composables/useMap'
import { sortVehicle } from '@/services/functions'
import SpotCircle from '@/components/SpotCircle.vue'
import VehicleStatistics from '@/components/VehicleStatistics.vue'
import position from '@/types/position'
import { getMarkerDirection } from '@/services/map/pointAngle/pointAngleHelper'

import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'
import VehicleIconBuilder from '@/services/map/vehicleIconBuilder/VehicleIconBuilder'
import setTitle from '@/services/title/setTitle'
import operationOffice from '@/types/operationOffice'
import SearchOperationOffices from '@/components/SearchFilter/SearchOperationOffices.vue'

const COUNT_POSITION_TO_SAVE = 8

export default defineComponent({
  name: 'vehiclesMap',
  props: {},
  setup: function () {
    const { pageTitle } = useTitle()
    let { center, defaultZoom, mapOptions } = useMap()

    mapOptions.value.streetViewControl = true

    return {
      pageTitle,
      center,
      defaultZoom,
      mapOptions,
    }
  },
  data() {
    return {
      vehicles: [] as vehicle[],
      selectedVehicle: null as vehicle | null,
      mapReady: false as boolean,
      showMarkerSetting: {
        vehicleName: false,
      },
      search: '' as string,
      spots: [] as spot[],
      showSpotCircle: false as boolean,
      showSatellite: false,
      panoramaChangeVisibleEvent: undefined as
        | google.maps.MapsEventListener
        | undefined,
      showMapUI: true as boolean,
      streetView: undefined as google.maps.StreetViewPanorama | undefined,
      paddingLeft: true as boolean,
      allowMapPadding: true as boolean,
      handleChangeVehicleStatus: 0 as number,
      arrayToChangeStatus: [] as vehicle[],
      operationOffices: [] as operationOffice[],
      searchedVehicles: [] as vehicle[],
      selectedOffices: [] as operationOffice[],
      engineStatusTimer: {} as any,
    }
  },

  provide() {
    return {
      choiceVehicle: this.choiceVehicle,
      closeVehicle: this.closeVehicle,
    }
  },

  beforeUnmount() {
    if (this.panoramaChangeVisibleEvent) {
      google.maps.event.removeListener(this.panoramaChangeVisibleEvent)
    }
    window.clearInterval(this.handleChangeVehicleStatus)
  },

  mounted() {
    this.handleChangeVehicleStatus = window.setInterval(() => {
      this.arrayToChangeStatus.map((vehicle) => {
        if (
          VehicleWorkStatus.isNeedToChangeBreakToRest(
            vehicle.latest_status_time ?? 0,
            new Date().getTime()
          )
        ) {
          vehicle.status = VehicleWorkStatus.getRestStatusByBreakStatus(
            vehicle.status
          )
          this.removeFromArrayToChangeStatus(vehicle)
        }
      })
    }, 1000)

    const link: any = this.$refs.vehiclesMap
    link.$mapPromise.then((map: google.maps.Map) => {
      const streetView = map.getStreetView()
      streetView.setOptions({
        fullscreenControl: false,
        enableCloseButton: false,
      })

      this.panoramaChangeVisibleEvent = google.maps.event.addListener(
        streetView,
        'visible_changed',
        () => {
          if (streetView) {
            if (streetView.getVisible()) {
              ;(async () => {
                this.hideUI()
                await nextTick()
                google.maps.event.trigger(streetView, 'resize')
              })()
            } else {
              this.showUI()
            }
          }
        }
      )
    })

    Api()
      .getSpots()
      .then((data: spot[]) => {
        this.spots = data
      })

    this.pageTitle = setTitle()

    Api()
      .getVehicles((data: getVehicleResponse) => {
        const vehicles = data.vehicles as vehicle[]
        this.vehicles = this.vehicles.concat(vehicles)

        vehicles.map((vehicle: vehicle) => {
          if (vehicle.engine_on) {
            const threashold =
              new Date().getTime() - ENGINE_OFF_THRESHOLD_MINUTES * 60 * 1000
            const interval = vehicle.latest_traffic_time
              ? vehicle.latest_traffic_time - threashold
              : 0
            this.engineStatusTimer[vehicle.vehicle_id] = setTimeout(
              () => (vehicle.engine_on = false),
              interval
            )
          }

          this.rememberVehiclePosition(vehicle, vehicle.position)
          this.changeBreakToRestStatus(vehicle)

          subscribeService().subscribeToVehicleSubscriptions(
            vehicle,
            this.vehicleEventAction,
            this.vehicleEventAction
          )
        })

        this.centerMapToMarkers()
        this.mapReady = true
      })
      .catch((error: errorResponse) => {
        console.log(error)
        const errorMessage: string = error.message
          ? error.message
          : this.$t('common.unknownErrorMessage')
        this.$notify({
          title: this.$t('pages.vehicleMap.loadVehicles.error'),
          text: errorMessage,
          type: 'error',
        })
        this.$rollbar?.error(error)
        this.mapReady = true
      })

    Api()
      .getOperationOffices()
      .then((data) => {
        this.operationOffices = data
      })
  },

  computed: {
    showMap(): boolean {
      return this.mapReady && this.vehicles.length > 0
    },
    filteredVehicles(): vehicle[] {
      let vehicles =
        this.selectedOffices.length || this.searchedVehicles.length
          ? this.searchedVehicles
          : this.vehicles
      if (this.search) {
        vehicles = vehicles.filter((vehicle: vehicle) => {
          const search: string = this.search.toLowerCase()
          return (
            vehicle.registration_number.toLowerCase().includes(search) ||
            vehicle.driver_name.toLowerCase().includes(search)
          )
        })
      }

      if (vehicles.length) {
        vehicles.sort(sortVehicle)
      }
      return vehicles
    },
    getLeftPaneMessage(): string {
      if (this.selectedVehicle) {
        return this.selectedVehicle.registration_number
      }

      return this.$t('pages.vehicleMap.leftPanel.open.vehicleCountText', {
        count: this.filteredVehicles.length,
      })
    },
    mapTypeId(): string {
      return this.showSatellite ? 'hybrid' : 'roadmap'
    },
    showStreetViewUI(): boolean {
      return !this.showMapUI
    },
    filteredSpots(): spot[] {
      if (!this.selectedOffices.length) return this.spots
      const filteredItems = [] as spot[]
      this.spots.forEach((spot: spot) => {
        this.selectedOffices.forEach((office: operationOffice) => {
          if (spot.operation_office_id == office.id) filteredItems.push(spot)
        })
      })
      return filteredItems
    },
  },

  methods: {
    vehicleEventAction(
      vehicle: vehicle,
      data: subscribeToVehicleResponse,
      payload: Traffic | Status
    ): void {
      const updatedVehicle: vehicle | undefined = this.vehicles?.find(
        (vehicleInArray: vehicle) =>
          vehicleInArray.device_id === vehicle.device_id
      )
      if (updatedVehicle) {
        updatedVehicle.position = data.position
        updatedVehicle.delivery_plan_id = data.delivery_plan_id
        updatedVehicle.status = data.status
        updatedVehicle.latest_status_time = data.latest_status_time
        updatedVehicle.latest_driving_time = data.latest_driving_time
        updatedVehicle.in_service = data.in_service
        updatedVehicle.engine_on = data.engine_on
        updatedVehicle.is_loaded = data.is_loaded

        this.rememberVehiclePosition(updatedVehicle, data.position)
        this.changeBreakToRestStatus(updatedVehicle)
      }

      if (this.engineStatusTimer[vehicle.vehicle_id]) {
        clearTimeout(this.engineStatusTimer[vehicle.vehicle_id])
      }
      this.engineStatusTimer[vehicle.vehicle_id] = setTimeout(
        () => (vehicle.engine_on = false),
        ENGINE_OFF_THRESHOLD_MINUTES * 60 * 1000
      )
    },
    choiceVehicle(vehicle: vehicle): void {
      this.selectedVehicle = vehicle
      this.center = vehicle.position
      this.scrollToSelectedVehicle(vehicle.device_id)
    },
    scrollToSelectedVehicle(device_id: string): void {
      document.getElementById(device_id)?.scrollIntoView({ behavior: 'smooth' })
    },
    closeVehicle(): void {
      this.selectedVehicle = null
    },
    getVehicleText(vehicle: vehicle): object | null {
      if (!this.showMarkerSetting.vehicleName) {
        return null
      }

      const textProps: Record<string, any> = {
        ...MAP_ICON_TEXT,
        fontSize: '14px',
        color: this.showSatellite ? 'white' : 'black',
        text: vehicle.registration_number,
      }

      delete textProps.fontFamily

      return textProps
    },
    getVehicleIcon(vehicle: vehicle): object {
      const iconPathBuilder = new VehicleIconBuilder()
      if (
        this.selectedVehicle &&
        this.selectedVehicle.vehicle_id === vehicle.vehicle_id
      ) {
        iconPathBuilder.setSelected()
      }
      if (!vehicle.engine_on) {
        iconPathBuilder.setEngineOff()
      } else {
        const markerPosition = getMarkerDirection(vehicle.last_positions)
        if (markerPosition === VEHICLE_MARKER_DIRECTION_LEFT) {
          iconPathBuilder.setOrientationLeft()
        }
        if (vehicle.is_loaded) {
          iconPathBuilder.setFull()
        }
      }

      return {
        url: iconPathBuilder.build(),
        labelOrigin: {
          x: 27,
          y: -10,
        },
      }
    },
    centerMapToMarkers(): void {
      const link: any = this.$refs.vehiclesMap
      link.$mapPromise.then((map: google.maps.Map) => {
        const google = window.google
        let bounds = new google.maps.LatLngBounds()
        for (let i = 0; i < this.vehicles.length; i++) {
          bounds.extend(this.vehicles[i].position)
        }

        // map.setCenter(bounds.getCenter());
        this.center = {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        }
        map.fitBounds(bounds)
        if (this.vehicles.length == 1) {
          map.setZoom(this.defaultZoom)
        }
      })
    },
    updateAddress(address: string, vehicle: vehicle): void {
      const updatedVehicle: vehicle | undefined = this.vehicles?.find(
        (vehicleInArray: vehicle) =>
          vehicleInArray.device_id === vehicle.device_id
      )
      if (updatedVehicle) {
        updatedVehicle.last_address_name = address
      }
    },
    getZIndex(vehicle: vehicle): number | null {
      return this.selectedVehicle &&
        vehicle.vehicle_id === this.selectedVehicle.vehicle_id
        ? 999
        : null
    },
    toggleVehicleName(): void {
      this.showMarkerSetting.vehicleName = !this.showMarkerSetting.vehicleName
    },
    toggleShowSpotCircle(): void {
      this.showSpotCircle = !this.showSpotCircle
    },
    toggleSatellite(): void {
      this.showSatellite = !this.showSatellite
    },
    showUI(): void {
      this.showMapUI = true
      this.allowMapPadding = true
    },
    hideUI(): void {
      this.showMapUI = false
      this.allowMapPadding = false
    },
    closeStreetView(): void {
      const link: any = this.$refs.vehiclesMap
      link.$mapPromise.then((map: google.maps.Map) => {
        map.getStreetView().setVisible(false)
      })
    },
    rememberVehiclePosition(vehicle: vehicle, position: position): void {
      if (vehicle.status == workStatus.WORK_STATUS_STOPPING) {
        return
      }

      if (vehicle.last_positions === undefined) {
        vehicle.last_positions = []
      }

      if (
        vehicle.last_positions.findIndex(
          (item) => item.lat === position.lat && item.lng === position.lng
        ) >= 0
      ) {
        return
      }

      vehicle.last_positions.unshift(position)
      if (vehicle.last_positions.length > COUNT_POSITION_TO_SAVE) {
        vehicle.last_positions.pop()
      }
    },
    changeBreakToRestStatus(vehicle: vehicle): void {
      if (VehicleWorkStatus.isBreakStatus(vehicle.status)) {
        this.appendToArrayToChangeStatus(vehicle)
      } else {
        this.removeFromArrayToChangeStatus(vehicle)
      }
    },
    appendToArrayToChangeStatus(vehicle: vehicle): void {
      const index = this.arrayToChangeStatus.indexOf(vehicle)
      if (index != -1) return
      this.arrayToChangeStatus.push(vehicle)
    },
    removeFromArrayToChangeStatus(vehicle: vehicle): void {
      const index = this.arrayToChangeStatus.indexOf(vehicle)
      if (index == -1) return
      this.arrayToChangeStatus.splice(index, 1)
    },
    officeName(vehicle: vehicle) {
      let officeName = ''
      this.operationOffices?.forEach((operationOffice: any) => {
        if (operationOffice.id == vehicle.operation_office_id)
          officeName = operationOffice.name
      })
      return officeName
    },
    selectedOperationOffices(selectedItems: operationOffice[]) {
      this.selectedOffices = this.sortSelectedOffices(selectedItems)
      this.searchedVehicles = this.filterByOperationOfficeIds(
        selectedItems,
        this.vehicles
      )
    },
    filterByOperationOfficeIds(offices: operationOffice[], items: any[]) {
      let filteredItems = [] as any[]
      items.forEach((item: any) => {
        offices.forEach((office: operationOffice) => {
          if (item.operation_office_id == office.id) filteredItems.push(item)
        })
      })
      return filteredItems
    },
    sortSelectedOffices(selectedItems: operationOffice[]) {
      const officeIdsArray = [] as any[]
      const selected = [] as operationOffice[]
      selectedItems.forEach((office: operationOffice) => {
        officeIdsArray.push(office.id)
      })
      this.operationOffices.forEach((office: operationOffice) => {
        officeIdsArray.forEach((id: string) => {
          if (office.id == id) selected.push(office)
        })
      })
      return selected
    },
  },

  components: {
    VehicleListElement,
    LeftPanel,
    SearchVehicle,
    vLoaderAnimation,
    ToggleTraffic,
    ToggleMarker,
    SpotCircle,
    VehicleStatistics,
    SearchOperationOffices,
  },
})
