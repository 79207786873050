import trafficElement from '@/types/trafficElement'
import polyline from '@/types/polyline'
import PolylinePattern from '@/services/map/polyline/PolylinePattern'
import position from '@/types/position'
import GapChecker from '@/services/map/gapChecker/GapChecker'

class PathToPolylineConvertor {
  protected polylinePatterns: PolylinePattern[] = []
  public gapChecker: GapChecker | undefined
  protected result: polyline[] = []

  public addPolylinePattern = (pattern: PolylinePattern): void => {
    this.polylinePatterns.push(pattern)
  }

  public addGapChecker(checker: GapChecker) {
    this.gapChecker = checker
  }

  public convert = (path: trafficElement[]): polyline[] => {
    this.result = []

    let curPattern: PolylinePattern | undefined
    let prevPatternMemo: string | undefined
    let curPolyline: polyline | undefined
    let foundPattern: PolylinePattern | undefined

    path.forEach((trafficElement: trafficElement, index: number) => {
      if (
        (foundPattern = this.polylinePatterns.find((pattern) =>
          pattern.isCover(trafficElement.speed)
        ))
      ) {
        curPattern = foundPattern
      } else {
        if (curPolyline) {
          this.addPolylineToResult(curPolyline)
          curPolyline = undefined
        }
        return true
      }

      const issetGap: boolean = this.isThereGap(index, path)

      const curPatternMemo: string = JSON.stringify(curPattern)

      if (!prevPatternMemo) {
        prevPatternMemo = curPatternMemo
      }

      const patternChanged = curPatternMemo !== prevPatternMemo
      if (patternChanged) {
        prevPatternMemo = curPatternMemo
      }

      const pathElement: position = {
        lat: trafficElement.lat,
        lng: trafficElement.lng,
      }

      if (curPolyline) {
        if (patternChanged || issetGap) {
          this.addPolylineToResult(curPolyline)
          let newPath
          if (issetGap) {
            newPath = [pathElement]
          } else {
            const startPoint: position =
              curPolyline.path[curPolyline.path.length - 1]
            newPath = [startPoint, pathElement]
          }
          curPolyline = {
            color: curPattern.color,
            path: newPath,
          }
        } else {
          curPolyline.path.push(pathElement)
        }
      } else {
        curPolyline = {
          color: curPattern.color,
          path: [pathElement],
        }
      }

      if (index == path.length - 1 && curPolyline) {
        this.addPolylineToResult(curPolyline)
      }
    })

    return this.result
  }

  protected addPolylineToResult = (polyline: polyline): void => {
    if (polyline.path.length > 1) {
      this.result.push(polyline)
    }
  }

  protected isThereGap = (index: number, path: trafficElement[]): boolean => {
    if (this.gapChecker) {
      return this.gapChecker.isThereGap(index, path)
    }
    return false
  }
}

export default PathToPolylineConvertor
