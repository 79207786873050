import PathToPolylineConvertor from '@/services/map/PathToPolylineConvertor'
import SpeedDependsPolylinePattern from '@/services/map/polyline/SpeedDependsPolylinePattern'
import SimplePolylinePattern from '@/services/map/polyline/SimplePolylinePattern'
import TimeGapChecker from '@/services/map/gapChecker/TimeGapChecker'

export default () => {
  const service = new PathToPolylineConvertor()
  service.addPolylinePattern(
    new SpeedDependsPolylinePattern('#FF9800', 60, 89.9)
  )
  service.addPolylinePattern(
    new SpeedDependsPolylinePattern('#2196f3', 0.1, 59.9)
  )
  service.addPolylinePattern(new SpeedDependsPolylinePattern('#000', 0, 0))
  service.addPolylinePattern(new SimplePolylinePattern('#FF0000'))
  service.addGapChecker(new TimeGapChecker(60 * 10))
  return service
}
