/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const listTrafficsFilteredBySnWithUser = /* GraphQL */ `query ListTrafficsFilteredBySnWithUser(
  $user_id: ID
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listTrafficsFilteredBySnWithUser(
    user_id: $user_id
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      sp
      sf
      eg
      ef
      ig
      th
      air
      lat
      lon
      acx
      acy
      acz
      agx
      agy
      agz
      gex
      gey
      gez
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrafficsFilteredBySnWithUserQueryVariables,
  APITypes.ListTrafficsFilteredBySnWithUserQuery
>;
export const listTrafficsFilteredByDgnWithUser = /* GraphQL */ `query ListTrafficsFilteredByDgnWithUser(
  $user_id: ID
  $dgn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listTrafficsFilteredByDgnWithUser(
    user_id: $user_id
    dgn: $dgn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      sp
      sf
      eg
      ef
      ig
      th
      air
      lat
      lon
      acx
      acy
      acz
      agx
      agy
      agz
      gex
      gey
      gez
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrafficsFilteredByDgnWithUserQueryVariables,
  APITypes.ListTrafficsFilteredByDgnWithUserQuery
>;
export const listStatusesFilteredByDeliveryGroupNoWithUser = /* GraphQL */ `query ListStatusesFilteredByDeliveryGroupNoWithUser(
  $user_id: ID
  $delivery_group_no: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listStatusesFilteredByDeliveryGroupNoWithUser(
    user_id: $user_id
    delivery_group_no: $delivery_group_no
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      serial_no
      delivery_group_no
      time
      status_flag
      status
      latitude
      longitude
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatusesFilteredByDeliveryGroupNoWithUserQueryVariables,
  APITypes.ListStatusesFilteredByDeliveryGroupNoWithUserQuery
>;
export const listStatusesFilteredBySerialNoWithUser = /* GraphQL */ `query ListStatusesFilteredBySerialNoWithUser(
  $user_id: ID
  $serial_no: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listStatusesFilteredBySerialNoWithUser(
    user_id: $user_id
    serial_no: $serial_no
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      serial_no
      delivery_group_no
      time
      status_flag
      status
      latitude
      longitude
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatusesFilteredBySerialNoWithUserQueryVariables,
  APITypes.ListStatusesFilteredBySerialNoWithUserQuery
>;
export const listAlertsFilteredByDgnWithUser = /* GraphQL */ `query ListAlertsFilteredByDgnWithUser(
  $user_id: ID
  $dgn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listAlertsFilteredByDgnWithUser(
    user_id: $user_id
    dgn: $dgn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      cat
      alert
      th
      dv
      tm
      lat
      lon
      trp
      feature
      mpg
      bitrate
      fps
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertsFilteredByDgnWithUserQueryVariables,
  APITypes.ListAlertsFilteredByDgnWithUserQuery
>;
export const listAlertsFilteredBySnWithUser = /* GraphQL */ `query ListAlertsFilteredBySnWithUser(
  $user_id: ID
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listAlertsFilteredBySnWithUser(
    user_id: $user_id
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      cat
      alert
      th
      dv
      tm
      lat
      lon
      trp
      feature
      mpg
      bitrate
      fps
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertsFilteredBySnWithUserQueryVariables,
  APITypes.ListAlertsFilteredBySnWithUserQuery
>;
export const listSensorsFilteredBySnWithUser = /* GraphQL */ `query ListSensorsFilteredBySnWithUser(
  $user_id: ID
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listSensorsFilteredBySnWithUser(
    user_id: $user_id
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      u1s
      u2s
      u3s
      u4s
      u5s
      u6s
      u7s
      u8s
      d11
      d12
      d21
      d22
      d31
      d32
      d41
      d42
      d51
      d52
      d61
      d62
      d71
      d72
      d81
      d82
      lat
      lon
      gst
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorsFilteredBySnWithUserQueryVariables,
  APITypes.ListSensorsFilteredBySnWithUserQuery
>;
export const listSensorsFilteredByDgnWithUser = /* GraphQL */ `query ListSensorsFilteredByDgnWithUser(
  $user_id: ID
  $dgn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listSensorsFilteredByDgnWithUser(
    user_id: $user_id
    dgn: $dgn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      u1s
      u2s
      u3s
      u4s
      u5s
      u6s
      u7s
      u8s
      d11
      d12
      d21
      d22
      d31
      d32
      d41
      d42
      d51
      d52
      d61
      d62
      d71
      d72
      d81
      d82
      lat
      lon
      gst
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorsFilteredByDgnWithUserQueryVariables,
  APITypes.ListSensorsFilteredByDgnWithUserQuery
>;
export const listDriverSetFilteredByDriverPin = /* GraphQL */ `query ListDriverSetFilteredByDriverPin(
  $user_id: ID
  $driver_pin: String!
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listDriverSetFilteredByDriverPin(
    user_id: $user_id
    driver_pin: $driver_pin
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      delivery_date
      serial_no
      driver_pin
      dgn
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDriverSetFilteredByDriverPinQueryVariables,
  APITypes.ListDriverSetFilteredByDriverPinQuery
>;
export const listTrafficsFilteredBySnAndDateTimeRangeWithUser = /* GraphQL */ `query ListTrafficsFilteredBySnAndDateTimeRangeWithUser(
  $user_id: ID
  $startDateTime: Int!
  $endDateTime: Int!
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listTrafficsFilteredBySnAndDateTimeRangeWithUser(
    user_id: $user_id
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      sp
      sf
      eg
      ef
      ig
      th
      air
      lat
      lon
      acx
      acy
      acz
      agx
      agy
      agz
      gex
      gey
      gez
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTrafficsFilteredBySnAndDateTimeRangeWithUserQueryVariables,
  APITypes.ListTrafficsFilteredBySnAndDateTimeRangeWithUserQuery
>;
export const listStatusFilteredBySerialNoAndDateTimeRangeWithUser = /* GraphQL */ `query ListStatusFilteredBySerialNoAndDateTimeRangeWithUser(
  $user_id: ID
  $startDateTime: Int!
  $endDateTime: Int!
  $serial_no: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listStatusFilteredBySerialNoAndDateTimeRangeWithUser(
    user_id: $user_id
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    serial_no: $serial_no
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      serial_no
      delivery_group_no
      time
      status_flag
      status
      latitude
      longitude
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStatusFilteredBySerialNoAndDateTimeRangeWithUserQueryVariables,
  APITypes.ListStatusFilteredBySerialNoAndDateTimeRangeWithUserQuery
>;
export const listAlertsFilteredBySnAndDateTimeRangeWithUser = /* GraphQL */ `query ListAlertsFilteredBySnAndDateTimeRangeWithUser(
  $user_id: ID
  $startDateTime: Int!
  $endDateTime: Int!
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listAlertsFilteredBySnAndDateTimeRangeWithUser(
    user_id: $user_id
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      cat
      alert
      th
      dv
      tm
      lat
      lon
      trp
      feature
      mpg
      bitrate
      fps
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertsFilteredBySnAndDateTimeRangeWithUserQueryVariables,
  APITypes.ListAlertsFilteredBySnAndDateTimeRangeWithUserQuery
>;
export const listSensorsFilteredBySnAndDateTimeRangeWithUser = /* GraphQL */ `query ListSensorsFilteredBySnAndDateTimeRangeWithUser(
  $user_id: ID
  $startDateTime: Int!
  $endDateTime: Int!
  $sn: String
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listSensorsFilteredBySnAndDateTimeRangeWithUser(
    user_id: $user_id
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    sn: $sn
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      sn
      dgn
      tm
      u1s
      u2s
      u3s
      u4s
      u5s
      u6s
      u7s
      u8s
      d11
      d12
      d21
      d22
      d31
      d32
      d41
      d42
      d51
      d52
      d61
      d62
      d71
      d72
      d81
      d82
      lat
      lon
      gst
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSensorsFilteredBySnAndDateTimeRangeWithUserQueryVariables,
  APITypes.ListSensorsFilteredBySnAndDateTimeRangeWithUserQuery
>;
export const listVehicleSubscriptionByDeliveryPlanIdAndTopic = /* GraphQL */ `query ListVehicleSubscriptionByDeliveryPlanIdAndTopic(
  $user_id: ID
  $delivery_plan_id: String!
  $topic: String!
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listVehicleSubscriptionByDeliveryPlanIdAndTopic(
    user_id: $user_id
    delivery_plan_id: $delivery_plan_id
    topic: $topic
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      serial_no
      tm
      topic
      payload
      id
      delivery_plan_id
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVehicleSubscriptionByDeliveryPlanIdAndTopicQueryVariables,
  APITypes.ListVehicleSubscriptionByDeliveryPlanIdAndTopicQuery
>;
export const listVehicleSubscriptionBySnAndTopic = /* GraphQL */ `query ListVehicleSubscriptionBySnAndTopic(
  $user_id: ID
  $topic: String!
  $serial_no: String!
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listVehicleSubscriptionBySnAndTopic(
    user_id: $user_id
    topic: $topic
    serial_no: $serial_no
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      serial_no
      tm
      topic
      payload
      id
      delivery_plan_id
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVehicleSubscriptionBySnAndTopicQueryVariables,
  APITypes.ListVehicleSubscriptionBySnAndTopicQuery
>;
export const listVehicleSubscriptionBySnAndTopicAndDateTimeRange = /* GraphQL */ `query ListVehicleSubscriptionBySnAndTopicAndDateTimeRange(
  $user_id: ID
  $topic: String!
  $startDateTime: Int!
  $endDateTime: Int!
  $serial_no: String!
  $limit: Int
  $nextToken: String
  $sortDirection: String
) {
  listVehicleSubscriptionBySnAndTopicAndDateTimeRange(
    user_id: $user_id
    topic: $topic
    startDateTime: $startDateTime
    endDateTime: $endDateTime
    serial_no: $serial_no
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      serial_no
      tm
      topic
      payload
      id
      delivery_plan_id
      created_at
      ttl_timestamp
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQueryVariables,
  APITypes.ListVehicleSubscriptionBySnAndTopicAndDateTimeRangeQuery
>;
