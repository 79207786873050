import { defineRule, configure } from 'vee-validate'
import { required } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

defineRule('required', required)

configure({
  generateMessage: localize('en', {
    messages: {
      required: 'validate.required',
      email: 'validate.email',
    },
  }),
})
