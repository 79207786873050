import { workStatus } from '@/services/const'

const vehicleResponse = {
  vehicles: [
    {
      device_id: 'D01005000070',
      registration_number: 'FEB50-541941',
      driver_name: '大輝 田口',
      phone_number: '123',
      position: {
        lat: 34.60937,
        lng: 135.517996666667,
      },
      status: workStatus.WORK_STATUS_LEAVING_WAREHOUSE,
      delivery_plan_id: '362975',
      driver_id: 24535,
      vehicle_id: 24242,
      operation_office_id: 24242,
      is_loaded: true,
    },
    {
      device_id: 'D01005000021',
      registration_number: 'FK71HJ-766713',
      driver_name: '昭博 橋本',
      phone_number: '',
      position: {
        lat: 48.105979,
        lng: 11.632978,
      },
      status: workStatus.WORK_STATUS_STOPPING,
      delivery_plan_id: '362976',
      driver_id: 24536,
      vehicle_id: 24243,
      operation_office_id: 24242,
      is_loaded: true,
    },
    {
      device_id: 'D01005000018',
      registration_number: 'FEB80-530800',
      driver_name: '彰吾 前川',
      phone_number: '',
      position: {
        lat: 51.993048,
        lng: 6.85212,
      },
      status: workStatus.WORK_STATUS_LOADING,
      delivery_plan_id: '362977',
      driver_id: 24537,
      vehicle_id: 24244,
      operation_office_id: 24242,
      is_loaded: false,
    },
    {
      device_id: 'D01005000016',
      registration_number: 'FH64F-600953',
      driver_name: '邦雄 北川',
      phone_number: '',
      position: {
        lat: 51.093048,
        lng: 6.84212,
      },
      status: workStatus.WORK_STATUS_OTHER,
      delivery_plan_id: '362978',
      driver_id: 24538,
      vehicle_id: 24245,
      operation_office_id: 24242,
      is_loaded: false,
    },
  ],
}

export default vehicleResponse
