import { computed, ref } from 'vue'
import { useHead } from '@vueuse/head'

export default function useTitle() {
  const pageTitle = ref<string>(``)

  useHead({
    title: computed(() => pageTitle.value),
  })

  return {
    pageTitle,
  }
}
