
import { Options, Vue } from 'vue-class-component'
import { PropType } from 'vue'

@Options({
  name: 'v-loader-animation',
  props: {
    px: {
      type: String as PropType<string>,
      default: 'px-4',
    },
    py: {
      type: String as PropType<string>,
      default: 'py-2',
    },
    text: {
      type: String as PropType<string>,
      default: 'text-gray-600',
    },
    loadingTextStyle: {
      type: String as PropType<string>,
      default: 'text-black',
    },
    loadingText: {
      type: String as PropType<string>,
      default: 'Loading',
    },
    addCharacterImg: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  computed: {
    classObject() {
      let classObject: any = {}

      if (this.px) {
        classObject[this.px] = true
      }

      if (this.py) {
        classObject[this.py] = true
      }

      if (this.text) {
        classObject[this.text] = true
      }

      return classObject
    },
    loadingClassObject() {
      let classObject: any = {}

      if (this.loadingTextStyle) {
        classObject[this.loadingTextStyle] = true
      }

      return classObject
    },
  },
})
export default class App extends Vue {}
