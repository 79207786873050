import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-gray-700 text-lg font-bold" }
const _hoisted_2 = { class: "flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GMapInfoWindow = _resolveComponent("GMapInfoWindow")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!

  return (_ctx.viewable)
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.spots, (spot, index) => {
        return (_openBlock(), _createBlock(_component_GMapMarker, {
          key: 'spot-pin-' + index,
          position: spot.position,
          clickable: true,
          draggable: false,
          onClick: ($event: any) => (_ctx.openMarker(index)),
          icon: _ctx.getSpotIcon()
        }, {
          default: _withCtx(() => [
            _createVNode(_component_GMapInfoWindow, {
              closeclick: true,
              opened: _ctx.isMarkerSelected(index),
              onCloseclick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeMarkerWindow()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h5", _hoisted_1, _toDisplayString(spot.name), 1),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("span", null, _toDisplayString(spot.address), 1),
                  _createElementVNode("span", null, _toDisplayString(spot.telephone_number), 1)
                ])
              ]),
              _: 2
            }, 1032, ["opened"])
          ]),
          _: 2
        }, 1032, ["position", "onClick", "icon"]))
      }), 128))
    : _createCommentVNode("", true)
}