import AsyncLock from 'async-lock'

export default class GraphQLApiBase {
  static lock = new AsyncLock()
  public static _clearCache(keyPrefix: string): void {
    Object.keys(localStorage).forEach((key) => {
      if (!key.indexOf(keyPrefix)) {
        localStorage.removeItem(key)
      }
    })
  }
  public static clearTimeoutCache(prefixKey: string): void {
    const targetPrefixes: string[] = []
    const current_time = new Date()
    const threashold = current_time.setSeconds(
      current_time.getSeconds() - this.maxTime()
    )

    Object.keys(localStorage).forEach((key) => {
      if (key.indexOf('Time') && !key.indexOf(prefixKey)) {
        const cache_time = localStorage[key]
        if (cache_time && parseInt(cache_time) < threashold) {
          targetPrefixes.push(key.substr(0, key.length - 4))
        }
      }
    })
    targetPrefixes.forEach((key) => this._clearCache(key))
  }

  public static async cache<T = string>(
    prefix: string = '',
    key: string = '',
    method: Function,
    param: T
  ): Promise<any> {
    const targetKey = prefix + key
    const time_key: string = targetKey + 'Time'
    const response_key: string = targetKey + 'Response'

    const res = await this.lock.acquire(targetKey, async () => {
      this.clearTimeoutCache(prefix)
      const current_time = new Date()
      const threashold = current_time.setSeconds(
        current_time.getSeconds() - this.maxTime()
      )

      const cache_time = localStorage[time_key]
      if (cache_time && parseInt(cache_time) >= threashold) {
        return new Promise((resolve) => {
          resolve(JSON.parse(localStorage[response_key]))
        })
      }

      const res = await method(param)

      if (res) {
        try {
          localStorage[response_key] = JSON.stringify(res)
          localStorage[time_key] = new Date().getTime()
          this.delayedClearCache(targetKey)
        } catch (DOMException) {
          console.log(
            'skip cache because response body is too long. key=' + prefix + key
          )
        }
      }
      return res
    })

    return new Promise((resolve) => {
      resolve(res)
    })
  }

  public static delayedClearCache(key: string): void {
    setTimeout(() => {
      this._clearCache(key)
    }, this.maxTime() * 1000)
  }

  public static maxTime(): number {
    return 10
  }
}
