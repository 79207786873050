import PointFinderInterface from '@/services/map/pointFinder/PointFinderInterface'
import position from '@/types/position'

class HaversinePointFinder implements PointFinderInterface {
  findNearestPoint(basePoint: position, arPoints: position[]): position {
    let nearestPoint: position | undefined = undefined
    let nearestDistance: number | undefined = undefined

    arPoints.map((point: position) => {
      const distance = this.haversineDistance(basePoint, point)
      if (nearestPoint === undefined || nearestDistance == undefined) {
        nearestPoint = point
        nearestDistance = distance
      }
      if (distance < nearestDistance) {
        nearestPoint = point
        nearestDistance = distance
        if (distance == 0) {
          return
        }
      }
    })

    if (nearestPoint === undefined) {
      throw 'arPoints is empty'
    }

    return nearestPoint
  }

  haversineDistance(
    point1: position,
    point2: position,
    isMiles = false
  ): number {
    let lat1 = point1.lat
    const lon1 = point1.lng

    let lat2 = point2.lat
    const lon2 = point2.lng

    const RADIUS_OF_EARTH_IN_KM = 6371

    const dLat = this.distance(lat2, lat1)
    const dLon = this.distance(lon2, lon1)

    lat1 = this.toRadian(lat1)
    lat2 = this.toRadian(lat2)

    // Haversine Formula
    const a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2)
    const c = 2 * Math.asin(Math.sqrt(a))

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c

    if (isMiles) {
      finalDistance /= 1.60934
    }

    return finalDistance
  }

  toRadian(angle: number): number {
    return (Math.PI / 180) * angle
  }

  distance(a: number, b: number): number {
    return (Math.PI / 180) * (a - b)
  }
}

export default HaversinePointFinder
