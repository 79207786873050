export enum timeLineProducer {
  CONTAINER,
  VIDEO,
  VIDEO_SEEKED,
  CHART,
}

export type timeLinePosition = {
  positionInPercentage: number
  producer: timeLineProducer
  producerID: number
}
