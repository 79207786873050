
import { Options, Vue } from 'vue-class-component'
import { PropType } from 'vue'
import CssClasses from '@/types/CssClasses'

@Options({
  name: 'v-button',
  props: {
    type: {
      type: String as PropType<string>,
      default: 'secondary',
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    classObject(): CssClasses {
      let classObject: CssClasses = {}

      switch (this.type) {
        case 'danger':
          classObject['bg-red-400'] = true
          classObject['hover:bg-red-500'] = true
          classObject['active:bg-red-400'] = true
          classObject['text-white'] = true
          break
        case 'secondary':
          classObject = {
            ...classObject,
            'bg-gray-100': true,
            'hover:bg-gray-300': true,
            'active:bg-gray-100': true,
            'text-gray-500': true,
            'border-gray-300': true,
          }
          break
        case 'primary':
          classObject = {
            ...classObject,
            'bg-indigo-600': true,
            'hover:bg-indigo-700': true,
            'active:bg-indigo-600': true,
            'text-white': true,
          }
          break
        case 'gray':
          classObject['bg-gray-400'] = true
          classObject['hover:bg-gray-500'] = true
          classObject['active:bg-gray-400'] = true
          classObject['text-white'] = true
          break
        default:
          classObject['false'] = true
      }

      return classObject
    },
  },

  // danger
  // <button type="button" class="btn btn-primary">Primary</button>
  // <button type="button" class="btn btn-secondary">Secondary</button>
  // <button type="button" class="btn btn-success">Success</button>
  // <button type="button" class="btn btn-danger">Danger</button>
  // <button type="button" class="btn btn-warning">Warning</button>
  // <button type="button" class="btn btn-info">Info</button>
  // <button type="button" class="btn btn-light">Light</button>
  // <button type="button" class="btn btn-dark">Dark</button>

  // <button type="button" class="btn btn-link">Link</button>
})
export default class App extends Vue {}
