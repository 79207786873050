import {
  alertStatus,
  workStatus,
  sensorAlertStatus,
  IGNORE_STATUS,
  ENGINE_OFF_THRESHOLD_MINUTES,
  ALERT_STATUS_MAP,
  REST_THRESHOLD_SECCONDS,
} from '@/services/const'
import getVehiclesResponse from '@/types/responses/getVehicleResponse'
import getRouteResponse from '@/types/responses/getRouteResponse'
import getRouteDetailRequest from '@/types/requests/getRouteDetailRequest'
import ApiProvider from '@/services/api/providers/ApiProvider'
import loginRequest from '@/types/requests/loginRequest'
import loginResponse from '@/types/responses/loginResponse'
import queryService from '@/services/query/QueryServiceFabrick'
import { formatTimeFromSeconds } from '@/services/functions'
import vehicle from '@/types/vehicle'
import spot from '@/types/spot'
import spotInfo from '@/types/spotInfo'
import vehicleInfo from '@/types/vehicleInfo'
import deliveryPlanInfo from '@/types/deliveryPlanInfo'
import driverInfo from '@/types/driverInfo'
import findRouteRequest from '@/types/requests/findRouteRequest'
import deliveryPlansRequest from '@/types/requests/deliveryPlansRequest'
import deliveryPlansResponse from '@/types/responses/deliveryPlansResponse'
import findRouteResponse from '@/types/responses/findRouteResponse'
import getDriversAndVehiclesResponse from '@/types/responses/getDriversAndVehiclesResponse'
import trafficElement from '@/types/trafficElement'
import routeEvent from '@/types/routeEvent'
import alert from '@/types/alert'
import sensor from '@/types/sensor'
import trackingData from '@/types/trackingData'

import sensorAlertRange from '@/types/sensorAlertRange'
import Status from '@/services/models/Status'
import Traffic from '@/services/models/Traffic'
import Vehicle from '@/services/models/Vehicle'
import DeliveryPlan from '@/services/models/DeliveryPlan'
import Driver from '@/services/models/Driver'
import Spot from '@/services/models/Spot'
import DeviceAlert from '@/services/models/DeviceAlert'
import Sensor from '@/services/models/Sensor'
import VehicleWorkStatus from '@/services/vehicle/VehicleWorkStatus'
import deviceAlertsResponse from '@/types/responses/deviceAlertsResponse'
import deviceAlertInfo from '@/types/deviceAlertInfo'
import AlertVideo from '@/types/alertVideo'
import OperationOfficeType from '@/types/operationOffice'
import OperationOffice from '@/services/models/OperationOffice'
import workloadType from '@/types/workload'
import Workload from '@/services/models/Workload'
import workloadResponse from '@/types/responses/workload'
import workloadItemType from '@/types/responses/workloadItem'
import workloadUnitType from '@/types/responses/workloadUnit'
import RequestWorkload from '@/types/requests/workload'
import WorkloadItem from '@/services/models/WorkloadItem'
import WorkloadUnit from '@/services/models/WorkloadUnit'
import { speedHistory } from '@/types/speedHistory'
import { gyroscopeHistory } from '@/types/gyroscopeHistory'
import { AlertDate } from '@/services/dateConvertor/AlertDate'
import { DateConvertor } from '@/services/dateConvertor/DateConvertor'
import { addDays, fromUnixTime, getUnixTime } from 'date-fns'
import { formatDate, getMinDateForSearch } from '@/services/functions'

import i18n from '@/lang/i18n'
import myOperationOffice from '@/types/myOperationOffice'
import MyOperationOffice from '@/services/models/MyOperationOffice'

class RestApiProvider implements ApiProvider {
  private dateConvertor: DateConvertor

  constructor(dateConvertor: DateConvertor) {
    this.dateConvertor = dateConvertor
  }

  private findDeliveryPlanByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<deliveryPlanInfo | null> => {
    const [deliveryPlanInfos] = await Promise.all([
      DeliveryPlan.selectByDeliveryPlanIds([delivery_plan_id]),
    ])
    const deliveryPlanData = deliveryPlanInfos?.data?.delivery_plans || []

    return new Promise((resolve) => {
      resolve(deliveryPlanData[0])
    })
  }

  private findDriverByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<driverInfo | null> => {
    const [deliveryPlan, driverInfos] = await Promise.all([
      this.findDeliveryPlanByDeliveryPlanId(delivery_plan_id),
      Driver.all(),
    ])
    const driverData = driverInfos?.data || []

    return new Promise((resolve) => {
      if (deliveryPlan) {
        for (let i = 0; i < driverData.length; i++) {
          const d = driverData[i]
          if (d.driver_pin == deliveryPlan.driver_id) {
            resolve(d)
          }
        }
      }
      resolve(null)
    })
  }

  private findVehicleByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<vehicleInfo | null> => {
    const [deliveryPlan, vehicleInfos] = await Promise.all([
      this.findDeliveryPlanByDeliveryPlanId(delivery_plan_id),
      Vehicle.all(),
    ])
    const vehicleData = vehicleInfos?.data || []

    return new Promise((resolve) => {
      if (deliveryPlan) {
        for (let i = 0; i < vehicleData.length; i++) {
          const v = vehicleData[i]
          if (v.id == deliveryPlan.vehicle_id) {
            resolve(v)
          }
        }
      }
      resolve(null)
    })
  }

  public getSpots = async (): Promise<spot[]> => {
    const res = await Spot.all()
    if (!res?.data) {
      return new Promise((resolve) => {
        resolve([])
      })
    }
    const _spots = await Promise.all(
      res.data.map(async (spot: spotInfo) => {
        if (spot.radius >= 100000) {
          return undefined
        }
        return {
          position: {
            lat: spot.latitude,
            lng: spot.longitude,
          },
          address: spot.address,
          telephone_number: '',
          radius: spot.radius,
          name: spot.name,
          operation_office_id: spot.operation_office_id,
        }
      })
    )
    const spots = _spots.filter((d) => d) as spot[]

    return new Promise((resolve) => {
      resolve(spots)
    })
  }

  public getVehicles = async (
    callback: Function = (vehicles: vehicleInfo) => {}
  ): Promise<getVehiclesResponse> => {
    this.getSpots()
    const driverInfos = await Driver.all()
    const retVehicles: vehicle[] = []
    await Vehicle.all(true, async (vehicles: vehicleInfo[]) => {
      const vehicleData = vehicles || []

      const delivery_plan_ids = vehicleData
        .map((vehicle: vehicleInfo): string => {
          return vehicle?.latest_delivery_plan_id || ''
        })
        .filter((v) => v.length > 0)
      const deliveryPlanInfos = await DeliveryPlan.selectByDeliveryPlanIds(
        delivery_plan_ids
      )
      const deliveryPlanData = deliveryPlanInfos?.data?.delivery_plans || []
      const driverData = driverInfos?.data || []

      const vehicleInformations = await Promise.all(
        vehicleData.map(async (vehicle: vehicleInfo) => {
          let latestDeliveryPlan: deliveryPlanInfo | null = null
          for (let i = 0; i < deliveryPlanData.length; i++) {
            const d = deliveryPlanData[i]
            if (vehicle.latest_delivery_plan_id == d.delivery_plan_id) {
              latestDeliveryPlan = d
              break
            }
          }
          if (!latestDeliveryPlan) {
            return
          }

          let driver: driverInfo | null = null
          for (let i = 0; i < driverData.length; i++) {
            const d = driverData[i]
            if (
              d.driver_pin == vehicle.driver_id &&
              d.operation_office_id == vehicle.operation_office_id
            ) {
              driver = d
              break
            }
          }

          const registration_number = vehicle.registration_number
            ? vehicle.registration_number
            : ''
          const device_name = latestDeliveryPlan?.device_name || ''
          const [status, inService, latestTraffic, latestStatus, isCarLoaded] =
            await Promise.all([
              queryService().vehicleStatus(device_name),
              queryService().isInService(device_name),
              queryService().latestTraffic(device_name),
              queryService().latestStatus(device_name),
              queryService().isLoaded(device_name),
            ])
          if (!latestTraffic) {
            return
          }

          let latestWorkStatus = workStatus.WORK_STATUS_NOTHING
          if (status) {
            latestWorkStatus =
              Status.convertWorkStatusToVehicleMapStatus(status)
          }

          const beforeTenMinutes = ENGINE_OFF_THRESHOLD_MINUTES * 60 * 1000
          const latestTrafficTime = latestTraffic.created_at
            ? new Date(latestTraffic.created_at).getTime()
            : 0
          const engine_on =
            latestTrafficTime > new Date().getTime() - beforeTenMinutes
          const latestPosition = Traffic.convertTrafficsToPositions([
            latestTraffic,
          ])[0]

          return {
            device_id: latestDeliveryPlan?.device_name || '',
            registration_number: registration_number,
            driver_name: driver?.name || '',
            phone_number: driver?.telephone_number || '',
            position: latestPosition,
            status: latestWorkStatus,
            delivery_plan_id: latestDeliveryPlan?.delivery_plan_id || '',
            leaving_warehouse_date_time:
              latestDeliveryPlan?.leaving_warehouse_date_time,
            driver_id: driver?.id || 0,
            vehicle_id: vehicle.id,
            operation_office_id: vehicle.operation_office_id,
            in_service: inService,
            engine_on: engine_on,
            latest_status_time: new Date(
              latestStatus?.created_at ?? 0
            ).getTime(),
            latest_traffic_time: latestTraffic.tm * 1000,
            is_loaded: isCarLoaded,
          }
        })
      )

      const filteredVehicles = <vehicle[]>vehicleInformations.filter((v) => v)
      await callback({ vehicles: filteredVehicles })
      // retVehicles = retVehicles.concat(filteredVehicles)
    })
    return new Promise((resolve) => {
      resolve({
        vehicles: retVehicles,
      })
    })
  }

  private getTotalMileage = async (
    delivery_plan_id: string
  ): Promise<number> => {
    const deliveryPlan = await this.findDeliveryPlanByDeliveryPlanId(
      delivery_plan_id
    )
    let totalMileage = deliveryPlan?.total_mileage || 0

    if (!totalMileage) {
      const [traffics, deliveryPlans] = await Promise.all([
        queryService().getTrafficElementsFilteredByDeliveryPlanId(
          delivery_plan_id
        ),
        DeliveryPlan.selectByDeliveryPlanIds([delivery_plan_id]),
      ])
      const data = deliveryPlans?.data?.delivery_plans || []
      const deliveryPlan = data[0]

      const leavingTime = new Date(deliveryPlan?.leaving_warehouse_date_time)
      const returningTime = new Date(
        deliveryPlan?.returning_warehouse_date_time
      )

      totalMileage = Traffic.calculateTotalMileage(
        leavingTime,
        returningTime,
        traffics
      )
    }
    return new Promise((resolve) => {
      resolve(totalMileage)
    })
  }

  public getCurrentRouteData = async (
    request: getRouteDetailRequest
  ): Promise<trackingData> => {
    const { delivery_plan_id } = request
    const [traffics, statuses, alerts] = await Promise.all([
      queryService().getTrafficElementsFilteredByDeliveryPlanId(
        delivery_plan_id
      ),
      queryService().getRouteEventsFilteredByDeliveryPlanId(delivery_plan_id),
      queryService().getAlertsFilteredByDeliveryPlanId(delivery_plan_id),
    ])
    return new Promise((resolve) => resolve({ traffics, statuses, alerts }))
  }

  public getRoute = async (
    request: getRouteDetailRequest
  ): Promise<getRouteResponse> => {
    const { delivery_plan_id } = request
    const [
      traffics,
      statuses,
      alerts,
      sensorAlerts,
      deliveryPlan,
      driver,
      vehicle,
      totalMileage,
      alertsDetail,
    ] = await Promise.all([
      queryService().getTrafficElementsFilteredByDeliveryPlanId(
        delivery_plan_id
      ),
      queryService().getRouteEventsFilteredByDeliveryPlanId(delivery_plan_id),
      queryService().getAlertsFilteredByDeliveryPlanId(delivery_plan_id),
      this.getSensorAlertsByDeliveryPlanIdWithUser(delivery_plan_id),
      this.findDeliveryPlanByDeliveryPlanId(delivery_plan_id),
      this.findDriverByDeliveryPlanId(delivery_plan_id),
      this.findVehicleByDeliveryPlanId(delivery_plan_id),
      this.getTotalMileage(delivery_plan_id),
      await DeviceAlert.find(delivery_plan_id),
    ])

    const leavingTime = this.getLeavingTime(statuses, alerts, traffics)
    const returningTime = this.getReturningTime(statuses, alerts, traffics)

    const filteredTraffics = traffics.filter((v) => {
      return (
        leavingTime &&
        v.date_time >= leavingTime &&
        (!returningTime || v.date_time <= returningTime)
      )
    })
    const filteredStatuses = statuses.filter((v) => {
      if (IGNORE_STATUS.indexOf(v.type) >= 0) {
        return false
      }
      return (
        leavingTime &&
        v.date_time >= leavingTime &&
        (!returningTime || v.date_time <= returningTime)
      )
    })
    let filteredAlerts = alerts.filter((v) => {
      return (
        leavingTime &&
        v.date_time >= leavingTime &&
        (!returningTime || v.date_time <= returningTime)
      )
    })
    filteredAlerts = await this.makeAlertResponse(alertsDetail, filteredAlerts)
    const events = Status.makeEvents(filteredTraffics, filteredStatuses)
    const runningTime = Traffic.calculate_driving_time(
      traffics,
      leavingTime,
      returningTime
    )
    const displayRunningTime = formatTimeFromSeconds(runningTime)

    return new Promise((resolve) => {
      const data = {
        path: filteredTraffics,
        events: events,
        alerts: filteredAlerts,
        sensorAlerts: [],
        leaving_warehouse_date_time: leavingTime,
        returning_warehouse_date_time: returningTime,
        device_id: deliveryPlan?.device_name || '',
        registration_number: vehicle?.registration_number || '',
        vehicle_id: vehicle?.id,
        driver_name: driver?.name || '',
        delivery_plan_id: delivery_plan_id,
        total_mileage: totalMileage,
        total_running_time: displayRunningTime,
      }
      resolve(data)
    })
  }

  // private makeEvents(
  //   traffics: trafficElement[],
  //   statuses: routeEvent[]
  // ): routeEvent[] {
  //   let trafficIndex = 0
  //   let duration = { running: 0, stopping: 0, stoppingOverTenMinutes: 0 }
  //   let beforeStatus: routeEvent | null = null

  //   const TEN_MINUTES = 10 * 60

  //   statuses.forEach((status: routeEvent) => {
  //     if (IGNORE_STATUS.indexOf(status.type) >= 0) {
  //       return true
  //     }
  //     if (!beforeStatus) {
  //       beforeStatus = status
  //       return true
  //     }

  //     if (trafficIndex >= traffics.length) {
  //       trafficIndex = traffics.length - 1
  //     }
  //     const statusTime = status.date_time.getTime() / 1000
  //     const beforeStatusTime = beforeStatus.date_time.getTime() / 1000
  //     let continuesStopTime = 0

  //     for (
  //       ;
  //       trafficIndex < traffics.length && traffics.length > 0;
  //       trafficIndex++
  //     ) {
  //       const trafficTime = traffics[trafficIndex].date_time.getTime() / 1000
  //       const beforeTrafficIndex = trafficIndex && trafficIndex - 1
  //       const beforeTrafficTime =
  //         traffics[beforeTrafficIndex].date_time.getTime() / 1000
  //       const currentTrafficTime =
  //         traffics[trafficIndex].date_time.getTime() / 1000
  //       const trafficStatus =
  //         traffics[trafficIndex].speed == 0 ||
  //         trafficTime - beforeTrafficTime > TEN_MINUTES
  //           ? 'stopping'
  //           : 'running'

  //       // status直後のtrafficまで進める
  //       if (trafficTime < beforeStatusTime) continue

  //       // statusをtrafficが超えたら値をセット
  //       if (trafficTime > statusTime) {
  //         duration[trafficStatus] += statusTime - beforeTrafficTime
  //         // 10分超停車
  //         if (continuesStopTime >= TEN_MINUTES) {
  //           duration['stoppingOverTenMinutes'] += continuesStopTime
  //         }

  //         // 0以外の場合デクリメント
  //         trafficIndex && trafficIndex--
  //         break
  //       }

  //       let diffTime = 0
  //       if (duration['running'] == 0 && duration['stopping'] == 0) {
  //         diffTime = trafficTime - beforeStatusTime
  //       } else {
  //         diffTime = trafficTime - beforeTrafficTime
  //       }
  //       duration[trafficStatus] += diffTime

  //       // 10分超停車時間
  //       if (trafficStatus == 'stopping') {
  //         continuesStopTime += diffTime
  //       } else {
  //         if (continuesStopTime >= TEN_MINUTES) {
  //           duration['stoppingOverTenMinutes'] += continuesStopTime
  //         }
  //         continuesStopTime = 0
  //       }
  //     }

  //     if (beforeStatus) {
  //       const sum = statusTime - beforeStatusTime
  //       const stoppingOverTenMinutes =
  //         duration['running'] || sum < TEN_MINUTES
  //           ? duration['stoppingOverTenMinutes']
  //           : sum
  //       beforeStatus.duration = {
  //         summarySeconds: statusTime - beforeStatusTime,
  //         sum: this.convertSecondsToTimeString(statusTime - beforeStatusTime),
  //         running: this.convertSecondsToTimeString(duration['running']),
  //         stopping: this.convertSecondsToTimeString(sum - duration['running']),
  //         stoppingOverTenMinutes: this.convertSecondsToTimeString(
  //           stoppingOverTenMinutes
  //         ),
  //       }
  //     }
  //     duration = { running: 0, stopping: 0, stoppingOverTenMinutes: 0 }
  //     beforeStatus = status
  //   })

  //   statuses.forEach((status: routeEvent, i: number) => {
  //     const st = new VehicleWorkStatus(status.type)
  //     if (!st.isBreak()) return

  //     const beforeStatus = statuses[i - 1]

  //     if (
  //       status.type == workStatus.WORK_STATUS_BREAK &&
  //       (status.duration?.summarySeconds ?? 0) > REST_THRESHOLD_SECCONDS
  //     ) {
  //       status.type = st.getRestStatusByBreakStatus()
  //     }

  //     if (
  //       beforeStatus &&
  //       status.type == workStatus.WORK_STATUS_BREAK_END &&
  //       (beforeStatus.duration?.summarySeconds ?? 0) > REST_THRESHOLD_SECCONDS
  //     ) {
  //       status.type = st.getRestStatusByBreakStatus()
  //     }
  //   })
  //   return statuses
  // }

  private async makeAlertResponse(
    res: deviceAlertsResponse,
    alerts: alert[]
  ): Promise<alert[]> {
    const deviceAlerts = res.data ?? []

    alerts.forEach((alert: alert) => {
      for (let i = 0; i < deviceAlerts?.length; i++) {
        const deviceAlert = deviceAlerts[i]
        const alertStatusString = ALERT_STATUS_MAP[deviceAlert.alert]
        if (
          Math.round(alert.date_time.getTime() / 1000) == deviceAlert?.ts &&
          alert.type == <alertStatus>alertStatusString
        ) {
          alert.g = deviceAlert?.g || undefined
          alert.speed = deviceAlert?.speed || undefined

          alert.videos = this.makeAlertVideoResponse(deviceAlert)
          const timeRecordsResponse = this.makeAlertTimeRecordsResponse(
            deviceAlert,
            alert.date_time
          )
          alert.arSpeedHistory = timeRecordsResponse.arSpeedHistory
          alert.arGyroscopeHistory = timeRecordsResponse.arGyroscopeHistory

          break
        }
      }
    })
    return new Promise((resolve) => {
      resolve(alerts)
    })
  }

  private makeAlertVideoResponse(
    deviceAlert: deviceAlertInfo
  ): Array<AlertVideo> {
    const videos = []
    if (deviceAlert?.video_main_url) {
      videos.push({
        src: deviceAlert.video_main_url,
      })
    }
    if (deviceAlert?.video_sub_url) {
      videos.push({
        src: deviceAlert.video_sub_url,
      })
    }
    if (deviceAlert?.video_extra_url) {
      videos.push({
        src: deviceAlert.video_extra_url,
      })
    }

    return videos
  }

  private makeAlertTimeRecordsResponse(
    deviceAlert: deviceAlertInfo,
    baseDate: Date
  ): {
    arSpeedHistory?: speedHistory[]
    arGyroscopeHistory?: gyroscopeHistory[]
  } {
    const arAlertDates = this.getDatesToConvert(deviceAlert)
    const arConvertedDates = this.dateConvertor.convertDates(
      baseDate,
      arAlertDates
    )

    return {
      arSpeedHistory: this.makeSpeedHistoryResponse(
        deviceAlert,
        arConvertedDates
      ),
      arGyroscopeHistory: this.makeGyroscopeHistoryResponse(
        deviceAlert,
        arConvertedDates
      ),
    }
  }

  private getDatesToConvert(deviceAlert: deviceAlertInfo): Array<AlertDate> {
    const arStringDates: Array<string> = []
    deviceAlert.speed_time_records?.map((speed_time_record) => {
      arStringDates.push(String(speed_time_record.time))
    })
    deviceAlert.coordinates?.map((coordinate) => {
      arStringDates.push(String(coordinate.time))
    })
    return [...new Set(arStringDates)].map(
      (stringDate) => new AlertDate(stringDate)
    )
  }

  private makeSpeedHistoryResponse(
    deviceAlert: deviceAlertInfo,
    arConvertedDates: AlertDate[]
  ): speedHistory[] | undefined {
    if (!deviceAlert.speed_time_records) {
      return undefined
    }

    const response: speedHistory[] = []
    deviceAlert.speed_time_records.map((timeRecord) => {
      const foundAlertDate = arConvertedDates.find((alertDate) => {
        return (
          timeRecord.time === alertDate.getOriginString() && alertDate.getDate()
        )
      })
      if (foundAlertDate !== undefined) {
        response.push({
          speed: timeRecord.speed,
          time: foundAlertDate.getDate() as Date,
        })
      }
    })

    if (response.length < 1) {
      return undefined
    }

    return response
  }

  private makeGyroscopeHistoryResponse(
    deviceAlert: deviceAlertInfo,
    arConvertedDates: AlertDate[]
  ): gyroscopeHistory[] | undefined {
    if (!deviceAlert.coordinates) {
      return undefined
    }

    const response: gyroscopeHistory[] = []
    deviceAlert.coordinates.map((coordinate) => {
      const foundAlertDate = arConvertedDates.find((alertDate) => {
        return (
          String(coordinate.time) === alertDate.getOriginString() &&
          alertDate.getDate()
        )
      })
      if (foundAlertDate !== undefined) {
        response.push({
          X: coordinate.X,
          Y: coordinate.Y,
          Z: coordinate.Z,
          time: foundAlertDate.getDate() as Date,
        })
      }
    })

    if (response.length < 1) {
      return undefined
    }

    return response
  }

  // private convertSecondsToTimeString(seconds: number): string {
  //   const date = new Date(0)
  //   date.setSeconds(seconds)
  //   return date.toISOString().substr(11, 8)
  // }

  public login(request: loginRequest): Promise<loginResponse> {
    console.log(request)
    return new Promise<loginResponse>((resolve, reject) => {
      console.log(reject)
      // reject({message:'test'});

      setTimeout(
        () =>
          resolve({
            user: {
              name: '山田 太郎',
              email: 'test@example.com',
              id: '12354wsdf32',
              operation_office: [],
            },
          }),
        1000
      )
    })
  }

  public async findRoute(
    request: findRouteRequest,
    forceUpdate: boolean = false
  ): Promise<findRouteResponse> {
    const req = Object.assign({}, request) as deliveryPlansRequest
    const itemsPerPage = 20
    const page = request['page'] ? request['page'] - 1 : 0
    req.limit = itemsPerPage
    let leaving_time_condition = ''
    let leaving_warehouse_date_time = req.leaving_warehouse_date_time || ''
    let returning_warehouse_date_time = ''
    let over_threshold_date_error: Error
    const operationOfficeIds = await this.makeOfficeIds(
      request.operation_office_ids
    )

    const minDate = getMinDateForSearch()

    if (req.returning_warehouse_date_time) {
      returning_warehouse_date_time = req.returning_warehouse_date_time
      req.returning_warehouse_date_time = undefined
    } else {
      returning_warehouse_date_time = req.leaving_warehouse_date_time || ''
    }

    if (!leaving_warehouse_date_time && !returning_warehouse_date_time) {
      leaving_warehouse_date_time = formatDate(new Date())
      returning_warehouse_date_time = formatDate(minDate)
    }
    if (
      new Date(leaving_warehouse_date_time) < minDate ||
      new Date(returning_warehouse_date_time) < minDate
    ) {
      over_threshold_date_error = new Error(
        i18n.global.t('pages.searchRoute.findRoute.dateRangeError')
      )
    }

    if (leaving_warehouse_date_time) {
      leaving_time_condition =
        leaving_warehouse_date_time + ',' + returning_warehouse_date_time
      req.leaving_warehouse_date_time = leaving_time_condition.replaceAll(
        '/',
        '-'
      )
    }

    const [deliveryPlans, vehicles, drivers] = await Promise.all([
      DeliveryPlan.selectByParams(req, true),
      Vehicle.all(forceUpdate),
      Driver.all(forceUpdate),
    ])
    const deliveryPlanData = deliveryPlans?.data?.delivery_plans || []
    const vehicleData = await this.filterItemsByOperationOfficeId(
      operationOfficeIds,
      vehicles?.data || []
    )
    const driverData = await this.filterItemsByOperationOfficeId(
      operationOfficeIds,
      drivers?.data || []
    )
    const total_pages = deliveryPlans?.data?.total_pages || 0

    return new Promise<findRouteResponse>((resolve, reject) => {
      if (over_threshold_date_error) reject(over_threshold_date_error)
      let filteredDeliveryPlans = deliveryPlanData
        .filter((v: any) => {
          return v.returning_warehouse_date_time
        })
        .map((deliveryPlan: deliveryPlanInfo) => {
          const vehicle = vehicleData.filter(
            (v: vehicleInfo) => deliveryPlan.vehicle_id == v.id
          )
          const driver = driverData.filter(
            (d: driverInfo) => deliveryPlan.driver_id == d.driver_pin
          )
          return {
            delivery_plan_id: deliveryPlan.delivery_plan_id,
            registration_number: vehicle[0]?.registration_number || '',
            device_id: deliveryPlan.device_name,
            driver_name: driver[0]?.name || '',
            returning_warehouse_date_time: new Date(
              deliveryPlan.returning_warehouse_date_time
            ),
            leaving_warehouse_date_time: new Date(
              deliveryPlan.leaving_warehouse_date_time
            ),
            total_mileage: deliveryPlan.total_mileage,
            vehicle_id: deliveryPlan.vehicle_id,
          }
        })
      filteredDeliveryPlans = filteredDeliveryPlans.sort((a: any, b: any) => {
        return (
          new Date(b.returning_warehouse_date_time).getTime() -
          new Date(a.returning_warehouse_date_time).getTime()
        )
      })

      const ret = filteredDeliveryPlans

      resolve({
        routes: ret,
        page_num: page + 1,
        total_pages: total_pages,
      })
    })
  }

  private makeOfficeIds = async (officeIds: string | undefined) => {
    if (!officeIds) return []

    const officeIdsArray = [] as any[]
    const splitOfficeIds = officeIds.split(',')
    splitOfficeIds.forEach((officeId) => {
      officeIdsArray.push(officeId)
    })

    return officeIdsArray
  }

  private filterItemsByOperationOfficeId = async (
    officeIds: Array<number>,
    items: any
  ) => {
    if (!officeIds.length) return items
    const values = [] as any[]

    officeIds.forEach((officeId: number) => {
      items.forEach((item: any) => {
        if (officeId == item.operation_office_id) values.push(item)
      })
    })
    return values
  }

  public async getDeliveryPlans(
    req: deliveryPlansRequest
  ): Promise<deliveryPlansResponse> {
    return DeliveryPlan.selectByParams(req, true)
  }

  public async getDriversAndVehicles(
    request: object
  ): Promise<getDriversAndVehiclesResponse> {
    const [vehicles, drivers] = await Promise.all([
      Vehicle.all(true),
      Driver.all(),
    ])
    const vehicleData = vehicles?.data || []
    const driverData = drivers?.data || []

    const vehicles_info = vehicleData.map((vehicle: vehicleInfo) => {
      return {
        key: vehicle.registration_number || '',
        val: vehicle.id.toString(),
        registration_number: vehicle.registration_number || '',
        device_id: vehicle.device_id || '',
        serial_no: vehicle.serial_no || '',
        operation_office_id: vehicle.operation_office_id || '',
      }
    })
    const drivers_info = driverData.map((driver: driverInfo) => {
      return {
        key: driver.name || '',
        val: driver.driver_pin.toString(),
        operation_office_id: driver.operation_office_id || '',
        email: driver.email || '',
        telephone_number: driver.telephone_number || '',
        id: driver.id || '',
      }
    })
    return new Promise<getDriversAndVehiclesResponse>((resolve, reject) => {
      resolve({
        vehicles: vehicles_info,
        drivers: drivers_info,
      })
    })
  }

  public getDrivers = async (): Promise<driverInfo[]> => {
    const res = await Driver.all()
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  // private calculateTotalMileage = (
  //   leavingTime: Date | undefined,
  //   returningTime: Date | undefined,
  //   traffics: trafficElement[]
  // ): number => {
  //   if (leavingTime === undefined || returningTime === undefined) {
  //     return 0
  //   }
  //   const filteredTraffics = traffics.filter((v) => {
  //     const tm = v.date_time.getTime()
  //     const start = new Date(leavingTime).getTime()
  //     const end = returningTime ? new Date(returningTime).getTime() : null
  //     return tm >= start && (!end || tm <= end)
  //   })

  //   let totalMileage = 0
  //   let beforeTime = 0
  //   let beforeSpeed = 0
  //   const SKIP_THREASHOLD = 10 * 60 * 1000
  //   filteredTraffics.forEach((t, i) => {
  //     const currentTime = t.date_time.getTime()
  //     // skip first Traffic and engine off
  //     if (i != 0 && beforeTime + SKIP_THREASHOLD >= currentTime) {
  //       totalMileage +=
  //         ((beforeSpeed / 60 / 60) * (currentTime - beforeTime)) / 1000
  //     }
  //     beforeTime = t.date_time.getTime()
  //     beforeSpeed = t.speed
  //   })
  //   const len = 5
  //   return Math.floor(totalMileage * Math.pow(10, len)) / Math.pow(10, len)
  // }

  public getRoutesBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startTime: number,
    endTime: number
  ): Promise<getRouteResponse[]> => {
    const startDateTime = Math.floor(startTime)
    //日付が小数のデータを正常に取得できないので加算
    const endDateTime = Math.ceil(endTime) + 1

    const [traffics, routeEvents, alerts] = await Promise.all([
      queryService().getListTrafficBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
      queryService().getListStatusBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
      queryService().getListAlertBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
    ])
    const sensorAlerts =
      await this.getSensorAlertsBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        Math.floor(startTime),
        Math.ceil(endTime)
      )
    const alertDetails = await this.getAlertDetailsPerDeliveryPlanId(
      alerts,
      Math.floor(startTime),
      Math.ceil(endTime)
    )
    const alertResponses = await this.makeAlertResponse(alertDetails, alerts)
    const events = Status.makeEvents(traffics, routeEvents)
    //取得したデータをdelivery_plan_idごとに分ける
    const routesList: Map<string, Partial<getRouteResponse>> = new Map()

    events.forEach((event) => {
      const dgn = event.dgn
      if (!routesList.has(dgn)) {
        routesList.set(dgn, {
          events: [],
          alerts: [],
          path: [],
          sensorAlerts: [],
        })
      }
      routesList.get(dgn)?.events?.push(event)
    })
    alertResponses.forEach((alertDetail) => {
      const dgn = alertDetail.dgn
      if (!routesList.has(dgn)) {
        routesList.set(dgn, {
          events: [],
          alerts: [],
          path: [],
          sensorAlerts: [],
        })
      }
      routesList.get(dgn)?.alerts?.push(alertDetail)
    })
    traffics.forEach((traffic) => {
      const dgn = traffic.dgn
      if (!routesList.has(dgn)) {
        routesList.set(dgn, {
          events: [],
          alerts: [],
          path: [],
          sensorAlerts: [],
        })
      }
      routesList.get(dgn)?.path?.push(traffic)
    })
    sensorAlerts.forEach((sensorAlert) => {
      const dgn = sensorAlert.dgn
      if (!routesList.has(dgn)) {
        routesList.set(dgn, {
          events: [],
          alerts: [],
          path: [],
          sensorAlerts: [],
        })
      }
      routesList.get(dgn)?.sensorAlerts?.push(sensorAlert)
    })

    for (const r of routesList) {
      const dgn = r[0]
      const route = r[1]
      const [deliveryPlan, driver, vehicle] = await Promise.all([
        this.findDeliveryPlanByDeliveryPlanId(dgn),
        this.findDriverByDeliveryPlanId(dgn),
        this.findVehicleByDeliveryPlanId(dgn),
      ])

      const leavingTime = this.getLeavingTime(
        route?.events ?? [],
        route?.alerts ?? [],
        route?.path ?? []
      )
      const latestStatus = deliveryPlan?.device_name
        ? await queryService().latestStatus(deliveryPlan.device_name)
        : undefined
      const latestRouteEvent = latestStatus
        ? queryService().convertStatusToRouteEvent(latestStatus)
        : undefined
      let returningTime: Date | undefined = undefined
      if (
        latestRouteEvent !== undefined &&
        route.events?.at(-1) === latestRouteEvent &&
        latestRouteEvent.type !== workStatus.WORK_STATUS_RETURNING_WAREHOUSE
      ) {
        returningTime = new Date()
      } else {
        returningTime = this.getReturningTime(
          route?.events ?? [],
          route?.alerts ?? [],
          route?.path ?? []
        )
      }

      const totalMileage = Traffic.calculateTotalMileage(
        leavingTime,
        returningTime,
        route.path ?? []
      )
      const runningTime = Traffic.calculate_driving_time(
        route.path ?? [],
        leavingTime,
        returningTime
      )
      const displayRunningTime = formatTimeFromSeconds(runningTime)
      route.leaving_warehouse_date_time = leavingTime
      route.returning_warehouse_date_time = returningTime
      route.device_id = deliveryPlan?.device_name ?? ''
      route.registration_number = vehicle?.registration_number ?? ''
      route.driver_name = driver?.name ?? ''
      route.delivery_plan_id = dgn
      route.total_mileage = totalMileage
      route.total_running_time = displayRunningTime
      route.vehicle_id = vehicle?.id
    }

    return new Promise((resolve) => {
      const routes: getRouteResponse[] = []
      routesList.forEach((v) => {
        routes.push(v as getRouteResponse)
      })
      routes.sort((a, b) => {
        if (
          a.leaving_warehouse_date_time ??
          new Date() > (b.leaving_warehouse_date_time ?? new Date())
        ) {
          return 1
        } else {
          return -1
        }
      })
      resolve(routes)
    })
  }

  private getAlertDetailsPerDeliveryPlanId = async (
    alertList: alert[],
    startDateTime: number,
    endDateTime: number
  ): Promise<deviceAlertsResponse> => {
    const dgnList: Set<string> = new Set()
    for (const alert of alertList) {
      dgnList.add(alert.dgn)
    }
    let alertDetailList: deviceAlertsResponse = { data: [] }
    for (const dgn of dgnList) {
      const alertDetail = await DeviceAlert.find(
        dgn,
        startDateTime,
        endDateTime
      )
      alertDetailList = { data: alertDetailList.data.concat(alertDetail.data) }
    }
    return alertDetailList
  }

  private getLeavingTime = (
    events: routeEvent[],
    alerts: alert[],
    path: trafficElement[]
  ): Date | undefined => {
    let leavingTime = events.at(0)?.date_time
    events.forEach((event) => {
      if (event.type === workStatus.WORK_STATUS_LEAVING_WAREHOUSE) {
        leavingTime = event.date_time
      }
    })
    if (leavingTime === undefined) {
      leavingTime = alerts.at(0)?.date_time
      const oldestPathDateTime = path.at(0)?.date_time
      if (
        leavingTime === undefined ||
        (oldestPathDateTime !== undefined && leavingTime > oldestPathDateTime)
      ) {
        leavingTime = oldestPathDateTime
      }
    }

    return leavingTime
  }

  private getReturningTime = (
    events: routeEvent[],
    alerts: alert[],
    path: trafficElement[]
  ): Date | undefined => {
    let returningTime = events.at(-1)?.date_time
    events
      .slice()
      .reverse()
      .forEach((event) => {
        if (event.type === workStatus.WORK_STATUS_RETURNING_WAREHOUSE) {
          returningTime = event.date_time
        }
      })
    if (returningTime === undefined) {
      returningTime = alerts.at(-1)?.date_time
      const latestPathDateTime = path.at(-1)?.date_time
      if (
        returningTime === undefined ||
        (latestPathDateTime !== undefined && returningTime < latestPathDateTime)
      ) {
        returningTime = latestPathDateTime
      }
    }

    return returningTime
  }

  public getRouteRange = async (
    delivery_plan_id: string
  ): Promise<{
    serial_number: string
    startTime: number
    endTime: number
  }> => {
    const MAX_RANGE = 7 * 24 * 60 * 60 * 1000 //1週間
    const [firstTraffics, lastTraffics] = await Promise.all([
      queryService().getTrafficElementsFilteredByDeliveryPlanId(
        delivery_plan_id,
        1,
        'ASC'
      ),
      queryService().getTrafficElementsFilteredByDeliveryPlanId(
        delivery_plan_id,
        1,
        'DESC'
      ),
    ])
    const lastTrafficTime = lastTraffics.at(0)?.date_time?.getTime()
    const firstTrafficTime = firstTraffics.at(0)?.date_time?.getTime()

    const events = await queryService().getRouteEventsFilteredByDeliveryPlanId(
      delivery_plan_id
    )

    const leavingTime = events
      .find((s) => s.type == 'leaving_warehouse')
      ?.date_time?.getTime()
    const returningTime = events
      .find((s) => s.type == 'returning_warehouse')
      ?.date_time?.getTime()
    const startTime = leavingTime ?? firstTrafficTime ?? new Date().getTime()
    const endTime = returningTime ?? lastTrafficTime ?? startTime

    const diffTime = endTime - startTime

    const startTimeWithin7days =
      diffTime <= MAX_RANGE ? startTime : endTime - MAX_RANGE
    return {
      serial_number: lastTraffics.at(0)?.sn ?? firstTraffics.at(0)?.sn ?? '',
      startTime: startTimeWithin7days,
      endTime: endTime,
    }
  }

  public isNoLeavingAndReturningWarehouseRoute = async (
    delivery_plan_id: string
  ): Promise<boolean> => {
    const res = await queryService().getRouteEventsFilteredByDeliveryPlanId(
      delivery_plan_id,
      1
    )
    if (res.length === 0) {
      return true
    }
    return false
  }

  public getRouteFilterBySerialNumberAndDatetimeRange = async (
    serial_number: string,
    startTime: number,
    endTime: number
  ): Promise<{ routes: getRouteResponse[]; serial_number: string }> => {
    const routes = await this.getRouteBySerialNoAndDateTimeRangeWithUser(
      serial_number,
      Math.floor(startTime),
      Math.ceil(endTime)
    )
    return new Promise((resolve) => {
      resolve({ routes: routes, serial_number: serial_number })
    })
  }

  public getRouteBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<getRouteResponse[]> => {
    //日付が小数のデータを正常に取得できないので加算
    endDateTime++

    const [traffics, alerts, routeEvents, sensorAlerts] = await Promise.all([
      queryService().getListTrafficBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
      queryService().getListAlertBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
      queryService().getListStatusBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
      this.getSensorAlertsBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startDateTime,
        endDateTime
      ),
    ])
    const alertDetails = await this.getAlertDetailsPerDeliveryPlanId(
      alerts,
      Math.floor(startDateTime),
      Math.ceil(endDateTime)
    )
    const alertResponses = await this.makeAlertResponse(alertDetails, alerts)
    const events = Status.makeEvents(traffics, routeEvents)

    const dgn = traffics[0]?.dgn
    const [deliveryPlan, driver, vehicle] = await Promise.all([
      this.findDeliveryPlanByDeliveryPlanId(dgn),
      this.findDriverByDeliveryPlanId(dgn),
      this.findVehicleByDeliveryPlanId(dgn),
    ])

    const total_mileage = Traffic.calculateTotalMileage(
      fromUnixTime(startDateTime),
      fromUnixTime(endDateTime),
      traffics
    )
    const total_running_time = Traffic.calculate_driving_time(
      traffics,
      fromUnixTime(startDateTime),
      fromUnixTime(endDateTime)
    )
    const displayRunningTime = formatTimeFromSeconds(total_running_time)
    const route: getRouteResponse = {
      path: traffics,
      alerts: alertResponses,
      events: events,
      sensorAlerts: sensorAlerts,
      leaving_warehouse_date_time: fromUnixTime(startDateTime),
      returning_warehouse_date_time: fromUnixTime(endDateTime),
      device_id: deliveryPlan?.device_name ?? '',
      registration_number: vehicle?.registration_number ?? '',
      vehicle_id: vehicle?.id,
      driver_name: driver?.name ?? '',
      delivery_plan_id: dgn,
      total_mileage: total_mileage,
      total_running_time: displayRunningTime,
      operation_office_id: vehicle?.operation_office_id,
    }
    return new Promise((resolve) => {
      resolve([route])
    })
  }

  public getConcatNoLeavingAndReturningWarehouseRouteBySerialNoAndDateTimeRangeWithUser =
    async (
      serial_number: string,
      startTime: number,
      endTime: number
    ): Promise<getRouteResponse[]> => {
      //日付が小数のデータを正常に取得できないので加算
      const startDateTime = Math.floor(startTime)
      const endDateTime = Math.ceil(endTime) + 1

      const [traffics, alerts, routeEvents] = await Promise.all([
        queryService().getListTrafficBySerialNoAndDateTimeRangeWithUser(
          serial_number,
          startDateTime,
          endDateTime
        ),
        queryService().getListAlertBySerialNoAndDateTimeRangeWithUser(
          serial_number,
          startDateTime,
          endDateTime
        ),
        queryService().getListStatusBySerialNoAndDateTimeRangeWithUser(
          serial_number,
          startDateTime,
          endDateTime
        ),
      ])
      const alertDetails = await this.getAlertDetailsPerDeliveryPlanId(
        alerts,
        Math.floor(startDateTime),
        Math.ceil(endDateTime)
      )
      const alertResponses = await this.makeAlertResponse(alertDetails, alerts)
      const events = Status.makeEvents(traffics, routeEvents)

      const leavingTime = fromUnixTime(startDateTime)
      const returningTime = fromUnixTime(endDateTime)

      const totalMileage = Traffic.calculateTotalMileage(
        fromUnixTime(startDateTime),
        fromUnixTime(endDateTime),
        traffics
      )
      const runningTime = Traffic.calculate_driving_time(
        traffics,
        fromUnixTime(startDateTime),
        fromUnixTime(endDateTime)
      )
      const displayRunningTime = formatTimeFromSeconds(runningTime)

      return new Promise((resolve) => {
        const route: getRouteResponse = {
          path: traffics,
          alerts: alertResponses,
          events: events,
          sensorAlerts: [],
          leaving_warehouse_date_time: leavingTime,
          returning_warehouse_date_time: returningTime,
          device_id: serial_number,
          registration_number: '',
          driver_name: '',
          delivery_plan_id: '',
          total_mileage: totalMileage,
          total_running_time: displayRunningTime,
        }
        resolve([route])
      })
    }

  public getSensorAlertsBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startTime: number,
    endTime: number
  ): Promise<sensorAlertRange[]> => {
    const sensors =
      await queryService().getListSensorBySerialNoAndDateTimeRangeWithUser(
        serial_number,
        startTime,
        endTime
      )
    const retAlerts = this.extractContinuousSensorAlert(sensors)
    return new Promise((resolve) => {
      resolve(retAlerts)
    })
  }

  public getSensorAlertsByDeliveryPlanIdWithUser = async (
    delivery_plan_id: string
  ): Promise<sensorAlertRange[]> => {
    const sensors = await queryService().getListSensorByDeliveryPlanIdWithUser(
      delivery_plan_id
    )
    const retAlerts = this.extractContinuousSensorAlert(sensors)
    return new Promise((resolve) => {
      resolve(retAlerts)
    })
  }

  private extractContinuousSensorAlert(sensors: sensor[]): sensorAlertRange[] {
    if (!sensors[0]) return []
    const sensorAlerts: sensor[] = []
    sensors.forEach((sensor) => {
      if (Sensor.getSensorAlert(sensor)[0]) sensorAlerts.push(sensor)
    })
    if (!sensorAlerts[0]) return []

    const range = 5 * 60

    const targetAlerts: sensor[] = []
    const retAlerts: sensorAlertRange[] = []
    let continueCount = 0
    let startAlertTime: number = 0
    let endAlertTime: number = 0
    let continueStartTime = 0
    let alert: sensor = sensors[0]

    continueStartTime = sensorAlerts[0].tm
    startAlertTime = sensorAlerts[0].tm

    let start = continueStartTime
    let end = continueStartTime + range

    for (let i = 0; i < sensorAlerts.length; i++) {
      alert = sensorAlerts[i]
      if (alert.tm < end) endAlertTime = alert.tm
      if (alert.tm < start) continue // skip

      if (alert.tm < end) {
        start += range
        end += range
        endAlertTime = alert.tm
        continueCount++
      } else {
        if (continueCount >= 3) {
          const ret = this.convertSensorAlertToSensorAlertRange(
            sensorAlerts[i - 1],
            startAlertTime,
            endAlertTime
          )
          retAlerts.push(ret)
        }
        continueStartTime = alert.tm
        startAlertTime = alert.tm
        start = continueStartTime + range
        end = continueStartTime + range * 2
        continueCount = 1
      }
    }
    if (continueCount >= 3) {
      const ret = this.convertSensorAlertToSensorAlertRange(
        alert,
        startAlertTime,
        endAlertTime
      )
      retAlerts.push(ret)
    }
    return retAlerts
  }

  private convertSensorAlertToSensorAlertRange(
    alert: sensor,
    startAlertTime: number,
    endAlertTime: number
  ): sensorAlertRange {
    const type = this.getSensorAlertType(alert)
    return {
      type: type,
      dgn: alert?.dgn ?? '',
      serial_number: alert?.sn ?? '',
      start_date_time: new Date(startAlertTime * 1000),
      finish_date_time: new Date(endAlertTime * 1000),
      position: {
        lat: alert?.lat ?? 0,
        lng: alert?.lon ?? 0,
      },
    }
  }

  private getSensorAlertType(alert: sensor): string {
    const statusKeys = ['u1s', 'u2s', 'u3s', 'u4s', 'u5s', 'u6s', 'u7s', 'u8s']
    const lowAlertRegex = /.*01$/
    const highAlertRegex = /.*10$/
    let type: sensorAlertStatus = sensorAlertStatus.ALERT_SENSOR_ERROR
    statusKeys.forEach((_key, i) => {
      const key = _key as keyof sensor
      if ((alert[key] as string).match(lowAlertRegex)) {
        type = sensorAlertStatus.ALERT_UNDER_THRESHOLD
      }
      if ((alert[key] as string).match(highAlertRegex)) {
        type = sensorAlertStatus.ALERT_OVER_THRESHOLD
      }
    })
    return type
  }

  public getMyOperationOffice = async (): Promise<
    myOperationOffice | undefined
  > => {
    const user = await MyOperationOffice.get()
    return new Promise((resolve) => {
      resolve(user?.data)
    })
  }

  public getOperationOffices = async (): Promise<OperationOfficeType[]> => {
    const res = await OperationOffice.get()
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public getWorkloads = async (
    delivery_plan_id: string,
    page: number
  ): Promise<workloadResponse> => {
    const res = await Workload.get(delivery_plan_id, page)
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public getWorkload = async (id: number): Promise<workloadType> => {
    const res = await Workload.show(id)
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public createWorkloads = async (
    request: RequestWorkload
  ): Promise<workloadType[]> => {
    const res = await Workload.post(request)
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public updateWorkloads = async (
    id: number,
    request: RequestWorkload
  ): Promise<workloadType[]> => {
    const res = await Workload.put(id, request)
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public deleteWorkloads = async (id: number): Promise<workloadType[]> => {
    const res = await Workload.delete(id)
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public getWorkloadItems = async (): Promise<workloadItemType> => {
    const res = await WorkloadItem.get()
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }

  public getWorkloadUnits = async (): Promise<workloadUnitType> => {
    const res = await WorkloadUnit.get()
    return new Promise((resolve) => {
      resolve(res?.data)
    })
  }
}

export default RestApiProvider
