export default {
  common: {
    loadingText: 'ロード中',
    unknownErrorMessage: '不明なエラー',
    workStatus: {
      leaving_warehouse: {
        vehicleStatus: '運行中',
        name: '出庫',
      },
      loading: {
        vehicleStatus: '荷積中',
        name: '荷積',
      },
      loading_end: {
        vehicleStatus: '荷積終了',
        name: '荷積終了',
      },
      unloading: {
        vehicleStatus: '荷卸中',
        name: '荷卸',
      },
      unloading_end: {
        vehicleStatus: '荷卸終了',
        name: '荷卸終了',
      },
      shipping: {
        vehicleStatus: '荷待中',
        name: '荷待',
      },
      shipping_end: {
        vehicleStatus: '荷待終了',
        name: '荷待終了',
      },
      incidental_work: {
        vehicleStatus: '付帯中',
        name: '付帯',
      },
      incidental_work_end: {
        vehicleStatus: '付帯終了',
        name: '付帯終了',
      },
      refueling: {
        vehicleStatus: '給油中',
        name: '給油',
      },
      refueling_end: {
        vehicleStatus: '給油終了',
        name: '給油終了',
      },
      wait: {
        vehicleStatus: '待機中',
        name: '待機',
      },
      wait_end: {
        vehicleStatus: '待機終了',
        name: '待機終了',
      },
      rest: {
        vehicleStatus: '休息中',
        name: '休息',
      },
      rest_end: {
        vehicleStatus: '休息終了',
        name: '休息終了',
      },
      other: {
        vehicleStatus: 'その他',
        name: 'その他',
      },
      other_end: {
        vehicleStatus: 'その他終了',
        name: 'その他終了',
      },
      high_way: {
        vehicleStatus: '高速道路',
        name: '高速道路',
      },
      general_way: {
        vehicleStatus: '一般道路',
        name: '一般道路',
      },
      actial_car: {
        vehicleStatus: '実車',
        name: '実車',
      },
      empty_car: {
        vehicleStatus: '空車',
        name: '空車',
      },
      maintenance: {
        vehicleStatus: '点検中',
        name: '点検',
      },
      maintenance_end: {
        vehicleStatus: '点検終了',
        name: '点検終了',
      },
      break: {
        vehicleStatus: '休憩中',
        name: '休憩',
      },
      break_end: {
        vehicleStatus: '休憩終了',
        name: '休憩終了',
      },
      returning_warehouse: {
        vehicleStatus: '運行終了',
        name: '帰庫',
      },
      stopping: {
        vehicleStatus: '停車中',
        name: '停車',
      },
      in_service: {
        vehicleStatus: '運行中',
        name: '運行',
      },
    },
    alerts: {
      over_speed: '速度超過',
      rapid_deceleration: '急減速',
      rapid_acceleration: '急加速',
      sharp_steering_wheel_and_steep_turn: '急旋回',
    },
    sensorAlerts: {
      over_threshold: '温度上限超過',
      under_threshold: '温度下限超過',
      sensor_error: 'センサー異常',
      start_time: '開始時刻',
      end_time: '終了時刻',
    },
    menu: {
      octlink: {
        text: '運行管理サービス',
      },
      routeSearch: {
        text: '運行ルート一覧',
      },
      sensorAlertNotification: {
        text: '温度センサー通知',
      },
    },
  },
  validate: {
    required: 'この項目を入力してください。',
    email:
      'メールアドレスが間違っています。正しいメールアドレスを入力してください。',
  },
  notification: {
    sensorInfo: {
      registration_number: {
        text: '車番',
      },
      device_id: {
        text: 'デバイスID',
      },
      unit: {
        text: '温度センサー',
      },
    },
    sensorAlert: {
      title: {
        text: '温度が閾値を超えました。',
      },
      suffix: {
        text: 'で温度が閾値を超えました。',
      },
    },
    sensorError: {
      title: {
        text: '温度センサーに問題が発生しました。',
      },
      suffix: {
        text: 'に問題が発生しました。',
      },
    },
    sensorAlertRecovered: {
      title: {
        text: '温度は正常です。',
      },
      suffix: {
        text: 'の温度は正常です。',
      },
    },
    sensorErrorRecovered: {
      title: {
        text: '温度センサーは正常です。',
      },
      suffix: {
        text: 'は正常です。',
      },
    },
  },
  pages: {
    company: {
      operationOffice: {
        name: '運営会社名',
      },
    },
    mainTemplate: {
      commonTitle: 'Fleet Map',
      logoutBtn: 'サインアウト',
    },
    vehicleMap: {
      loadVehicles: {
        error: '画面表示エラー　再度ログインしてください',
      },
      noVehicle: {
        header: '表示する車両はありません.',
      },
      leftPanel: {
        open: {
          text: '開く',
          vehicleCountText: '{count}台',
        },
        close: {
          text: '閉じる',
        },
        searchOperationOffices: {
          placeholder: '運営会社を検索',
        },
        searchBar: {
          placeholder: '車両またはドライバーを検索',
        },
        openRouteBtn: {
          text: '運行ルート',
        },
        statistics: {
          finished: '運行終了',
          resting: '停車中',
          inOperation: '作業中',
          inService: '運行中',
        },
      },
      vehicleList: {
        openRouteBtn: {
          text: '運行ルート',
        },
      },
      vehicleDetail: {
        noAddress: {
          text: '住所を取得する ',
        },
        tooltip: {
          address: '住所を取得する',
        },
      },
      tooltip: {
        traffic: '渋滞情報',
        vehicleName: '車両番号',
        spot: '登録地点',
        satellite: '航空写真',
        thermostat: '温度異常',
      },
    },
    routeMap: {
      title: '運行ルート詳細',
      search: {
        cancel: { text: 'キャンセル' },
        select: { text: '選択' },
        delete: { text: '削除' },
        search: { text: '検索' },
        flashMessage: {
          noSerialNo: { text: 'デバイスIDが存在しません。' },
          failedGetInformations: { text: 'データの取得に失敗しました。' },
          required: { text: '開始日時と終了日時を指定してください。' },
          invalidTime: {
            text: '終了日時よりも前の開始日時を指定してください。',
          },
          within7days: { text: '有効な日付範囲は7日以内です。' },
        },
      },
      noRouteData: {
        header: '運行ルートはありません',
      },
      loadRoute: {
        error: 'Load route error',
      },
      loadVideo: {
        error: 'エラーが起きています',
      },
      routeDetail: {
        noWorks: {
          text: '作業はありません',
        },
        noAlerts: {
          text: '危険挙動はありません',
        },
        returningWarehouseIsUndefined: {
          text: '運行中です ',
        },
        registrationNumber: {
          text: '車両番号',
        },
        numberOfWorks: {
          text: 'つの作業',
        },
        showMore: {
          text: 'もっと表示する',
        },
        deviceId: {
          text: 'デバイスID',
        },
        driverName: {
          text: 'ドライバー名',
        },
        routeID: {
          text: 'Trip ID',
        },
        totalMileage: {
          text: '総走行距離',
        },
        totalRunningTime: {
          text: '総走行時間',
        },
        eventStatus: {
          running: {
            text: '走行合計',
          },
          stop: {
            text: '停止合計',
          },
          stopOverTenMinutes: {
            text: '10分超停止合計',
          },
        },
        isCargo: {
          text: '荷物量登録有り',
        },
        chartNote: {
          text: '※加速度設定を選択された場合は、速度変化をG値に変換して表示しています。',
        },
        beforeCopy: {
          text: 'コピーする',
        },
        afterCopySuccess: {
          text: 'コピーしました',
        },
        afterCopyFailure: {
          text: 'コピーに失敗しました',
        },
      },
      tooltip: {
        alert: '危険挙動',
        work: '作業',
        rest: '休息・休憩',
        spot: '登録地点',
      },
      gValue: 'G値',
      speedValue: '速度',
      timelineTab: {
        work: '作業',
        alert: '危険挙動',
      },
    },
    searchRoute: {
      title: '運行ルート一覧',
      header: '運行ルート一覧',
      filter: {
        operation_offices: {
          label: '運営会社',
        },
        vehicle: {
          label: '車両',
        },
        driver: {
          label: 'ドライバー',
        },
        date: {
          label: '日付',
          placeholder: '日付を選択',
          cancelText: 'キャンセル ',
          selectText: '選択',
        },
        notFound: {
          text: '検索対象が見つかりません',
        },
        noOptions: {
          text: 'リストがありません',
        },
      },
      tableRoutes: {
        header: {
          tripID: {
            text: 'tripID',
          },
          deliveryDate: {
            text: '出庫日',
          },
          returnDate: {
            text: '帰庫日',
          },
          vehicle: {
            text: '車両',
          },
          driver: {
            text: 'ドライバー',
          },
        },
      },
      notFound: '対象の運行がありません',
      loadDriversAndVehicles: {
        error: 'ドライバーと車両が見つかりません',
        selectOperationOffices: '運営会社を選択',
        selectDriver: 'ドライバーを選択',
        selectVehicle: '車両を選択',
      },
      findRoute: {
        error: 'エラーが起きています',
        dateRangeError:
          '指定された日付が不正です。過去１３ヶ月以内の日付を指定してください',
      },
    },
    driverMap: {
      title: '運行ルート詳細',
      search: {
        cancel: { text: 'キャンセル' },
        select: { text: '選択' },
        delete: { text: '削除' },
        search: { text: '検索' },
        flashMessage: {
          noSerialNo: { text: 'デバイスIDが存在しません。' },
          required: { text: '開始日時と終了日時を指定してください。' },
          invalidTime: {
            text: '終了日時よりも前の開始日時を指定してください。',
          },
          within7days: { text: '有効な日付範囲は7日以内です。' },
        },
      },
      cargoAmountRegistration: {
        buttonName: '荷物量登録',
        modalTitle: '数量登録',
        name: '品名',
        quantity: '数量',
        unit: '単位',
        save: '登録',
      },
      refuelingVolumeRegistration: {
        buttonName: '給油量登録',
        modalTitle: '給油量登録',
        name: '給油所種類',
        unit: 'L',
      },
      isRegistered: '荷物量登録有り',
      validation: {
        name: {
          isEmpty: '品名を入力してください。',
          maxLength: '品名は10文字以内で入力してください。',
        },
        refuelingName: {
          isEmpty: '給油所名を入力してください。',
        },
        quantity: {
          isEmpty: '数量を入力してください。',
          cargoMinMax: '0~9999.99までの数字を入力してください。',
          refuelingMinMax: '0~999.99までの数字を入力してください。',
          isNotNumber: '数量は数字で入力してください。',
          overDecimal: '数量は小数点第二位までで入力してください。',
        },
        unit: {
          isEmpty: '単位を入力してください。',
        },
      },
      noRouteData: {
        header: '運行ルートはありません',
      },
      notDriving: {
        label: '現在運行中ではありません',
      },
    },
    noData: 'N/A',
    notFound: {
      title: 'ページが見つかりません',
      noRoutePage: {
        header: 'ページが見つかりません',
      },
    },
  },
}
