import restApiConnection from '@/services/api/providers/Connection'
import UserSession from '@/services/models/UserSession'
import vehiclesResponse from '@/types/responses/vehiclesResponse'
import vehicleInfo from '@/types/vehicleInfo'
import RestApiBase from '@/services/models/RestApiBase'

export default class Vehicle extends RestApiBase {
  public static async all(
    forceUpdate: boolean = false,
    callback: Function = (vehicles: vehicleInfo[]) => {
      return vehicles
    }
  ): Promise<vehiclesResponse> {
    return this.sync('GetVehicles', () => {
      return this.selectAll({ cache: !forceUpdate, callback: callback })
    })
  }

  public static selectAll = async (args: {
    cache: boolean
    callback: Function
  }): Promise<vehiclesResponse> => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id

    if (user_id === undefined) {
      args.callback([])
      return new Promise((resolve) => {
        resolve({ data: [] as vehicleInfo[] } as vehiclesResponse)
      })
    }

    const limitPerPage = 20
    let maxPageCount = 1

    let vehicles: vehicleInfo[] = []
    for (let i = 1; i <= maxPageCount; i++) {
      const _vehicles = await restApiConnection.get(
        `/vehicles?user_session_id=${user_id}&page=${i}&limit=${limitPerPage}`,
        {
          headers: {
            'Cache-Control': 'max-age=0',
          },
          cache: args.cache,
        }
      )

      const totalPages = _vehicles?.data?.total_pages ?? 1
      const data = _vehicles?.data?.vehicles ?? []
      maxPageCount = totalPages

      if (data.length === 0) break

      vehicles = vehicles.concat(data)
      args.callback(data)
    }
    return new Promise((resolve) => {
      resolve({ data: vehicles } as vehiclesResponse)
    })
  }
}
