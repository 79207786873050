
import { defineComponent, PropType, ref, watch } from 'vue'
import { Line } from 'vue-chartjs'
import {
  CategoryScale,
  Chart as ChartJS,
  Chart,
  ChartDataset,
  ChartEvent,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  Plugin,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { AnyObject } from 'chart.js/types/basic'
import TimelineHelper from '@/services/map/alert/TimelineHelper'
import { timeLinePosition, timeLineProducer } from '@/types/timeLinePosition'
import { speedHistory } from '@/types/speedHistory'
import { gyroscopeHistory } from '@/types/gyroscopeHistory'
import { Point } from 'chart.js/types/geometric'
import { alertStatus } from '@/services/const'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  TimeScale,
  PointElement,
  CategoryScale
)

const chartTimeLinePositionInPercentage = ref<number>(0)
const isItChartEvent = ref<boolean>(false)
const drawTimeLineLocalContext = ref<boolean>(true)

function drawTimelineOnChart(
  chart: Chart,
  positionInPercentage: number | undefined
): void {
  if (!positionInPercentage) {
    return
  }
  const chartAreaPositionX = TimelineHelper.convertPercentToNumber(
    positionInPercentage,
    chart.chartArea.width
  )
  let chartPositionX = chartAreaPositionX + chart.chartArea.left

  let ctx = chart.ctx
  ctx.save()
  ctx.beginPath()
  ctx.moveTo(chartPositionX, chart.chartArea.top)
  ctx.lineTo(chartPositionX, chart.chartArea.bottom)
  ctx.setLineDash([12, 12])
  ctx.lineWidth = 3
  ctx.strokeStyle = '#616161'
  ctx.stroke()
  ctx.restore()
  ctx.save()

  ctx.setLineDash([])
}

function wasClickInsideChartArea(event: ChartEvent, chart: Chart): boolean {
  if (!event.x || !event.y) {
    return false
  }

  return (
    event.x >= chart.chartArea.left &&
    event.x <= chart.chartArea.right &&
    event.y >= chart.chartArea.top &&
    event.y <= chart.chartArea.bottom
  )
}

interface Props {
  globalTimeLinePosition?: timeLinePosition
  drawTimeLine?: boolean
}

export default defineComponent({
  name: 'AlertChart',
  emits: ['chartClick'],
  setup(props, { emit }) {
    watch(chartTimeLinePositionInPercentage, (newPosition) => {
      if (!isItChartEvent.value) {
        return
      }
      isItChartEvent.value = false

      // console.log('change global chart value');

      emit('chartClick', {
        positionInPercentage: newPosition,
        producer: timeLineProducer.CHART,
        producerID: 0,
      })
    })

    const chartInstance = ref(null)
    const propsForTS = props as Props
    watch(
      () => propsForTS.globalTimeLinePosition,
      (timeLinePosition) => {
        if (chartInstance.value && timeLinePosition) {
          // console.log('update local chart value');

          chartTimeLinePositionInPercentage.value = Number(
            timeLinePosition.positionInPercentage
          )
          const chart = (chartInstance.value as any).chart as Chart
          chart.update()
        }
      }
    )

    drawTimeLineLocalContext.value = true
    watch(
      () => propsForTS.drawTimeLine,
      (value) => {
        drawTimeLineLocalContext.value = value || false
      }
    )

    return {
      chartTimeLinePositionInPercentage,
      chartInstance,
    }
  },
  props: {
    chartId: {
      type: String as PropType<string>,
      default: 'bar-chart',
    },
    globalTimeLinePosition: {
      type: Object as PropType<timeLinePosition>,
    },
    arGyroscopeHistory: {
      type: Array as PropType<gyroscopeHistory[]>,
      default: () => [],
    },
    arSpeedHistory: {
      type: Array as PropType<speedHistory[]>,
      default: () => [],
    },
    alertType: {
      type: String as PropType<alertStatus>,
    },
    plugins: {
      type: Array as PropType<Plugin<'line'>[]>,
      default: () => [
        {
          id: 'myEventCatcher',
          afterDraw(chart: Chart) {
            if (drawTimeLineLocalContext.value) {
              drawTimelineOnChart(
                chart,
                chartTimeLinePositionInPercentage.value
              )
            }
          },
          afterEvent(chart: Chart, args: AnyObject) {
            const event = args.event as ChartEvent
            if (event.type === 'click' && event.x && event.y) {
              if (wasClickInsideChartArea(args.event as ChartEvent, chart)) {
                const chartClickPositionX = event.x
                const chartAreaClickPositionX =
                  chartClickPositionX - chart.chartArea.left
                chartTimeLinePositionInPercentage.value =
                  TimelineHelper.convertNumberToPercent(
                    chartAreaClickPositionX,
                    chart.chartArea.width
                  )
                isItChartEvent.value = true
              }
            }
          },
        },
        Filler,
      ],
    },
    drawTimeLine: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  data() {
    return {
      datasetIdKey: 'label' as String,
      width: 400 as number,
      height: 350 as number,
      cssClasses: '' as String,
      styles: {} as Partial<CSSStyleDeclaration>,
    }
  },
  mounted() {},
  computed: {
    chartData(): object {
      let datasets: ChartDataset[] = []

      if (this.needToShowGyroscopeAxis) {
        datasets.push(this.gyroscopeAxisDataset)
      }

      if (this.needToShowSpeedAxis) {
        datasets.push(this.speedAxisDataset)
      }

      return {
        datasets: datasets,
      }
    },
    speedAxisDataset(): ChartDataset {
      return {
        label: this.$t('pages.routeMap.speedValue'),
        backgroundColor: 'rgba(59, 130, 246, 0.5)',
        borderColor: 'rgba(59, 130, 246, 0.5)',
        data: this.speedData,
        yAxisID: 'y',
        borderWidth: 1,
        fill: {
          target: 'origin',
          above: 'rgba(59,130,246,0.09)',
        },
      }
    },
    gyroscopeAxisDataset(): ChartDataset {
      return {
        label: this.$t('pages.routeMap.gValue'),
        backgroundColor: '#f87979',
        borderColor: '#f87979',
        data: this.gyroscopeHistory,
        yAxisID: 'y1',
        fill: false,
        borderWidth: 1,
        // pointRadius: 2,
      }
    },
    speedData(): Point[] {
      const tsHack = this as any
      return tsHack.arSpeedHistory.map((speedHistory: speedHistory) => {
        return {
          x: speedHistory.time,
          y: speedHistory.speed,
        }
      })
    },
    gyroscopeHistory(): Point[] {
      const tsHack = this as any
      return tsHack.arGyroscopeHistory.map((speedHistory: gyroscopeHistory) => {
        let axisValue
        if (this.isRapidAcceleration || this.isRapidDeceleration) {
          axisValue = speedHistory.Y
        } else {
          axisValue = speedHistory.X
        }

        return {
          x: speedHistory.time,
          y: axisValue,
        }
      })
    },
    chartOptions(): object {
      return {
        responsive: true,
        maintainAspectRatio: false,
        // interaction: {
        //   mode: 'index',
        //   intersect: false,
        // },
        // stacked: false,
        scales: this.chartOptionsScales,
        events: ['click'], // 'mousemove', 'mouseout',  'touchstart', 'touchmove'
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
        },
      }
    },
    chartOptionsScales(): any {
      const scales: any = {
        x: this.timeScaleSetting,
      }

      if (this.needToShowGyroscopeAxis) {
        scales.y1 = this.gyroscopeScaleSetting
      }

      if (this.needToShowSpeedAxis) {
        scales.y = this.speedScaleSetting
      }

      return scales
    },
    timeScaleSetting(): any {
      return {
        type: 'time',
        display: true,
        position: 'bottom',
        time: {
          unit: 'second',
          stepSize: 2,
          displayFormats: {
            second: 'mm:ss',
          },
          tooltipFormat: 'll HH:mm:ss',
        },
      }
    },
    speedScaleSetting(): any {
      return {
        type: 'linear',
        display: true,
        position: 'left',

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      }
    },
    gyroscopeScaleSetting(): any {
      return {
        type: 'linear',
        display: true,
        position: 'right',
      }
    },
    isRapidAcceleration(): boolean {
      const tsHack = this as any
      return tsHack.alertType === alertStatus.ALERT_STATUS_RAPID_ACCELERATION
    },
    isRapidDeceleration(): boolean {
      const tsHack = this as any
      return tsHack.alertType === alertStatus.ALERT_STATUS_RAPID_DECELERATION
    },
    isSharpWheelTurn(): boolean {
      const tsHack = this as any
      return (
        tsHack.alertType ===
        alertStatus.ALERT_STATUS_SHARP_STEERING_WHEEL_AND_STEEP_TURN
      )
    },
    isOverSpeed(): boolean {
      const tsHack = this as any
      return tsHack.alertType === alertStatus.ALERT_STATUS_OVER_SPEED
    },
    needToShowGyroscopeAxis(): boolean {
      const tsHack = this as any
      return (
        tsHack.isRapidAcceleration ||
        tsHack.isRapidDeceleration ||
        tsHack.isSharpWheelTurn
      )
    },
    needToShowSpeedAxis(): boolean {
      const tsHack = this as any
      return (
        tsHack.isRapidAcceleration ||
        tsHack.isRapidDeceleration ||
        tsHack.isOverSpeed
      )
    },
  },
  watch: {},
  methods: {},
  components: {
    ChartLine: Line,
  },
})
