import restApiConnection from '@/services/api/providers/Connection'
import RestApiBase from '@/services/models/RestApiBase'
import workloadItemType from '@/types/responses/workloadItem'
import UserSession from '@/services/models/UserSession'
import { AxiosResponse } from 'axios'

export default class SelfWorkload extends RestApiBase {
  public static async get(): Promise<AxiosResponse<workloadItemType>> {
    return this.sync('GetWorkloads', this.getOwn)
  }

  public static getOwn = async (): Promise<AxiosResponse<workloadItemType>> => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    const getLimit = 12
    return restApiConnection.get(
      `/workload_items?user_session_id=${user_id}&limit=${getLimit}`,
      {
        headers: {
          'Cache-Control': 'max-age=0',
        },
      }
    )
  }
}
