import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('v-link-click'))),
    class: _normalizeClass([_ctx.classObject, "border rounded-md focus:outline-none block text-center"]),
    target: _ctx.blank ? '_blank' : null
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}