
import { defineComponent, PropType } from 'vue'
import VIcon from '@/components/template/v-icon.vue'

export default defineComponent({
  name: 'LeftPanel',
  data() {
    return {
      isOpen: true as boolean,
      isOpenMobile: false as boolean,
    }
  },
  props: {
    openText: {
      type: String as PropType<string>,
      default: '',
    },
  },
  provide() {
    return {
      togglePanelMobile: this.togglePanelMobile,
    }
  },
  computed: {
    getIcon(): string {
      return this.isOpen ? 'arrow_left' : 'arrow_right'
    },
    getOpenText(): string {
      return this.openText
        ? this.openText
        : this.$t('pages.vehicleMap.leftPanel.open.text')
    },
  },
  methods: {
    togglePanel(): void {
      this.isOpen = !this.isOpen
    },
    togglePanelMobile(): void {
      this.isOpenMobile = !this.isOpenMobile
    },
  },
  components: {
    VIcon,
  },
  emits: ['changeVisibility'],
  watch: {
    isOpen: function (): void {
      this.$emit('changeVisibility', this.isOpen)
    },
  },
})
