import { DateConvertor } from '@/services/dateConvertor/DateConvertor'
import { AlertDate } from '../../services/dateConvertor/AlertDate'

import { format, addMilliseconds, addSeconds, subSeconds } from 'date-fns'

export class BaseDateIndentConvertor implements DateConvertor {
  private arDatesNearToBaseDate: { [key: string]: Date } = {}
  private indentInSeconds = 40

  public convertDates(baseDate: Date, arAlertDates: AlertDate[]): AlertDate[] {
    this.findNearDates(baseDate)
    this.addFoundDates(arAlertDates)
    return arAlertDates
  }

  private findNearDates(baseDate: Date) {
    this.findDatesBeforeBaseLine(baseDate)
    this.addBaseDate(baseDate)
    this.findDatesAfterBaseLine(baseDate)
  }

  private findDatesBeforeBaseLine(baseDate: Date) {
    for (let i = 0; i < this.indentInSeconds; i++) {
      const date = subSeconds(baseDate, i)
      this.arDatesNearToBaseDate[format(date, 'yyyyMdHms')] = date
    }
  }

  private addBaseDate(baseDate: Date) {
    this.arDatesNearToBaseDate[format(baseDate, 'yyyyMdHms')] = baseDate
  }

  private findDatesAfterBaseLine(baseDate: Date) {
    for (let i = 0; i < this.indentInSeconds; i++) {
      const date = addSeconds(baseDate, i)
      this.arDatesNearToBaseDate[format(date, 'yyyyMdHms')] = date
    }
  }

  private addFoundDates(arAlertDates: AlertDate[]) {
    arAlertDates.map((alertDate) => {
      if (alertDate.getDate()) return
      const dateString = alertDate.getDateString()

      let foundAlertDate: Date | undefined = undefined
      Object.keys(this.arDatesNearToBaseDate).forEach(
        (nearAlertDate: string) => {
          if (nearAlertDate === dateString) {
            foundAlertDate = this.arDatesNearToBaseDate[nearAlertDate]
            return false
          }
        }
      )
      if (foundAlertDate !== undefined) {
        const milisecond = AlertDate.parseMicroseconds(foundAlertDate)
        const date = addMilliseconds(foundAlertDate, Number(milisecond))
        alertDate.setDate(date)
      }
    })
  }
}
