import sensor from '@/types/sensor'
import vehicle from '@/types/vehicle'
import sensorAlert from '@/types/sensorAlert'
import sensorAlertCount from '@/types/sensorAlertCount'
import sensorUnitTimes from '@/types/sensorUnitTimes'
import SensorModel from '@/services/models/Sensor'
import queryService from '@/services/query/QueryServiceFabrick'

import NotificationModel from '@/services/models/Sensor/Notification'

import Push from 'push.js'
import i18n from '@/lang/i18n'
import { formatDateTime } from '@/services/functions'

export default class Notification {
  public type = 'alert' as keyof sensorAlertCount
  public count = 3
  public thresholdCount = 3
  public range = 5 * 60
  public notificationable = true
  public units = [1, 2, 3, 4, 5, 6, 7, 8]
  public latestNotificationTimes: {
    [device_id: string]: sensorUnitTimes
  } = {}

  constructor(type: string, count: number, thresholdCount: number) {
    this.type = type as keyof sensorAlertCount
    this.count = count
    this.thresholdCount = thresholdCount
  }

  public setNotificationable(flag: boolean) {
    this.notificationable = flag
  }

  public async notify(vehicle: vehicle, sensor: sensor) {
    if (!this.notificationable) return
    const currentAlert = SensorModel.hasSensorError([sensor])

    const totalTime = this.count * this.range
    const sensors = await this.getSensors(vehicle, sensor.tm, totalTime)
    const splited = SensorModel.splitSensors(
      sensors,
      sensor.tm,
      this.range,
      this.count
    )

    const notifyUnits = this.notifyUnits(splited, vehicle)
    this.notifySensorAlert(vehicle, sensor, notifyUnits)
  }
  protected notifyUnits(
    splitedSensors: sensor[][],
    vehicle: vehicle
  ): number[] {
    return []
  }

  protected notifySensorAlert(
    vehicle: vehicle,
    sensor: sensor,
    units: number[]
  ) {
    this.notifySensor(vehicle, sensor, units, this.type)
  }

  protected notifySensor(
    vehicle: vehicle,
    sensor: sensor,
    units: number[],
    type: string
  ) {
    if (!units[0]) return
    let prefix = ''
    if (type == 'alert') prefix = 'notification.sensorAlert'
    if (type == 'error') prefix = 'notification.sensorError'
    if (type == 'alert_recovered') prefix = 'notification.sensorAlertRecovered'
    if (type == 'error_recovered') prefix = 'notification.sensorErrorRecovered'
    units.forEach((unit) => {
      this.setLatestNotificationTime(vehicle.device_id, unit, sensor.tm)
    })

    const unitText =
      i18n.global.t('notification.sensorInfo.unit.text') + ' ' + units.join(',')
    let vehicleLableTitle =
      i18n.global.t('notification.sensorInfo.registration_number.text') + ':'
    let vehicleLabel = vehicle.registration_number
    if (vehicleLabel == '') {
      vehicleLableTitle =
        i18n.global.t('notification.sensorInfo.device_id.text') + ':'
      vehicleLabel = vehicle.device_id
    }
    const time = formatDateTime(new Date(sensor.tm * 1000))
    const url = `${process.env.BASE_URL}route/${sensor.dgn}?lng=${sensor.lon}&lat=${sensor.lat}`

    const body = `${vehicleLableTitle}${vehicleLabel} \n${unitText} ${i18n.global.t(
      `${prefix}.suffix.text`
    )}\n${time}`
    this.sensorNotify(i18n.global.t(`${prefix}.title.text`), body, url)
  }

  protected sensorNotify(title: string, body: string, url: string) {
    Push.create(title, {
      body: body,
      icon: '/images/favicons/favicon-32x32.png',
      timeout: 5 * 60 * 1000,
      onClick: () => window.open(url, '_blank'),
    })
  }

  public getLatestNotificationTime(device_id: string, unit: number): number {
    const unitKey = `unit${unit}` as keyof sensorUnitTimes
    if (!this.latestNotificationTimes[device_id])
      this.latestNotificationTimes[device_id] = {} as sensorUnitTimes
    return this.latestNotificationTimes[device_id][unitKey] ?? 0
  }

  protected resetLatestNotificationTime(device_id: string, unit: number) {
    this.setLatestNotificationTime(device_id, unit, 0)
  }

  protected setLatestNotificationTime(
    device_id: string,
    unit: number,
    value: number
  ) {
    const unitKey = `unit${unit}` as keyof sensorUnitTimes
    if (!this.latestNotificationTimes[device_id])
      this.latestNotificationTimes[device_id] = {} as sensorUnitTimes
    this.latestNotificationTimes[device_id][unitKey] = value
  }

  protected getSensors(
    vehicle: vehicle,
    time: number,
    range: number
  ): Promise<sensor[]> {
    return queryService().getListSensorBySerialNoAndDateTimeRangeWithUser(
      vehicle.device_id,
      Math.floor(time - range),
      Math.ceil(time)
    )
  }
}
