import { workStatus, REST_THRESHOLD_MILLI_SECCONDS } from '../../services/const'

class VehicleWorkStatus {
  protected status: workStatus

  constructor(status: workStatus) {
    this.status = status
  }

  isInService(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_LEAVING_WAREHOUSE:
      case workStatus.WORK_STATUS_IN_SERVICE:
        return true
      default:
        return false
    }
  }

  isInOperation(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_LOADING:
      case workStatus.WORK_STATUS_UNLOADING:
      case workStatus.WORK_STATUS_SHIPPING:
      case workStatus.WORK_STATUS_WAIT:
      case workStatus.WORK_STATUS_REFUELING:
      case workStatus.WORK_STATUS_INCIDENTAL_WORK:
        return true
      default:
        return false
    }
  }

  isInRest(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_BREAK:
      case workStatus.WORK_STATUS_REST:
        return true
      default:
        return false
    }
  }

  isStop(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_STOPPING:
        return true
      default:
        return false
    }
  }

  isReturn(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_RETURNING_WAREHOUSE:
        return true
      default:
        return false
    }
  }

  isNothing(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_NOTHING:
        return true
      default:
        return false
    }
  }

  isFinished(): boolean {
    return (
      !this.isInService() &&
      !this.isInOperation() &&
      !this.isInRest() &&
      !this.isStop() &&
      !this.isNothing()
    )
  }

  isRest(): boolean {
    return VehicleWorkStatus.isRestStatus(this.status)
  }

  isBreak(): boolean {
    return VehicleWorkStatus.isBreakStatus(this.status)
  }

  getRestStatusByBreakStatus(): workStatus {
    return VehicleWorkStatus.getRestStatusByBreakStatus(this.status)
  }

  static isRestStatus(status: workStatus): boolean {
    return (
      status === workStatus.WORK_STATUS_REST ||
      status === workStatus.WORK_STATUS_REST_END
    )
  }

  static isBreakStatus(status: workStatus): boolean {
    return (
      status === workStatus.WORK_STATUS_BREAK ||
      status === workStatus.WORK_STATUS_BREAK_END
    )
  }

  static isNeedToChangeBreakToRest(
    beforeTime: number,
    afterTime: number
  ): boolean {
    const milliSecondsSinceStatusCreated =
      new Date(afterTime).getTime() - new Date(beforeTime).getTime()
    return milliSecondsSinceStatusCreated > REST_THRESHOLD_MILLI_SECCONDS
  }

  static getRestStatusByBreakStatus(status: workStatus): workStatus {
    if (status === workStatus.WORK_STATUS_BREAK) {
      return workStatus.WORK_STATUS_REST
    }
    return workStatus.WORK_STATUS_REST_END
  }

  public isEventRelatedToCarLoadEvent(): boolean {
    switch (this.status) {
      case workStatus.WORK_STATUS_EMPTY_CAR:
      case workStatus.WORK_STATUS_ACTUAL_CAR:
      case workStatus.WORK_STATUS_LEAVING_WAREHOUSE:
        return true
      default:
        return false
    }
  }

  public isCarLoaded(): boolean {
    return this.status === workStatus.WORK_STATUS_ACTUAL_CAR
  }
}

export default VehicleWorkStatus
