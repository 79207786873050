import PolylinePattern from '@/services/map/polyline/PolylinePattern'

class SimplePolylinePattern implements PolylinePattern {
  color: string

  constructor(color?: string) {
    if (color) {
      this.color = color
    } else {
      this.color = 'black'
    }
  }

  isCover(speed: number): boolean {
    return true
  }
}

export default SimplePolylinePattern
