import position from '@/types/position'
import { toRefs, reactive } from 'vue'

export default function useMap() {
  const state = reactive({
    center: { lat: 35.455711666667, lng: 133.337 } as position,
    defaultZoom: 17 as number,
    mapOptions: {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
    },
  })

  return toRefs(state)
}
