import sensor from '@/types/sensor'
import vehicle from '@/types/vehicle'
import sensorAlert from '@/types/sensorAlert'
import sensorAlertCount from '@/types/sensorAlertCount'
import SensorModel from '@/services/models/Sensor'
import queryService from '@/services/query/QueryServiceFabrick'

import NotificationModel from '@/services/models/Sensor/Notification'

export default class AlertNotification extends NotificationModel {
  constructor(type: string) {
    const getCount = 3
    const threshold = 3
    super(type, getCount, threshold)
  }

  protected notifyUnits(
    splitedSensors: sensor[][],
    vehicle: vehicle
  ): number[] {
    const notifyUnits: number[] = []
    const alertCounts: number[] = []

    splitedSensors
      .slice()
      .reverse()
      .forEach((splitedSensor) => {
        const sensorAlert = SensorModel.hasSensorError(splitedSensor)

        this.units.forEach((unit) => {
          if (!alertCounts[unit]) alertCounts[unit] = 0
          const latestNotificationTime = this.getLatestNotificationTime(
            vehicle.device_id,
            unit
          )

          if (sensorAlert[this.type].indexOf(unit) != -1) {
            alertCounts[unit]++
          } else {
            alertCounts[unit] = 0
          }
          if (
            !latestNotificationTime &&
            alertCounts[unit] == this.thresholdCount
          ) {
            notifyUnits.push(unit)
          }
        })
      })
    this.units.forEach((unit) => {
      if (
        notifyUnits.indexOf(unit) == -1 &&
        alertCounts[unit] < this.thresholdCount
      ) {
        this.resetLatestNotificationTime(vehicle.device_id, unit)
      }
    })
    return notifyUnits
  }
}
