import restApiConnection from '@/services/api/providers/Connection'
import UserSession from '@/services/models/UserSession'
import deviceAlertInfo from '@/types/deviceAlertInfo'
import deviceAlertsResponse from '@/types/responses/deviceAlertsResponse'
import getDeviceAlertsResponse from '@/types/responses/getDeviceAlertsResponse'
import RestApiBase from '@/services/models/RestApiBase'

export default class DeviceAlert extends RestApiBase {
  public static async find(
    delivery_plan_id: string,
    startDateTime: number | null = null,
    endDateTime: number | null = null
  ): Promise<deviceAlertsResponse> {
    let key = 'DeviceAlertDeliveryPlanId' + delivery_plan_id
    if (startDateTime) key += 'startDateTime:' + startDateTime
    if (endDateTime) key += 'endDateTime:' + endDateTime
    return this.sync(key, this.selectByDeliveryPlanId, {
      delivery_plan_id,
      startDateTime,
      endDateTime,
    })
  }

  public static selectByDeliveryPlanId = async (args: {
    delivery_plan_id: string
    startDateTime: number
    endDateTime: number
  }): Promise<deviceAlertsResponse> => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    let query =
      '?delivery_plan_id=' +
      args.delivery_plan_id +
      '&user_session_id=' +
      user_id
    if (args.startDateTime) {
      query += '&start_date_time=' + args.startDateTime
    }
    if (args.endDateTime) {
      query += '&end_date_time=' + args.endDateTime
    }

    let merged: deviceAlertInfo[] = []
    let maxPages = 1
    // limitPerPage * limitPages = 300 records
    const limitPerPage = 10
    const limitPages = 30
    for (let i = 0; i < maxPages; i++) {
      const page = i + 1

      const res = await restApiConnection.get(
        '/device_alerts' + query + `&page=${page}&limit=${limitPerPage}`,
        { cache: true }
      )
      const alerts: getDeviceAlertsResponse = res?.data
      maxPages = Math.min(alerts?.total_pages ?? 1, limitPages)

      const deviceAlerts = alerts?.device_alerts ?? []
      if (deviceAlerts.length === 0) break

      merged = merged.concat(deviceAlerts)
    }
    return new Promise((resolve) => {
      resolve(<deviceAlertsResponse>{ data: merged })
    })
  }
}
