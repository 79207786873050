import {
  workStatus,
  alertStatus,
  ALERT_STATUS_MAP,
  STATUS_MAP,
} from '@/services/const'
import position from '@/types/position'
import trafficElement from '@/types/trafficElement'
import alert from '@/types/alert'
import sensor from '@/types/sensor'
import routeEvent from '@/types/routeEvent'
import QueryProvider from '@/services/query/providers/QueryProvider'
import { DeviceAlert, Status, Traffic, DriverSet } from '@/API'

class DummyProvider implements QueryProvider {
  DEFAULT_STATUS = workStatus.WORK_STATUS_IN_SERVICE

  public vehicleStatus = async (
    serial_no: string
  ): Promise<workStatus | undefined> => {
    return new Promise((resolve) => {
      resolve(workStatus.WORK_STATUS_LEAVING_WAREHOUSE)
    })
  }
  public latestTraffic = async (serial_no: string): Promise<Traffic> => {
    return new Promise((resolve) => {
      resolve(<Traffic>{})
    })
  }
  public latestStatus = async (
    serial_no: string
  ): Promise<Status | undefined> => {
    return new Promise((resolve) => {
      resolve(<Status>{})
    })
  }
  public latestServiceStatus = async (
    serial_no: string
  ): Promise<workStatus> => {
    return new Promise((resolve) => {
      resolve(workStatus.WORK_STATUS_LEAVING_WAREHOUSE)
    })
  }
  public latestWorkStatus = async (serial_no: string): Promise<workStatus> => {
    return new Promise((resolve) => {
      resolve(workStatus.WORK_STATUS_LEAVING_WAREHOUSE)
    })
  }
  public isInService = async (serial_no: string): Promise<boolean> => {
    return new Promise((resolve) => {
      resolve(true)
    })
  }
  public isLoaded = async (serial_no: string): Promise<boolean> => {
    return new Promise((resolve) => {
      resolve(true)
    })
  }
  public latestPosition = async (serial_no: string): Promise<position> => {
    return new Promise((resolve) => {
      const data = {
        lat: 0.0,
        lng: 0.0,
      }
      resolve(<position>data)
    })
  }
  public getTrafficElementsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<trafficElement[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getRouteEventsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<routeEvent[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getAlertsFilteredByDeliveryPlanId = async (
    delivery_plan_id: string
  ): Promise<alert[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getListStatusBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<routeEvent[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getListAlertBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<alert[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getListTrafficBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<trafficElement[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getListSensorByDeliveryPlanIdWithUser = async (
    delivery_plan_id: string
  ): Promise<sensor[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public latestSensor = async (serial_number: string): Promise<sensor> => {
    return new Promise((resolve) => {
      resolve({} as sensor)
    })
  }

  public getListSensorBySerialNoAndDateTimeRangeWithUser = async (
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<sensor[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public getListDriverSetByDriverPin = async (
    driver_pin: string,
    max?: number,
    orderby?: 'ASC' | 'DESC'
  ): Promise<DriverSet[]> => {
    return new Promise((resolve) => {
      resolve([])
    })
  }

  public convertTrafficToTrafficElement = (
    traffic: Traffic
  ): trafficElement => {
    const { dgn, sn, lat, lon, tm, sp } = <Traffic>traffic
    return {
      dgn: dgn,
      sn: sn,
      lat: lat,
      lng: lon,
      date_time: new Date(tm),
      speed: sp,
    }
  }

  public convertDeviceAlertToAlert = (deviceAlert: DeviceAlert): alert => {
    const { dgn, tm, lat, lon, alert } = <DeviceAlert>deviceAlert

    const alertStatusString = ALERT_STATUS_MAP[alert]
    return {
      dgn: dgn,
      date_time: new Date(tm * 1000),
      type: <alertStatus>alertStatusString,
      position: {
        lat: lat,
        lng: lon,
      },
    }
  }

  public convertStatusToRouteEvent = (s: Status): routeEvent => {
    const { time, latitude, longitude, status, status_flag } = s
    const key = status_flag + '' + status
    const vehicleStatus = STATUS_MAP[key]
      ? STATUS_MAP[key]
      : this.DEFAULT_STATUS
    return {
      dgn: s.delivery_group_no,
      date_time: new Date(time * 1000),
      type: <workStatus>vehicleStatus,
      position: {
        lat: latitude,
        lng: longitude,
      },
    }
  }
}

export default DummyProvider
