export default {
  radius: 200,
  mapOptions: {
    strokeWeight: 2,
    fillColor: '#0000FF',
    strokeColor: '#0000FF',
    fillOpacity: 0.1,
    strokeOpacity: 0.5,
  },
}
