
import { defineComponent, PropType } from 'vue'
import RouteType from '@/types/RouteType'
import {
  formatDateTime,
  convertShortDeliveryPlanId,
} from '@/services/functions'
import vLink from '@/components/template/v-link.vue'

export default defineComponent({
  name: 'RouteListTableView',
  props: {
    routes: {
      type: Object as PropType<RouteType[]>,
      required: true,
    },
  },
  setup() {
    return {
      formatDateTime,
      convertShortDeliveryPlanId,
    }
  },
  data() {
    return {}
  },

  provide() {
    return {}
  },

  mounted() {},

  computed: {},

  methods: {},

  components: {
    vLink,
  },
})
