import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-228cc97a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-toggle absolute flex flex-wrap content-center bg-white rounded-r-md border border-gray-300" }
const _hoisted_2 = { class: "ml-3 flex justify-center flex-col h-full" }
const _hoisted_3 = { class: "inline-block align-middle ml-1" }
const _hoisted_4 = { class: "pane-content flex flex-wrap h-full" }
const _hoisted_5 = {
  class: "content self-start w-full overflow-y-auto border-t h-full",
  id: "left-panel-content"
}
const _hoisted_6 = { class: "block h-full ml-3" }
const _hoisted_7 = { class: "inline-block align-middle ml-1" }
const _hoisted_8 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["panel-wrap absolute z-30 bg-white shadow-2xl", { 'open-menu': _ctx.isOpen, 'open-menu-mobile': _ctx.isOpenMobile }]),
      "data-test": "left-panel"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "block",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePanel && _ctx.togglePanel(...args))),
          "data-test": "toggle-left-panel"
        }, [
          _createVNode(_component_v_icon, {
            icon: _ctx.getIcon,
            class: "text-xl inline-block align-middle"
          }, null, 8, ["icon"])
        ])
      ]),
      _createElementVNode("div", {
        class: "panel-mobile-open-block border-t",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePanelMobile())),
        "data-test": "toggle-left-panel-mobile"
      }, [
        _createElementVNode("button", _hoisted_2, [
          _createElementVNode("span", null, [
            _createVNode(_component_v_icon, {
              icon: "unfold_more",
              class: "text-3xl text-gray-600 inline-block align-middle"
            }),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getOpenText), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]),
        _createElementVNode("div", {
          class: "panel-mobile-close-block self-end w-full border-t",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.togglePanelMobile())),
          "data-test": "close-left-panel-mobile"
        }, [
          _createElementVNode("button", _hoisted_6, [
            _createVNode(_component_v_icon, {
              icon: "unfold_less",
              class: "text-3xl inline-block text-gray-600 align-middle"
            }),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('pages.vehicleMap.leftPanel.close.text')), 1)
          ])
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 64))
}