import {
  VEHICLE_MARKER_DIRECTION_LEFT,
  VEHICLE_MARKER_DIRECTION_RIGHT,
} from '../../const'
import position from '@/types/position'

//  left       0°           right
//             |Y       3.3
//             |       /
//             |α=45°/
//             |  /
//  270°-------------------> 90°
//         0,0 |             X
//             |
//             |
//             |
//  left      180°         right

const angleBetweenPoints = (
  ay: number,
  ax: number,
  by: number,
  bx: number
): number => {
  const dy = by - ay
  const dx = bx - ax
  const result = (Math.atan2(dx, dy) * 180) / Math.PI
  return result < 0 ? 360 + result : result
}

const getMarkerDirectionByAngle = (angle: number): string => {
  if (angle > 0 && angle <= 90) {
    return VEHICLE_MARKER_DIRECTION_RIGHT
  } else if (angle > 90 && angle < 180) {
    return VEHICLE_MARKER_DIRECTION_RIGHT
  } else if (angle >= 180 && angle <= 270) {
    return VEHICLE_MARKER_DIRECTION_LEFT
  } else if (angle > 270 && angle <= 360) {
    return VEHICLE_MARKER_DIRECTION_LEFT
  }
  return VEHICLE_MARKER_DIRECTION_RIGHT
}

const angleBetweenPositions = (base: position, moved: position): number => {
  return angleBetweenPoints(base.lat, base.lng, moved.lat, moved.lng)
}

export const getMarkerDirection = (
  queuePositions: position[] | undefined
): string => {
  let markerPosition = VEHICLE_MARKER_DIRECTION_RIGHT
  if (queuePositions && queuePositions.length >= 2) {
    const arDirections: any = {}
    arDirections[VEHICLE_MARKER_DIRECTION_RIGHT] = 0
    arDirections[VEHICLE_MARKER_DIRECTION_LEFT] = 0

    for (let i = 0; i < queuePositions.length; i++) {
      const basePositionIndex = i + 1
      const movedPositionIndex = i

      if (
        !queuePositions[basePositionIndex] ||
        !queuePositions[movedPositionIndex]
      ) {
        break
      }

      const markerPosition = getMarkerDirectionByAngle(
        angleBetweenPositions(
          queuePositions[basePositionIndex],
          queuePositions[movedPositionIndex]
        )
      )
      arDirections[markerPosition] = arDirections[markerPosition] + 1
    }

    markerPosition =
      arDirections[VEHICLE_MARKER_DIRECTION_RIGHT] >
      arDirections[VEHICLE_MARKER_DIRECTION_LEFT]
        ? VEHICLE_MARKER_DIRECTION_RIGHT
        : VEHICLE_MARKER_DIRECTION_LEFT
  }
  return markerPosition
}
