import StoreInterface from '@/services/store/StoreInterface'

class LocalStorage implements StoreInterface {
  public clean(key: string): void {
    localStorage.removeItem(key)
  }

  public get(key: string): object | undefined {
    const val = localStorage.getItem(key)
    if (val) {
      return JSON.parse(val)
    }
    return undefined
  }

  public set(key: string, val: object): void {
    localStorage.setItem(key, JSON.stringify(val))
  }
}

export default LocalStorage
