import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-3 px-3 pb-4" }
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "text-center w-1/4" }
const _hoisted_4 = { class: "block text-5xl font-bold text-blue-500" }
const _hoisted_5 = { class: "text-gray-600 text-lg" }
const _hoisted_6 = { class: "text-center w-1/4" }
const _hoisted_7 = { class: "block text-5xl font-bold text-green-500" }
const _hoisted_8 = { class: "text-gray-600 text-lg" }
const _hoisted_9 = { class: "text-center w-1/4" }
const _hoisted_10 = { class: "block text-5xl font-bold text-red-500" }
const _hoisted_11 = { class: "text-gray-600 text-lg" }
const _hoisted_12 = { class: "text-center w-1/4" }
const _hoisted_13 = { class: "block text-5xl font-bold" }
const _hoisted_14 = { class: "text-gray-600 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.inServiceVehicleCount), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(this.$t('pages.vehicleMap.leftPanel.statistics.inService')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.operationVehicleCount), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString(this.$t('pages.vehicleMap.leftPanel.statistics.inOperation')), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.restingAndStopVehicleCount), 1),
        _createElementVNode("span", _hoisted_11, _toDisplayString(this.$t('pages.vehicleMap.leftPanel.statistics.resting')), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.finishedVehicleCount), 1),
        _createElementVNode("span", _hoisted_14, _toDisplayString(this.$t('pages.vehicleMap.leftPanel.statistics.finished')), 1)
      ])
    ])
  ]))
}