import restApiConnection from '@/services/api/providers/Connection'
import UserSession from '@/services/models/UserSession'
import driversResponse from '@/types/responses/driversResponse'
import RestApiBase from '@/services/models/RestApiBase'

export default class Driver extends RestApiBase {
  public static async all(
    forceUpdate: boolean = false
  ): Promise<driversResponse> {
    return this.sync('GetDrivers', () => {
      return this.selectAll(!forceUpdate)
    })
  }

  public static selectAll = async (
    cache: boolean = true
  ): Promise<driversResponse> => {
    const user_session = await UserSession.get()
    if (!user_session) return Promise.reject()

    const user_id = user_session?.data?.user_session_id || ''
    return restApiConnection.get('/drivers?user_session_id=' + user_id, {
      cache: cache,
    })
  }
}
