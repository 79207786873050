
import VIcon from '@/components/template/v-icon.vue'
import { Options, Vue } from 'vue-class-component'
import { nextTick, PropType } from 'vue'
import vehicle from '@/types/vehicle'

@Options({
  name: 'SearchVehicle',
  props: {
    vehicles: {
      type: Array as PropType<vehicle[]>,
      default: function () {
        return []
      },
    },
    modelValue: {
      type: String as PropType<string>,
    },
    desktop: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  inject: ['togglePanelMobile', 'closeVehicle'],
  data() {
    return {
      selectedTooltipIndex: null as number | null,
    }
  },
  computed: {},
  methods: {
    cleanInput(): void {
      this.$emit('update:modelValue', '')
    },
    showLeftPanelContent(): void {
      if (this.desktop) {
        return
      }
      this.togglePanelMobile()
      this.closeVehicle()
      ;(async () => {
        await nextTick()
        const searchBars = document.getElementsByClassName(
          'search-bar-input desktop'
        )
        if (searchBars.length && searchBars[0]) {
          const desktopTopSearchBar: HTMLElement = searchBars[0] as HTMLElement
          desktopTopSearchBar.focus()
        }
      })()
    },
  },
  watch: {},
  emits: ['update:modelValue'],
  components: {
    VIcon,
  },
})
export default class App extends Vue {}
