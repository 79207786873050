import GapChecker from '@/services/map/gapChecker/GapChecker'
import trafficElement from '@/types/trafficElement'

class TimeGapChecker implements GapChecker {
  protected timeGapIntervalSec: number

  constructor(value: number) {
    this.timeGapIntervalSec = value
  }

  isThereGap(index: number, path: trafficElement[]): boolean {
    const prevIndex: number = index - 1
    if (this.timeGapIntervalSec && path[prevIndex] && path[index]) {
      const diff: number =
        (path[index].date_time.getTime() -
          path[prevIndex].date_time.getTime()) /
        1000
      return diff >= this.timeGapIntervalSec
    }
    return false
  }
}

export default TimeGapChecker
