import { GraphQLResult } from '@aws-amplify/api'
import gql from 'graphql-tag'
import AppSyncClient from '@/services/query/providers/Connection'
import {
  listSensorsFilteredByDgnWithUser,
  listSensorsFilteredBySnAndDateTimeRangeWithUser,
  listSensorsFilteredBySnWithUser,
} from '@/graphql/queries'
import {
  ListSensorsFilteredByDgnWithUserQuery,
  ListSensorsFilteredBySnAndDateTimeRangeWithUserQuery,
  ListSensorsFilteredBySnWithUserQuery,
} from '@/API'
import GraphQLApiBase from '@/services/models/GraphQLApiBase'
import UserSessionModel from '@/services/models/UserSession'
import sensor from '@/types/sensor'
import sensorAlert from '@/types/sensorAlert'
import sensorAlertCount from '@/types/sensorAlertCount'

const SENSOR_MAX_LIMIT = 5000
export default class Sensor extends GraphQLApiBase {
  public static clearCache(): void {
    this._clearCache('Sensor')
  }

  public static async selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<sensor[]> {
    return this.cache(
      'SensorDeliveryPlanID',
      delivery_plan_id,
      this._selectByDeliveryPlanId,
      delivery_plan_id
    )
  }

  public static async selectBySerialNoAndDateTimeRangeWithUser(
    serial_number: string,
    startDateTime: number,
    endDateTime: number
  ): Promise<sensor[]> {
    return this.cache(
      'SensorSerialNumber',
      serial_number + startDateTime + endDateTime,
      this._selectBySerialNoAndDateTimeRangeWithUser,
      {
        serial_number,
        startDateTime,
        endDateTime,
      }
    )
  }

  public static async _selectByDeliveryPlanId(
    delivery_plan_id: string
  ): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()

    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id || ''
    let nextToken: string | null | undefined = undefined
    let merged: sensor[] | null = null

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listSensorsFilteredByDgnWithUser),
        variables: {
          user_id: user_id,
          dgn: delivery_plan_id,
          sortDirection: 'DESC',
          nextToken: nextToken,
          limit: SENSOR_MAX_LIMIT,
        },
      })) as GraphQLResult<ListSensorsFilteredByDgnWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res?.data?.listSensorsFilteredByDgnWithUser?.items as sensor[]) || []
      )
      nextToken = res?.data?.listSensorsFilteredByDgnWithUser?.nextToken
    }
    return new Promise((resolve) => {
      resolve(<sensor[]>merged || [])
    })
  }

  public static async _selectBySerialNo(arg: {
    serial_number: string
    max?: number
    orderby?: 'ASC' | 'DESC'
  }): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: sensor[] | null = null

    const res = (await appSyncConnection.query({
      query: gql(listSensorsFilteredBySnWithUser),
      variables: {
        user_id: user_id,
        sn: arg.serial_number,
        sortDirection: arg.orderby ?? 'ASC',
        limit: arg.max ?? SENSOR_MAX_LIMIT,
      },
    })) as GraphQLResult<ListSensorsFilteredBySnWithUserQuery>
    if (!merged) merged = []
    merged = merged.concat(
      (res.data?.listSensorsFilteredBySnWithUser?.items as sensor[]) ?? []
    )

    return new Promise((resolve) => {
      resolve(merged ?? [])
    })
  }

  public static async _selectBySerialNoAndDateTimeRangeWithUser(arg: {
    serial_number: string
    startDateTime: number
    endDateTime: number
  }): Promise<sensor[]> {
    const appSyncConnection = AppSyncClient.connection()
    const user_session = await UserSessionModel.get()
    const user_id = user_session?.data?.user_session_id ?? ''
    let merged: sensor[] | null = null
    let nextToken: string | null | undefined = undefined

    while (!merged || nextToken) {
      const res = (await appSyncConnection.query({
        query: gql(listSensorsFilteredBySnAndDateTimeRangeWithUser),
        variables: {
          user_id: user_id,
          sn: arg.serial_number,
          startDateTime: arg.startDateTime,
          endDateTime: arg.endDateTime,
          sortDirection: 'ASC',
          limit: SENSOR_MAX_LIMIT,
          nextToken: nextToken,
        },
      })) as GraphQLResult<ListSensorsFilteredBySnAndDateTimeRangeWithUserQuery>
      if (!merged) merged = []
      merged = merged.concat(
        (res.data?.listSensorsFilteredBySnAndDateTimeRangeWithUser
          ?.items as sensor[]) ?? []
      )
      nextToken =
        res.data?.listSensorsFilteredBySnAndDateTimeRangeWithUser?.nextToken
    }
    return new Promise((resolve) => {
      resolve(merged ?? [])
    })
  }

  public static splitSensors(
    sensors: sensor[],
    end_time: number,
    range: number,
    count: number
  ): sensor[][] {
    const splited: sensor[][] = []
    let threshold = end_time
    let tmp: sensor[] = []

    const firstSensorTime = sensors[0]?.tm ?? 0

    for (let i = 0; i < count; i++) {
      threshold = end_time - range * i
      tmp = []
      sensors
        .slice()
        .reverse()
        .forEach((s) => {
          if (s.tm <= threshold && s.tm > threshold - range) {
            tmp.push(s)
          }
        })
      splited.push(tmp)
    }
    return splited
  }

  public static hasSensorError(sensors: sensor[]): sensorAlertCount {
    const ret: sensorAlertCount = {
      error: [],
      alert: [],
    }
    sensors.forEach((s) => {
      const alerts = Sensor.getSensorAlert(s)
      alerts.forEach((alert) => {
        if (alert.status == 'error') {
          if (ret.error.indexOf(alert.unit) == -1) ret.error.push(alert.unit)
        } else {
          if (ret.alert.indexOf(alert.unit) == -1) ret.alert.push(alert.unit)
        }
      })
    })
    return ret
  }

  public static getSensorAlert(sensor: sensor): sensorAlert[] {
    const statusKeys = ['U1s', 'U2s', 'U3s', 'U4s', 'U5s', 'U6s', 'U7s', 'U8s']
    const alerts: sensorAlert[] = []
    const lowAlertRegex = /.*01$/
    const highAlertRegex = /.*10$/
    const errorAlertRegex = /.*11$/

    const payloadKeys = Object.keys(sensor)
    statusKeys.forEach((_key, i) => {
      const key = this.convertKey(payloadKeys, _key) as keyof sensor
      if ((sensor[key] as string).match(lowAlertRegex))
        alerts.push({ unit: i + 1, status: 'low' } as sensorAlert)
      if ((sensor[key] as string).match(highAlertRegex))
        alerts.push({ unit: i + 1, status: 'high' } as sensorAlert)
      if ((sensor[key] as string).match(errorAlertRegex))
        alerts.push({ unit: i + 1, status: 'error' } as sensorAlert)
    })
    return alerts
  }

  private static convertKey(keys: string[], target: string): string {
    let ret = ''
    keys.forEach((key) => {
      if (key.toLowerCase() == target.toLowerCase()) return (ret = key)
    })
    return ret
  }
}
