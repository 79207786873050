class TimelineHelper {
  public static convertPercentToNumber(percent: Number, total: Number): number {
    if (percent < 0 || percent > 100 || total < 1) {
      return 0
    }

    return Number((Number(total) * (Number(percent) * 0.01)).toFixed(2))
    // return Math.round((Number(total) * (Number(percent) * 0.01)));
  }

  public static convertNumberToPercent(
    pixelValue: Number,
    total: Number
  ): number {
    if (pixelValue < 0 || pixelValue > total || total < 1) {
      return 0
    }

    return Number((Number(pixelValue) / (Number(total) * 0.01)).toFixed(2))
    // return Math.round((Number(pixelValue) / (Number(total) * 0.01)));
  }
}

export default TimelineHelper
