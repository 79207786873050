
import Api from '@/services/api/ApiServiceFabrick'
import { defineComponent, nextTick } from 'vue'
import useTitle from '@/composables/useTitle'
import vLabel from '@/components/template/v-label.vue'
import vButton from '@/components/template/v-button.vue'
import vIcon from '@/components/template/v-icon.vue'
import RouteListTableView from '@/components/RouteListTableView.vue'
import RouteListMobileView from '@/components/RouteListMobileView.vue'
import keyValue from '@/types/keyValue'
import RouteType from '@/types/RouteType'
import loadStatus from '@/types/loadStatus'
import findRouteResponse from '@/types/responses/findRouteResponse'
import findRouteRequest from '@/types/requests/findRouteRequest'
import errorResponse from '@/types/responses/errorResponse'
import vLoaderAnimation from '@/components/template/v-loader-animation.vue'
import getDriversAndVehiclesResponse from '@/types/responses/getDriversAndVehiclesResponse'
import DatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { formatDate, getMinDateForSearch } from '@/services/functions'
import Multiselect from '@vueform/multiselect'
import { MULTISELECT_CLASSES_PARAM } from '@/services/const'
import setTitle from '@/services/title/setTitle'
import { formatArrayDateToArrayYYYYMMDD } from '@/services/functions'
import operationOffice from '@/types/operationOffice'
import SearchOperationOffices from '@/components/SearchFilter/SearchOperationOffices.vue'

export default defineComponent({
  name: 'vehiclesMap',
  props: {},
  setup() {
    const { pageTitle } = useTitle()

    const classes_param = MULTISELECT_CLASSES_PARAM

    return {
      pageTitle,
      formatArrayDateToArrayYYYYMMDD,
      classes_param,
    }
  },
  data() {
    const minDate = getMinDateForSearch()

    return {
      readyToUpdateList: false as boolean,
      vehicles: [] as keyValue[],
      drivers: [] as keyValue[],
      selectedVehicle: '' as string,
      selectedDriver: '' as string,
      date: [] as Array<Date>,
      routes: [] as RouteType[],
      page: 1 as number,
      enablePagination: false as boolean,
      routeLoadStatus: {
        loading: false,
        loaded: false,
      } as loadStatus,
      minDate: minDate,
      operationOffices: [] as operationOffice[],
      operationOfficeIds: '' as string,
      filteredVehiclesByOperationOffices: [] as keyValue[],
      filteredDriversByOperationOffices: [] as keyValue[],
    }
  },

  provide() {
    return {}
  },

  mounted() {
    this.pageTitle = setTitle(this.$t('pages.searchRoute.title'))

    Api()
      .getDriversAndVehicles({})
      .then((data: getDriversAndVehiclesResponse) => {
        this.vehicles = data.vehicles
        this.drivers = data.drivers
      })
      .catch((error: errorResponse) => {
        console.log(error)
        const errorMessage: string = error.message
          ? error.message
          : this.$t('common.unknownErrorMessage')
        this.$notify({
          title: this.$t('pages.searchRoute.loadDriversAndVehicles.error'),
          text: errorMessage,
          type: 'error',
        })
        this.$rollbar.error(error)
      })
    if (this.$route.query?.operation_office_ids) {
      this.operationOfficeIds = this.$route.query.operation_office_ids as string
    }
    if (this.$route.query?.vehicle_id) {
      this.selectedVehicle = this.$route.query.vehicle_id as string
    }
    if (this.$route.query?.driver_id) {
      this.selectedDriver = this.$route.query.driver_id as string
    }
    if (
      this.$route.query?.returning_warehouse_date_time &&
      this.isDate(String(this.$route.query.returning_warehouse_date_time)) &&
      this.$route.query?.leaving_warehouse_date_time &&
      this.isDate(String(this.$route.query.leaving_warehouse_date_time))
    ) {
      this.date = [
        new Date(String(this.$route.query.leaving_warehouse_date_time)),
        new Date(String(this.$route.query.returning_warehouse_date_time)),
      ]
    }
    ;(async () => {
      await nextTick()
      this.readyToUpdateList = true
    })()
    this.findRoutes()
    window.addEventListener('scroll', this.showNextPage)

    Api()
      .getOperationOffices()
      .then((data) => {
        this.operationOffices = data
      })
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.showNextPage)
  },

  computed: {
    isPaginationRequest(): boolean {
      return this.page > 1
    },
    getFindRouteRequest(): findRouteRequest {
      const findRouteRequest: findRouteRequest = {}

      if (this.date?.length) {
        if (this.date[0]) {
          findRouteRequest.leaving_warehouse_date_time = formatDate(
            this.date[0] as Date
          )
        }
        if (this.date[1]) {
          findRouteRequest.returning_warehouse_date_time = formatDate(
            this.date[1] as Date
          )
        }
      }

      if (this.selectedVehicle) {
        findRouteRequest.vehicle_id = parseInt(this.selectedVehicle)
      }
      if (this.selectedDriver) {
        findRouteRequest.driver_id = parseInt(this.selectedDriver)
      }

      findRouteRequest.page = this.page
      findRouteRequest.operation_office_ids = this.operationOfficeIds

      return findRouteRequest
    },
    selectedOffices(): operationOffice[] {
      const officeIdsArray = [] as any[]
      const selected = [] as operationOffice[]
      const splitOfficeIds = this.operationOfficeIds.split(',')
      splitOfficeIds.forEach((officeId) => {
        officeIdsArray.push(officeId)
      })
      this.operationOffices.forEach((office: operationOffice) => {
        splitOfficeIds.forEach((id: string) => {
          if (office.id == id) selected.push(office)
        })
      })
      return selected
    },
  },

  methods: {
    findRoutes(forceUpdate: boolean = false): void {
      this.routeLoadStatus.loading = true
      Api()
        .findRoute(this.getFindRouteRequest, forceUpdate)
        .then((data: findRouteResponse) => {
          if (this.isPaginationRequest) {
            const tmpRoutes = this.routes.concat(data.routes)
            this.routes = [...new Set(tmpRoutes)]
          } else {
            this.routes = data.routes
          }
          this.enablePagination = data.page_num < data.total_pages
          this.routeLoadStatus.loaded = true
          this.routeLoadStatus.loading = false

          // for long screen devices,
          // which doesn't show scroll panel
          // when showing all items per page(20)
          if (this.enablePagination) {
            ;(async () => {
              await nextTick()
              this.showNextPage()
            })()
          }
        })
        .catch((error: errorResponse) => {
          console.log(error)
          this.routeLoadStatus.loaded = true
          this.routeLoadStatus.loading = false
          const errorMessage: string = error.message
            ? error.message
            : this.$t('common.unknownErrorMessage')
          this.$notify({
            title: this.$t('pages.searchRoute.findRoute.error'),
            text: errorMessage,
            type: 'error',
          })
          this.$rollbar.error(error)
        })
    },
    refreshRouteList(): void {
      this.cleanPagination()
      this.findRoutes(true)
    },
    cleanPagination(): void {
      this.page = 1
    },
    loadNextPage(): void {
      this.page++
      this.findRoutes()
    },
    showNextPage(): void {
      // document.documentElement
      const container = (this.$refs as any)['page-content']
      const headerHeight = 52

      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        container.offsetHeight + headerHeight
      if (
        bottomOfWindow &&
        this.enablePagination &&
        !this.routeLoadStatus.loading
      ) {
        this.loadNextPage()
      }
    },
    updateUrlParams(): void {
      /* eslint-disable no-unused-vars */
      let { page, ...queryParams } = this.getFindRouteRequest
      /* eslint-enable no-unused-vars */

      this.$router.push({
        name: 'searchRoute',
        query: queryParams as Record<any, any>,
      })
    },
    isDate(val: string): boolean {
      /* eslint-disable */
      const dateFormat =
        /(^\d{1,4}[\.|\\/|-]\d{1,2}[\.|\\/|-]\d{1,4})(\s*(?:0?[1-9]:[0-5]|1(?=[012])\d:[0-5])\d\s*[ap]m)?$/
      /* eslint-enable */
      return dateFormat.test(val)
    },
    updateListOfRoute(): void {
      if (this.readyToUpdateList) {
        this.cleanPagination()
        this.findRoutes()
        this.updateUrlParams()
      }
    },
    selectedOperationOffices(offices: operationOffice[]) {
      this.operationOfficeIds = this.makeOperationOffices(offices)
      this.filteredVehiclesByOperationOffices = this.filterByOperationOfficeIds(
        offices,
        this.vehicles
      )
      this.filteredDriversByOperationOffices = this.filterByOperationOfficeIds(
        offices,
        this.drivers
      )
      this.updateListOfRoute()
    },
    makeOperationOffices(offices: operationOffice[]) {
      let ids = ''
      offices.forEach((office: operationOffice) => {
        if (!ids) {
          ids = String(office.id)
        } else {
          ids = ids + ',' + String(office.id)
        }
      })
      return ids
    },
    filterByOperationOfficeIds(offices: operationOffice[], items: keyValue[]) {
      let matchOfficeIds = [] as keyValue[]
      items.forEach((item: keyValue) => {
        offices.forEach((office: operationOffice) => {
          if (item.operation_office_id == office.id) matchOfficeIds.push(item)
        })
      })
      return matchOfficeIds
    },
  },

  watch: {
    selectedVehicle: function (): void {
      this.updateListOfRoute()
    },
    selectedDriver: function (): void {
      this.updateListOfRoute()
    },
    date: function (): void {
      this.updateListOfRoute()
    },
  },

  components: {
    vLabel,
    vButton,
    vIcon,
    RouteListTableView,
    RouteListMobileView,
    vLoaderAnimation,
    DatePicker,
    Multiselect,
    SearchOperationOffices,
  },
})
