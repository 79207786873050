import { format } from 'date-fns'

export class AlertDate {
  private originString: string
  private dateString: string
  private microseconds: string
  private date: Date | undefined

  constructor(date: string) {
    const [time, _milisecond] = date.split('.')
    if (time.length == 14) {
      const year = Number(time.substring(0, 4))
      const month = Number(time.substring(4, 6))
      const day = Number(time.substring(6, 8))
      const hour = Number(time.substring(8, 10))
      const minute = Number(time.substring(10, 12))
      const second = Number(time.substring(12, 14))

      let milisecond = 0
      try {
        if (_milisecond) milisecond = Number(_milisecond.slice(0, 3))
      } catch (TypeError) {
        console.log('invalid milisecond. ' + date)
      }

      const _date = new Date(
        year,
        month - 1,
        day,
        hour,
        minute,
        second,
        milisecond
      )
      this.date = _date
      this.originString = date

      this.dateString = format(_date, 'yyyyMdHms')
      this.microseconds = _milisecond
    } else {
      this.originString = date
      this.dateString = AlertDate.parseDateTime(date)
      this.microseconds = AlertDate.parseMicroseconds(date)
    }
  }

  private static parseDateTime(date: string): string {
    const ar_date_components = date.split('.')
    if (ar_date_components.length > 1) {
      return ar_date_components[0]
    }
    return date
  }

  public static parseMicroseconds(date: string): string {
    const ar_date_components = date.split('.')
    if (ar_date_components.length > 1) {
      return ar_date_components[1]
    }
    return ''
  }

  public getDate(): Date | undefined {
    return this.date
  }

  public setDate(date: Date) {
    this.date = date
  }

  public getMicroseconds(): String {
    return this.microseconds
  }

  public getDateString(): String {
    return this.dateString
  }

  public getOriginString(): string {
    return this.originString
  }

  public static createFromDate(date: Date): AlertDate {
    const alertDate = new AlertDate(format(date, 'yyyyMMddHHmmss'))
    alertDate.setDate(date)
    return alertDate
  }
}
